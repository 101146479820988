import { ErrorMessage, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import FormikControl from '../../../components/FormikControl'
import SellerModal from '../../../components/SellerModal'
import TextError from '../../../components/TextError'
import {
  _status_,
  isAdharCardAllowed,
  isShippingOnCat,
  prepareNotificationData,
  showToast
} from '../../../lib/GetBaseUrl'
import Select from 'react-select'
import * as Yup from 'yup'
import axios from 'axios'
import { Button } from 'react-bootstrap'
import axiosProvider from '../../../lib/AxiosProvider'
import { useSelector } from 'react-redux'
import Loader from '../../../components/Loader'
import {
  _adharBackImg_,
  _adharFrontImg_,
  _brandImg_,
  _cancelCheaque_,
  _digitalSignImg_,
  _kycImg_,
  _msmeImg_,
  _panCardImg_
} from '../../../lib/ImagePath'
import { shippingOnCategoryLevel } from '../../../lib/AllStaticVariables'
import {
  _alphabetRegex_,
  _integerRegex_,
  _phoneNumberRegex_
} from '../../../lib/Regex'
import { customStyles } from '../../../components/customStyles'
import { useLocation } from 'react-router-dom'
import { _exception } from '../../../lib/exceptionMessage'

const BasicInfoModal = ({
  loading,
  setLoading,
  initialValues,
  setInitialValues,
  modalShow,
  setModalShow,
  isModalRequired,
  fetchData,
  toast,
  setToast
}) => {
  const { userInfo } = useSelector((state) => state?.user)
  const location = useLocation()

  const SUPPORTED_FORMATS = [
    'image/jpg',
    'image/jpeg',
    'image/png',
    'application/pdf'
  ]

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .matches(_alphabetRegex_, 'Invalid brand name')
      .required('Please enter Brand Name'),
    description: Yup.string().required('Please enter Description'),
    logo: Yup.mixed()
      .test(
        'fileFormat',
        'File formate is not supported, Please use .jpg/.png/.jpeg format support',
        (value) => {
          if (typeof value === 'string') return true
          else {
            return value && SUPPORTED_FORMATS?.includes(value.type)
          }
        }
      )
      .test('fileSize', 'File must be less than 2MB', (value) => {
        if (typeof value === 'string') {
          return true
        } else return value !== undefined && value && value.size <= 2000000
      })
      .required('Logo is required'),
    backgroundBanner: Yup.mixed()
      .test(
        'fileFormat',
        'File formate is not supported, Please use .jpg/.png/.jpeg format support',
        (value) => {
          if (typeof value === 'string') return true
          else {
            return value && SUPPORTED_FORMATS?.includes(value.type)
          }
        }
      )
      .test('fileSize', 'File must be less than 2MB', (value) => {
        if (typeof value === 'string') {
          return true
        } else return value !== undefined && value && value.size <= 2000000
      })
      .required('Background banner is required'),
    browsebyBrandImage: Yup.mixed()
      .test(
        'fileFormat',
        'File formate is not supported, Please use .jpg/.png/.jpeg format support',
        (value) => {
          if (typeof value === 'string') return true
          else {
            return value && SUPPORTED_FORMATS?.includes(value.type)
          }
        }
      )
      .test('fileSize', 'File must be less than 2MB', (value) => {
        if (typeof value === 'string') {
          return true
        } else return value !== undefined && value && value.size <= 2000000
      })
      .required('Browse by brand is required')
  })

  const onSubmit = async (values) => {
    let dataofForm = {
      Name: values.name,
      Description: values?.description,
      Status: values?.status,
      Logo: values?.logo?.name ? values?.logo?.name : '',
      FileName: values?.logo ? values?.logo : '',
      BackgroundBanner: values?.backgroundBanner?.name
        ? values?.backgroundBanner?.name
        : '',
      BackgroundBannerFileName: values?.backgroundBanner
        ? values?.backgroundBanner
        : '',
      BrowsebyBrandImage: values?.browsebyBrandImage?.name
        ? values?.browsebyBrandImage?.name
        : '',
      BrowsebyBrandImageFileName: values?.browsebyBrandImage
        ? values?.browsebyBrandImage
        : '',
      BrandCertificate: '',
      SellerId: values?.sellerID,
      Website: values?.website
    }

    if (values?.id) {
      dataofForm = { ...dataofForm, ID: values?.brandId }
    }

    const submitFormData = new FormData()

    const keys = Object.keys(dataofForm)

    keys.forEach((key) => {
      submitFormData.append(key, dataofForm[key])
    })

    try {
      setLoading(true)
      const response = await axiosProvider({
        method: values?.id ? 'PUT' : 'POST',
        endpoint: values?.id ? 'Brand' : 'Brand/AddNewBrandBySeller',
        data: submitFormData,
        logData: values,
        location: location?.pathname,
        userId: userInfo?.userId,
        oldData: initialValues?.basicInfo
      })
      setLoading(false)

      if (response?.data?.code === 200) {
        if (!values?.id) {
          setModalShow((draft) => {
            draft.createSeller = false
            draft.basicInfo = false
            draft.gstInfo = false
            draft.warehouse = false
          })
        } else {
          setModalShow((draft) => {
            draft.basicInfo = false
          })
        }

        const sendNotification = axiosProvider({
          endpoint: 'Notification',
          method: 'POST',
          data: prepareNotificationData({
            reciverId: values?.userID,
            userId: userInfo?.userId,
            userType: userInfo?.userType,
            notificationTitle: `Brand: ${values?.name} ${
              values?.id
                ? 'updated brand successfully'
                : 'added brand successfully'
            }`,
            notificationDescription: `${values?.id ? 'Update' : 'Created'} by ${
              userInfo?.fullName
            }`,
            url: `/manage-seller/seller-details/${values?.sellerID}`,
            notifcationsof: 'Seller'
          })
        })
      }

      fetchData()
      showToast(toast, setToast, response)
    } catch {
      setLoading(false)

      showToast(toast, setToast, {
        data: {
          message: _exception?.message,
          code: 204
        }
      })
    }
  }

  const renderComponent = () => {
    return (
      <Formik
        enableReinitialize={true}
        initialValues={initialValues?.basicInfo}
        validateOnChange={true}
        validateOnBlur={true}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <div className='create_seller'>
              {loading && <Loader />}
              <div className='row'>
                <div className='col-md-4'>
                  <div className='d-flex flex-column justify-content-center align-items-center text-center'>
                    <div className='input-file-wrapper m--cst-filetype mb-3'>
                      <label className='form-label required' htmlFor='logo'>
                        Logo
                      </label>
                      <input
                        id='logoFile'
                        className='form-control'
                        name='logo'
                        type='file'
                        accept='image/jpg, image/png, image/jpeg'
                        onChange={(event) => {
                          const objectUrl = URL.createObjectURL(
                            event.target.files[0]
                          )
                          if (event.target.files[0].type !== '') {
                            setFieldValue('logoUrl', objectUrl)
                          }
                          setFieldValue(
                            'logo',
                            event?.target?.files[0]
                              ? event?.target?.files[0]
                              : ''
                          )
                        }}
                        hidden
                      />
                      {values?.logo ? (
                        <div className='position-relative m--img-preview d-flex rounded-2 overflow-hidden'>
                          <img
                            src={
                              values?.logoUrl?.includes('blob')
                                ? values?.logoUrl
                                : `${process.env.REACT_APP_IMG_URL}${_brandImg_}${values?.logo}`
                            }
                            alt='Preview Logo'
                            title={values?.logo ? values?.logoFile?.name : ''}
                            className='rounded-2'
                          ></img>
                          <span
                            onClick={(e) => {
                              setFieldValue('logo', null)
                              setFieldValue('logoFile', '')
                              document.getElementById('logoFile').value = null
                            }}
                          >
                            <i className='m-icon m-icon--close'></i>
                          </span>
                        </div>
                      ) : (
                        <>
                          <label
                            className='m__image_default d-flex align-items-center justify-content-center rounded-2'
                            htmlFor='logoFile'
                          >
                            <i className='m-icon m-icon--defaultpreview'></i>
                          </label>
                        </>
                      )}
                      <ErrorMessage
                        name='logo'
                        component={TextError}
                        customclass={'cfz-12 lh-sm'}
                      />
                    </div>
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='d-flex flex-column justify-content-center align-items-center text-center'>
                    <div className='input-file-wrapper m--cst-filetype mb-3'>
                      <label
                        className='form-label required'
                        htmlFor='backgroundBanner'
                      >
                        Background Banner
                      </label>
                      <input
                        id='backgroundBannerFileName'
                        className='form-control'
                        name='backgroundBanner'
                        type='file'
                        accept='image/jpg, image/png, image/jpeg'
                        onChange={(event) => {
                          const objectUrl = URL.createObjectURL(
                            event.target.files[0]
                          )
                          if (event.target.files[0].type !== '') {
                            setFieldValue('backgroundBannerUrl', objectUrl)
                          }
                          setFieldValue(
                            'backgroundBanner',
                            event?.target?.files[0]
                              ? event?.target?.files[0]
                              : ''
                          )
                        }}
                        hidden
                      />
                      {values?.backgroundBanner ? (
                        <div className='position-relative m--img-preview d-flex rounded-2 overflow-hidden'>
                          <img
                            src={
                              values?.backgroundBannerUrl?.includes('blob')
                                ? values?.backgroundBannerUrl
                                : `${process.env.REACT_APP_IMG_URL}${_brandImg_}${values?.backgroundBanner}`
                            }
                            alt='Preview Banner'
                            title={
                              values?.backgroundBanner
                                ? values?.backgroundBanner?.name
                                : ''
                            }
                            className='rounded-2'
                          ></img>
                          <span
                            onClick={(e) => {
                              setFieldValue('backgroundBanner', null)
                              setFieldValue('backgroundBannerFileName', '')
                              document.getElementById(
                                'backgroundBannerFileName'
                              ).value = null
                            }}
                          >
                            <i className='m-icon m-icon--close'></i>
                          </span>
                        </div>
                      ) : (
                        <>
                          <label
                            className='m__image_default d-flex align-items-center justify-content-center rounded-2'
                            htmlFor='backgroundBannerFileName'
                          >
                            <i className='m-icon m-icon--defaultpreview'></i>
                          </label>
                        </>
                      )}
                      <ErrorMessage
                        name='backgroundBanner'
                        component={TextError}
                        customclass={'cfz-12 lh-sm'}
                      />
                    </div>
                  </div>
                </div>

                <div className='col-md-4'>
                  <div className='d-flex flex-column justify-content-center align-items-center text-center'>
                    <div className='input-file-wrapper m--cst-filetype mb-3'>
                      <label
                        className='form-label required'
                        htmlFor='browsebyBrandImage'
                      >
                        Browse By Brand
                      </label>
                      <input
                        id='browsebyBrandImageFileName'
                        className='form-control'
                        name='browsebyBrandImage'
                        type='file'
                        accept='image/jpg, image/png, image/jpeg'
                        onChange={(event) => {
                          const objectUrl = URL.createObjectURL(
                            event.target.files[0]
                          )
                          if (event.target.files[0].type !== '') {
                            setFieldValue('browsebyBrandImageUrl', objectUrl)
                          }
                          setFieldValue(
                            'browsebyBrandImage',
                            event?.target?.files[0]
                              ? event?.target?.files[0]
                              : ''
                          )
                        }}
                        hidden
                      />
                      {values?.browsebyBrandImage ? (
                        <div className='position-relative m--img-preview d-flex rounded-2 overflow-hidden'>
                          <img
                            src={
                              values?.browsebyBrandImageUrl?.includes('blob')
                                ? values?.browsebyBrandImageUrl
                                : `${process.env.REACT_APP_IMG_URL}${_brandImg_}${values?.browsebyBrandImage}`
                            }
                            alt='Preview Brand'
                            title={
                              values?.browsebyBrandImage
                                ? values?.browsebyBrandImage?.name
                                : ''
                            }
                            className='rounded-2'
                          ></img>
                          <span
                            onClick={(e) => {
                              setFieldValue('browsebyBrandImage', null)
                              setFieldValue('browsebyBrandImageFileName', '')
                              document.getElementById(
                                'browsebyBrandImageFileName'
                              ).value = null
                            }}
                          >
                            <i className='m-icon m-icon--close'></i>
                          </span>
                        </div>
                      ) : (
                        <>
                          <label
                            className='m__image_default d-flex align-items-center justify-content-center rounded-2'
                            htmlFor='browsebyBrandImageFileName'
                          >
                            <i className='m-icon m-icon--defaultpreview'></i>
                          </label>
                        </>
                      )}
                      <ErrorMessage
                        name='browsebyBrandImage'
                        component={TextError}
                        customclass={'cfz-12 lh-sm'}
                      />
                    </div>
                  </div>
                </div>

                <div className='col-md-12'>
                  <FormikControl
                    isRequired
                    control='input'
                    label='Brand Name'
                    type='text'
                    name='name'
                    placeholder='Enter Brand Name'
                    onChange={(e) => {
                      setFieldValue('name', e?.target?.value)
                    }}
                  />
                </div>
                <div className='col-md-12'>
                  <FormikControl
                    isRequired
                    as='textarea'
                    control='input'
                    label='Description'
                    type='text'
                    name='description'
                    placeholder='Enter description'
                  />
                </div>

                <div className='col-md-12'>
                  <div className='input-file-wrapper mb-3'>
                    <FormikControl
                      control='input'
                      label='Website'
                      id='website'
                      type='text'
                      name='website'
                      placeholder='Company website'
                      onChange={(e) => {
                        setFieldValue('website', e?.target?.value)
                      }}
                    />
                  </div>
                </div>
              </div>
              {!isModalRequired && (
                <Button type='submit' id='createSeller'>
                  Submit details
                </Button>
              )}
            </div>
          </Form>
        )}
      </Formik>
    )
  }

  return isModalRequired ? (
    <SellerModal
      show={modalShow?.basicInfo}
      modalsize={'xl'}
      modalheaderclass={''}
      // modeltitle={initialdata === true ? "Create Country" : "Update Country"}
      onHide={() =>
        setModalShow((draft) => {
          draft.basicInfo = false
        })
      }
      btnclosetext={''}
      closebtnvariant={''}
      backdrop={'static'}
      formbuttonid={'basic-info'}
      submitname={'Submit Details'}
      modalclass={'create_seller background_color_none'}
      headerclass={'d-none'}
      buttonclass={'d-none'}
    >
      {renderComponent()}
    </SellerModal>
  ) : (
    renderComponent()
  )
}

export default BasicInfoModal
