import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import PageRole from './PageRole'
import UserRole from './UserRole'
import { setPageTitle } from '../../redux/slice/pageTitleSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import NotFound from '../../NotFound/NotFound'

const ManageRole = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setPageTitle('Manage Roles'))
  }, [])

  const { userInfo } = useSelector((state) => state.user)

  const [activeToggle, setActiveToggle] = useState('userRole')
  const handleTabClick = (e, tabName) => {
    e.preventDefault()
    setActiveToggle(tabName)
  }

  return userInfo?.userType?.toLowerCase() === 'super admin' ? (
    <div className='card overflow-hidden'>
      <div className='card-body p-0'>
        <div className='nav-tabs-horizontal nav nav-tabs'>
          {/* <Link
            // onClick={() => setActiveToggle('pageRole')}
            onClick={(e) => handleTabClick(e, 'pageRole')}
            data-toggle='tab'
            className={`nav-link fw-semibold ${
              activeToggle === 'pageRole' ? 'active show' : ''
            }`}
          >
            <span className='nav-span'>
              Page Role
              <hr />
            </span>
          </Link> */}
          <Link
            // onClick={() => setActiveToggle('userRole')}
            onClick={(e) => handleTabClick(e, 'userRole')}
            data-toggle='tab'
            className={`nav-link fw-semibold ${
              activeToggle === 'userRole' ? 'active show' : ''
            }`}
          >
            <span className='nav-span'>
              User Role
              <hr />
            </span>
          </Link>
        </div>

        <div className='tab-content p-3'>
          {/* <div
            id='pageRole'
            className={`tab-pane fade ${
              activeToggle === 'pageRole' ? 'active show' : ''
            }`}
          >
            {activeToggle === 'pageRole' && <PageRole />}
          </div> */}
          <div
            id='userRole'
            className={`tab-pane fade ${
              activeToggle === 'userRole' ? 'active show' : ''
            }`}
          >
            {activeToggle === 'userRole' && <UserRole />}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <NotFound />
  )
}

export default ManageRole
