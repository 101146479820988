import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <div className='footer'>
      <p className='mb-0'>
        Copyright &#169; 2023 archioo. All Right reserved.
      </p>
    </div>
  )
}

export default Footer
