import React, { useEffect, useState } from 'react'
import CategoryWidget from '../../../components/ManageHomePage/CategoryWidget'
import ManageBannerSlider from '../../../components/ManageHomePage/ManageBannerSlider'
import { Row, Col } from 'react-bootstrap'
import GridImageLayout from '../../../components/GridImageSection/GridImageLayout'
import ManageSection from '../../../components/ManageHomePage/ManageSection'
import axiosProvider from '../../../lib/AxiosProvider'
import Loader from '../../../components/Loader'
import HomePageThumbnail from '../../../components/ManageHomePage/HomePageThumbnail'
import Accordion from 'react-bootstrap/Accordion'
import {
  callApi,
  splitStringOnCapitalLettersAndUnderscores
} from '../../../lib/GetBaseUrl'
import { _manageLayoutTypeImg_ } from '../../../lib/ImagePath'
import CustomToast from '../../../components/Toast/CustomToast'
import { useDispatch, useSelector } from 'react-redux'
import { setPageTitle } from '../../redux/slice/pageTitleSlice'
import { useImmer } from 'use-immer'
import HomeProductWidget from '../../../components/ManageHomePage/HomeProductWidget'
import {
  allCrudNames,
  allPages,
  checkPageAccess
} from '../../../lib/AllPageNames'
import NotFound from '../../NotFound/NotFound'
import CustomGrid from '../../../components/GridImageSection/CustomGrid'
import { useLocation } from 'react-router-dom'

function ManageHomePage(props) {
  const [toast, setToast] = useState({
    show: false,
    text: null,
    variation: null
  })
  const [modalShow, setModalShow] = useState({
    show: false,
    layoutId: null,
    layoutTypeId: null,
    layout_name: '',
    isDataUpdated: false
  })
  const location = useLocation()
  const { userId } = useSelector((state) => state?.user?.userInfo)
  const { pageAccess } = useSelector((state) => state.user)
  const [layoutDetails, setLayoutDetails] = useState({
    show: false,
    isDataUpdated: false
  })
  const [loading, setLoading] = useState(false)
  const [allState, setAllState] = useImmer({
    allSectionDetails: [],
    homePageSection: {}
  })

  const handleDelete = async (id, isProductList = false) => {
    setLoading(true)
    let endpoint = isProductList
      ? `ManageHomePageSections/DeleteSection?sectionId=${id}`
      : `ManageHomePageSections?id=${id}`
    const response = await axiosProvider({
      method: 'DELETE',
      endpoint,
      userId,
      location: location?.pathname
    })
      .then((res) => {
        if (res?.data?.code === 200) {
          let homePageSection = allState?.homePageSection
          homePageSection = Object.keys(homePageSection)
            ?.filter((key) => homePageSection[key]?.section?.section_id !== id)
            ?.reduce((obj, key) => {
              obj[key] = homePageSection[key]
              return obj
            }, {})
          setAllState((draft) => {
            draft.homePageSection = homePageSection
          })
        }
        setToast({
          show: true,
          text: res?.data?.message,
          variation: res?.data?.code !== 200 ? 'error' : 'success'
        })

        setTimeout(() => {
          setToast({ ...toast, show: false })
        }, 2000)
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
      })
  }

  const handleImgDelete = async (endpoint) => {
    setLoading(true)
    const response = await axiosProvider({
      method: 'DELETE',
      endpoint,
      userId,
      location: location?.pathname
    })
      .then((res) => {
        if (res?.status === 200) {
          fetchData()
        }
        setToast({
          show: true,
          text: res?.data?.message,
          variation: res?.data?.code !== 200 ? 'error' : 'success'
        })

        setTimeout(() => {
          setToast({ ...toast, show: false })
        }, 2000)
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
      })
  }

  const renderComponent = (data) => {
    return Object.entries(data)?.map(([key, value]) => {
      switch (value?.layoutsInfo?.layout_name) {
        case 'Banners':
          return (
            <ManageBannerSlider
              layoutsInfo={value?.layoutsInfo}
              section={value?.section}
              layoutDetails={layoutDetails}
              setLayoutDetails={setLayoutDetails}
              handleDelete={handleDelete}
              handleImgDelete={handleImgDelete}
              modalShow={modalShow}
              setModalShow={setModalShow}
            />
          )

        case 'Thumbline':
          return (
            <HomePageThumbnail
              layoutsInfo={value?.layoutsInfo}
              section={value?.section}
              layoutDetails={layoutDetails}
              setLayoutDetails={setLayoutDetails}
              handleDelete={handleDelete}
              modalShow={modalShow}
              setModalShow={setModalShow}
              handleImgDelete={handleImgDelete}
            />
          )

        case 'Product List':
          return (
            <HomeProductWidget
              layoutsInfo={value?.layoutsInfo}
              section={value?.section}
              layoutDetails={layoutDetails}
              setLayoutDetails={setLayoutDetails}
              handleDelete={handleDelete}
              handleImgDelete={handleImgDelete}
              modalShow={modalShow}
              setModalShow={setModalShow}
            />
          )

        case 'Gallery':
          return (
            <GridImageLayout
              layoutsInfo={value?.layoutsInfo}
              handleDelete={handleDelete}
              section={value?.section}
              layoutDetails={layoutDetails}
              setLayoutDetails={setLayoutDetails}
              setModalShow={setModalShow}
              modalShow={modalShow}
              handleImgDelete={handleImgDelete}
            />
          )

        default:
          return null
      }
    })
  }

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setPageTitle('Home page'))
  }, [])

  useEffect(() => {
    fetchData()
  }, [])

  const fetchAllGenericData = async (apiUrls) => {
    try {
      const responseArray = await Promise.all(
        apiUrls.map((url) => callApi(url.endpoint, url.queryString, url.state))
      )

      return responseArray
    } catch (error) {}
  }

  const fetchData = async () => {
    try {
      setLoading(true)
      let apiUrls = [
        {
          endpoint: 'ManageHomePageSection/GetHomePageSection',
          queryString: '',
          state: 'homePageSection'
        }
      ]

      if (!allState?.allSectionDetails?.length) {
        apiUrls.push({
          endpoint: 'ManageLayoutTypes/bindlayouts',
          queryString: '',
          state: 'allSectionDetails'
        })
      }

      const fetchData = async () => {
        let homePageSection, allSectionDetails

        const responses = await fetchAllGenericData(apiUrls)
        responses.forEach((response) => {
          switch (response.state) {
            case 'homePageSection':
              homePageSection = response.data
              break

            case 'allSectionDetails':
              allSectionDetails = response?.data
              break

            default:
              break
          }
        })
        if (allSectionDetails?.length) {
          setAllState((draft) => {
            draft.allSectionDetails = allSectionDetails
          })
        }
        setAllState((draft) => {
          draft.homePageSection = homePageSection
        })

        setLoading(false)
      }
      fetchData()
    } catch (error) {
      setLoading(false)
    }
  }

  const components =
    allState?.homePageSection && renderComponent(allState?.homePageSection)

  return checkPageAccess(pageAccess, allPages?.homePage, allCrudNames?.read) ? (
    <div>
      {loading && !modalShow?.show && <Loader />}

      {toast?.show && (
        <CustomToast text={toast?.text} variation={toast?.variation} />
      )}

      <Row className='justify-content-center align-items-start'>
        {checkPageAccess(
          pageAccess,
          allPages?.homePage,
          allCrudNames?.write
        ) && (
          <Col md={3} className='mt-4'>
            <Accordion
              defaultActiveKey='0'
              className='pv-mhome-accordion-main d-flex flex-column gap-3'
            >
              {allState?.allSectionDetails?.map((data, index) => (
                <Accordion.Item
                  eventKey={index}
                  key={index}
                  className='rounded'
                >
                  <Accordion.Header className='bold'>
                    {data?.name}
                  </Accordion.Header>
                  {data?.layoutTypes?.length > 0 && (
                    <Accordion.Body>
                      <Row className='flex-wrap gy-3 align-items-center'>
                        {data?.layoutTypes?.map((layout) => (
                          <Col
                            key={Math.floor(Math.random() * 100000)}
                            sm={6}
                            onClick={() => {
                              setModalShow({
                                ...modalShow,
                                show: !modalShow?.show,
                                layoutId: data?.id,
                                layoutName: layout?.name
                                  ?.toLowerCase()
                                  ?.includes('product')
                                  ? 'Product List'
                                  : data?.name,
                                layoutTypeId: layout?.id,
                                layoutTypeName: layout?.name
                              })
                            }}
                          >
                            <div
                              role='button'
                              className='d-flex m-auto w-100 align-items-center justify-content-center gap-2 flex-column border p-3 rounded'
                            >
                              <img
                                width='70px'
                                className='img-fluid'
                                src={`${process.env.REACT_APP_IMG_URL}${_manageLayoutTypeImg_}${layout?.image}`}
                              />
                              <span className='pv-accordian-item-desc'>
                                {splitStringOnCapitalLettersAndUnderscores(
                                  layout.name
                                )}
                              </span>
                            </div>
                          </Col>
                        ))}
                      </Row>
                    </Accordion.Body>
                  )}
                </Accordion.Item>
              ))}
            </Accordion>
          </Col>
        )}

        <Col
          // md={
          //   checkPageAccess(pageAccess, allPages?.homePage, allCrudNames?.read) ? 12: 9
          // }
          md={9}
          className='pv-homepage-layout'
        >
          {components?.length > 0 ? (
            components
          ) : (
            <div
              style={{
                border: '2px dashed var(--bs-border-color)',
                height: '400px'
              }}
              className='rounded d-flex align-items-center justify-content-center w-100'
              onClick={() => setModalShow(true)}
            >
              <h3 className='text-secondary'>Select Your Homepage</h3>
            </div>
          )}
        </Col>
      </Row>
      {modalShow?.show && (
        <ManageSection
          fetchHomePageData={fetchData}
          loading={loading}
          setLoading={setLoading}
          toast={toast}
          setToast={setToast}
          modalShow={modalShow}
          setModalShow={setModalShow}
        />
      )}
      {layoutDetails?.show && (
        <CategoryWidget
          fetchHomePageData={fetchData}
          layoutDetails={layoutDetails}
          setLayoutDetails={setLayoutDetails}
          setToast={setToast}
          toast={toast}
        />
      )}
    </div>
  ) : (
    <NotFound />
  )
}

export default ManageHomePage
