export const _productImg_ = 'ProductImage/'
export const _brandImg_ = 'BrandImages/'
export const _brandCertificateImg_ = 'Brandcertificate/'
export const _subMenuImg_ = 'ManageSubMenu/'
export const _manageLayoutsImg_ = 'ManageLayouts/'
export const _manageLayoutTypeImg_ = 'ManageLayoutTypes/'
export const _headerMenuImg_ = 'ManageHeaderMenu/'
export const _collectionImg_ = 'ManageCollection/'
export const _userProfileImg_ = 'UserProfile/'
export const _gstInfoImg_ = 'GSTInfo/'
export const _cancelCheaque_ = 'Kyc/CancelCheque/'
export const _categoryImg_ = 'Images/'
export const _tempImg_ = 'Temp/'
export const _panCardImg_ = 'Kyc/PanCardDoc/'
export const _msmeImg_ = 'Kyc/MSMEDoc/'
export const _kycImg_ = 'Kyc/Logo/'
export const _digitalSignImg_ = 'Kyc/DigitalSign/'
export const _adharFrontImg_ = 'Kyc/AadharCardFrontDoc/'
export const _adharBackImg_ = 'Kyc/AadharCardBackDoc/'
export const _homePageImg_ = 'HomePages/'
export const _homePageDtlImg_ = ''
export const _lendingPageImg_ = 'LendingPageSections/'
export const _childMenuImg_ = 'ManageChildMenu/'
export const _specificationDocs_ = 'SpecificationDocFiles/'
export const _manageLayoutOptionImg_ = 'ManageLayoutOptions/'
export const _productBimcad_ = 'ProductBimcad/'
export const _specificationDocFiles_ = 'SpecificationDocFiles/'
