import React from 'react'
import { _homePageImg_, _lendingPageImg_ } from '../../lib/ImagePath'
import ImgEditComponet from '../ManageHomePage/ImgEditComponet'
import Swal from 'sweetalert2'
import { _SwalDelete } from '../../lib/exceptionMessage'
import { parseHTMLString, removeBrTags } from '../../lib/GetBaseUrl'

const CustomGrid = ({
  layoutsInfo,
  section,
  handleDelete,
  setLayoutDetails,
  layoutDetails,
  setModalShow,
  modalShow,
  fromLendingPage,
  handleImgDelete,
  allState
}) => {
  const minImagesLength = layoutsInfo?.layout_min_images ?? 0
  const maxImagesLength = layoutsInfo?.layout_max_images ?? 0

  const sectionDelete = (id, specificImgDelete = false) => {
    Swal.fire({
      title: _SwalDelete.title,
      text: _SwalDelete.text,
      icon: _SwalDelete.icon,
      showCancelButton: _SwalDelete.showCancelButton,
      confirmButtonColor: _SwalDelete.confirmButtonColor,
      cancelButtonColor: _SwalDelete.cancelButtonColor,
      confirmButtonText: _SwalDelete.confirmButtonText,
      cancelButtonText: _SwalDelete.cancelButtonText
    }).then((result) => {
      if (result.isConfirmed) {
        if (specificImgDelete) {
          let endpoint = fromLendingPage
            ? `LendingPageSectionDetails?id=${id}`
            : `ManageHomePageDetails?id=${id}`
          handleImgDelete(endpoint)
        } else {
          handleDelete(section?.section_id)
        }
      } else if (result.isDenied) {
      }
    })
  }

  const parseHTMLString = (htmlString) => {
    htmlString = removeBrTags(htmlString)
    const container = document.createElement('div')
    container.innerHTML = htmlString
    const elements = Array.from(container.childNodes)

    return elements.map((node, index) => {
      if (node.nodeType === Node.TEXT_NODE) {
        return node.textContent
      } else {
        const { tagName, attributes } = node
        const props = {}
        for (let i = 0; i < attributes.length; i++) {
          const { name, value } = attributes[i]
          props[name] = value
        }

        const children = parseHTMLString(node.innerHTML)

        return React.createElement(
          tagName.toLowerCase(),
          { key: index, ...props },
          children
        )
      }
    })
  }

  return (
    <div className='custom_grid_wrapper'>
      <div className='row-grid'>
        {Object.keys(section?.innerColumnClass || {}).map((innerColumnName) => {
          const columnKey = section?.innerColumnClass[innerColumnName]
          const column = section?.columns && section?.columns[innerColumnName]
          const columnNumberMatch = innerColumnName.match(/\d+/)
          const columnNumber = columnNumberMatch
            ? parseInt(columnNumberMatch[0])
            : null
          if (column && column?.single) {
            return column?.single?.map((card) => (
              <div
                className={`position-relative pv-globle-imgedit-btn ${card?.col_class}`}
                key={Math.floor(Math.random() * 100000)}
              >
                {card?.image ? (
                  <img
                    src={`${process.env.REACT_APP_IMG_URL}${
                      fromLendingPage ? _lendingPageImg_ : _homePageImg_
                    }${card?.image}`}
                    alt=''
                    style={{ objectFit: 'cover' }}
                    className='img_customgrid w-100 h-100'
                  />
                ) : (
                  <>
                    {card?.title &&
                      parseHTMLString(card?.title)?.map(
                        (element, index) => element
                      )}
                    {card?.sub_title &&
                      parseHTMLString(card?.sub_title)?.map(
                        (element, index) => element
                      )}
                    {card?.description &&
                      parseHTMLString(card?.description)?.map(
                        (element, index) => element
                      )}
                  </>
                )}
                <ImgEditComponet
                  sectionDelete={() => {
                    sectionDelete(card?.section_details_id, true)
                  }}
                  sectionEdit={(optionName, optionId) => {
                    let layoutTypeDetailsId = layoutsInfo?.layout_details?.find(
                      (obj) =>
                        obj?.section_type?.toLowerCase() === 'single' &&
                        obj?.layout_type_detail_name?.toLowerCase() === column
                    )?.layout_type_detail_id
                    setLayoutDetails({
                      show: !layoutDetails?.show,
                      sectionId: section?.section_id,
                      minImagesLength,
                      maxImagesLength,
                      layoutTypeDetailsId,
                      columnNumber,
                      dataTypeToSave: { optionName, optionId },
                      sectionDetailsId: card?.section_details_id
                    })
                  }}
                  showDeleteIcon={true}
                  showMultipleOptions
                  allState={allState}
                />
              </div>
            ))
          } else {
            return (
              <div
                className={`position-relative pv-globle-imgedit-btn ${columnKey}`}
                key={Math.floor(Math.random() * 100000)}
              >
                <img
                  style={{ objectFit: 'cover' }}
                  src='https://placehold.jp/800x800.png'
                  alt=''
                  className='img_customgrid w-100 h-100'
                />
                <ImgEditComponet
                  sectionEdit={(optionName, optionId) => {
                    let layoutTypeDetailsId = layoutsInfo?.layout_details?.find(
                      (obj) =>
                        obj?.section_type?.toLowerCase() === 'single' &&
                        obj?.layout_type_detail_name?.toLowerCase() === column
                    )?.layout_type_detail_id
                    setLayoutDetails({
                      show: !layoutDetails?.show,
                      sectionId: section?.section_id,
                      minImagesLength,
                      maxImagesLength,
                      layoutTypeDetailsId,
                      columnNumber,
                      dataTypeToSave: { optionName, optionId }
                    })
                  }}
                  showDeleteIcon={false}
                  showMultipleOptions
                  allState={allState}
                />
              </div>
            )
          }
        })}
      </div>
    </div>
  )
}

export default CustomGrid
