import React from 'react'
import PriceVariant from './PriceVariant'
import { isAllowPriceVariant } from '../../lib/GetBaseUrl'
import PricingCalculation from './PricingCalculation'
import PackagingDetails from './PackagingDetails'
import PricingDetails from './PricingDetails'
import ManageSizeWarehouseQuantities from './ManageSizeWarehouseQuantities'
import ManageSizeValues from './ManageSizeValues'
import FeaturesAndAttributes from './FeaturesAndAttributes'

const WarehouseAndPrice = ({
  allState,
  values,
  setFieldValue,
  fetchCalculation,
  calculation,
  setCalculation
}) => {
  return (
    <>
      {(allState?.color?.length > 0 || allState?.sizeType?.length > 0) && (
        <div className='card'>
          <div className='card-body'>
            <div>
              <h5 className='mb-3 head_h3'>Attributes</h5>
              <div className='row'>
                <FeaturesAndAttributes
                  values={values}
                  setFieldValue={setFieldValue}
                  allState={allState}
                />

                {values?.productPrices?.length > 0 &&
                  !values?.isSizeWisePriceVariant &&
                  allState?.sizeType?.length > 0 && (
                    <ManageSizeValues
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                  )}

                {values?.productPrices?.length > 0 &&
                  !values?.isSizeWisePriceVariant &&
                  values?.productPrices
                    ?.filter((productPrice) => productPrice?.isCheckedForQty)
                    ?.map((productPrice) => (
                      <ManageSizeWarehouseQuantities
                        productPrice={productPrice}
                        values={values}
                        setFieldValue={setFieldValue}
                        allState={allState}
                      />
                    ))}
              </div>
            </div>
          </div>
        </div>
      )}

      <div className='row'>
        {/* <div className='col-md-6'>
          <div className='card'>
            <div className='card-body'>
              <div className='row mb-3'>
                {!values?.isSizeWisePriceVariant && (
                  <PackagingDetails
                    values={values}
                    setFieldValue={setFieldValue}
                    allState={allState}
                    calculation={calculation}
                    setCalculation={setCalculation}
                    fetchCalculation={fetchCalculation}
                  />
                )}


                {values?.isSizeWisePriceVariant && (
                  <PackagingDetails
                    values={values}
                    setFieldValue={setFieldValue}
                    allState={allState}
                    calculation={calculation}
                    setCalculation={setCalculation}
                    fetchCalculation={fetchCalculation}
                  />
                )}
              </div>
              {isAllowPriceVariant && (
                <PriceVariant
                  values={values}
                  setFieldValue={setFieldValue}
                  setCalculation={setCalculation}
                  calculation={calculation}
                  fetchCalculation={fetchCalculation}
                  allState={allState}
                />
              )}
            </div>
          </div>
        </div> */}
        {/* {calculation?.displayCalculation && (
          <PricingCalculation
            displayCalculation={calculation?.displayCalculation}
          />
        )} */}
        {/* {
          <PricingDetails
            values={values}
            allState={allState}
            setFieldValue={setFieldValue}
            fetchCalculation={fetchCalculation}
            setCalculation={setCalculation}
            calculation={calculation}
          />
        } */}
      </div>
    </>
  )
}

export default WarehouseAndPrice
