import React from 'react'
import Select from 'react-select'
import { Form as frm, ToggleButton, InputGroup } from 'react-bootstrap'
import {
  changeHandler,
  getUniqueListBy,
  isAllowPriceVariant,
  prepareProductName
} from '../../lib/GetBaseUrl'

const FeaturesAndAttributes = ({ values, setFieldValue, allState }) => {
  return (
    <div>
      {!values?.isExistingProduct && allState?.color?.length > 0 && (
        <div className='col-md-4'>
          <div className='input-file-wrapper mb-3'>
            <label className='form-label required'>Select Color</label>
            <Select
              id='scolor'
              isDisabled={
                values?.isAllowColorsInVariant ||
                (values?.isAllowColorsInVariant && values?.productId)
                  ? true
                  : false
              }
              placeholder='Select Color'
              value={
                values?.tempColorId && {
                  value: values?.tempColorId,
                  label: allState?.editData
                    ? allState?.color?.filter(
                        (data) => data?.id === values?.tempColorId
                      )[0]?.name
                    : values?.tempColorName
                }
              }
              options={
                allState?.color &&
                allState?.color?.map(({ id, name }) => ({
                  value: id,
                  label: name
                }))
              }
              onBlur={() => {
                if (values?.tempColorId) {
                  setFieldValue('validation', {
                    ...values?.validation,
                    tempColorId: ''
                  })
                } else {
                  setFieldValue('validation', {
                    ...values?.validation,
                    tempColorId: 'Color Required'
                  })
                }
              }}
              onChange={(e) => {
                if (e?.value) {
                  setFieldValue('validation', {
                    ...values?.validation,
                    tempColorId: ''
                  })
                } else {
                  setFieldValue('validation', {
                    ...values?.validation,
                    tempColorId: 'Color Required'
                  })
                }
                values?.isAllowColorsInTitle &&
                  setFieldValue(
                    'productName',
                    prepareProductName(
                      e?.label,
                      values?.titleSequenceOfColor,
                      'color',
                      values,
                      values?.isAllowColorsInTitle
                    )
                  )
                setFieldValue('tempColorId', e?.value)
                setFieldValue('tempColorName', e?.label)
                let productColorMappingArray = values?.productColorMapping ?? []
                let list = {
                  colorId: e?.value
                }
                if (productColorMappingArray?.length > 0)
                  productColorMappingArray = productColorMappingArray?.filter(
                    (productColor) => productColor?.colorId !== e?.value
                  )
                productColorMappingArray.push(list)
                setFieldValue('productColorMapping', productColorMappingArray)

                let productPrices = []
                if (!allState?.sizeType?.length) {
                  productPrices.push({
                    mrp: 1000,
                    sellingPrice: 1000,
                    discount: '0',
                    quantity: 1,
                    sizeID: null,
                    productWarehouses: []
                  })
                  setFieldValue('productPrices', productPrices)
                }
              }}
            />
            {values?.validation?.tempColorId && (
              <span className='text-danger'>
                {values?.validation?.tempColorId}
              </span>
            )}
          </div>
        </div>
      )}

      {allState?.sizeType?.length > 0 && !values?.isExistingProduct && (
        <div className='col'>
          <div className='input-file-wrapper mb-3'>
            <label className='form-label required'>Size Type</label>
            <div className='d-flex align-items-center gap-3 flex-wrap'>
              {getUniqueListBy(allState?.sizeType, 'sizeTypeID')?.map(
                ({ sizeTypeName, sizeTypeID }) => (
                  <ToggleButton
                    key={sizeTypeID}
                    className='mb-2'
                    id={sizeTypeID}
                    checked={sizeTypeID === values?.tempSizeId ? true : false}
                    disabled={
                      values?.isExistingProduct ||
                      values?.productId ||
                      values?.isAllowSizeInVariant
                        ? true
                        : false
                    }
                    // disabled={
                    //   values?.productId &&
                    //   sizeTypeID !== values?.tempSizeId &&
                    //   true
                    // }
                    type='checkbox'
                    name={sizeTypeName}
                    variant='outline-primary'
                    onBlur={() => {
                      if (values?.tempSizeId) {
                        setFieldValue('validation', {
                          ...values?.validation,
                          tempSizeId: ''
                        })
                      } else {
                        setFieldValue('validation', {
                          ...values?.validation,
                          tempSizeId: 'Size Type Required'
                        })
                      }
                    }}
                    onMouseDown={() => {
                      let filteredSizeData = allState?.sizeType?.filter(
                        (sizeDetails) => sizeDetails?.sizeTypeID === sizeTypeID
                      )
                      let { titleSequenceOfSize, isAllowSizeInTitle } =
                        filteredSizeData[0]
                      if (values?.tempSizeId !== sizeTypeID) {
                        setFieldValue('tempSizeId', sizeTypeID)
                        setFieldValue('quantity', '')
                        setFieldValue('tempSizeValueId', null)
                        setFieldValue('tempSizeValueName', null)

                        setFieldValue(
                          'titleSequenceOfSize',
                          titleSequenceOfSize
                        )
                        setFieldValue('isAllowSizeInTitle', isAllowSizeInTitle)

                        setFieldValue(
                          'productName',
                          prepareProductName(
                            sizeTypeName,
                            3,
                            'size',
                            values,
                            false
                          )
                        )

                        let productPriceList = filteredSizeData?.map(
                          (sizeDetails) => {
                            return {
                              mrp: values?.mrp ?? '',
                              sellingPrice: values?.sellingPrice ?? '',
                              discount: values?.discount ?? '',
                              quantity: '',
                              sizeID: sizeDetails?.sizeId,
                              sizeName: sizeDetails?.sizeName,
                              isCheckedForQty: false
                            }
                          }
                        )

                        setFieldValue('productPrices', productPriceList)
                      }
                    }}
                  >
                    {sizeTypeName}
                  </ToggleButton>
                )
              )}
              {values?.validation?.tempSizeId && (
                <span className='text-danger'>
                  {values?.validation?.tempSizeId}
                </span>
              )}
            </div>
          </div>
        </div>
      )}
      {isAllowPriceVariant &&
        values?.isAllowPriceVariant &&
        values?.tempSizeId && (
          <div className='col-md-3 mt-auto mb-2'>
            <InputGroup className='custom_checkbox'>
              <InputGroup.Checkbox
                id='isSizeWisePriceVariant'
                checked={values?.isSizeWisePriceVariant ? true : false}
                onChange={(e) => {
                  changeHandler(
                    'isSizeWisePriceVariant',
                    e?.target?.checked,
                    setFieldValue
                  )
                  setFieldValue('mrp', '')
                  setFieldValue('discount', '')
                  setFieldValue('sellingPrice', '')
                  let productPriceList = values?.productPrices ?? []

                  if (productPriceList.length > 0) {
                    productPriceList = productPriceList?.map((data) => {
                      // if (values?.productId) {
                      //   if (e?.target?.checked) {
                      //     let originalProductPrice =
                      //       values?.sellerProducts[0]?.productPrices?.find(
                      //         (product) => product?.id === data?.id
                      //       )

                      //     return {
                      //       ...data,
                      //       discount: originalProductPrice?.discount,
                      //       mrp: originalProductPrice?.mrp,
                      //       sellingPrice: originalProductPrice?.sellingPrice,
                      //       isDataInTable: e?.target?.checked
                      //     }
                      //   } else {
                      //     return {
                      //       ...data,
                      //       isDataInTable: false,
                      //       mrp: '',
                      //       sellingPrice: '',
                      //       discount: '',
                      //       isCheckedForQty: false
                      //     }
                      //   }
                      // } else {
                      setFieldValue('tempSizeValueId', null)
                      setFieldValue('tempSizeValueName', '')
                      return {
                        ...data,
                        mrp: '',
                        sellingPrice: '',
                        discount: '',
                        isCheckedForQty: false,
                        isDataInTable: false,
                        perWarehouseStock: ''
                      }
                      // }
                    })
                  }

                  setFieldValue(
                    'productName',
                    prepareProductName(
                      'Price variant',
                      2,
                      'size',
                      values,
                      false
                    )
                  )
                  setFieldValue('productPrices', productPriceList)
                }}
              />
              <label className='custom_label' htmlFor='isSizeWisePriceVariant'>
                Has Price Variants
              </label>
            </InputGroup>
          </div>
        )}
    </div>
  )
}

export default FeaturesAndAttributes
