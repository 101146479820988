import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { setPageTitle } from '../../redux/slice/pageTitleSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import {
  allCrudNames,
  allPages,
  checkPageAccess
} from '../../../lib/AllPageNames'
import NotFound from '../../NotFound/NotFound'
import MainBimCad from './MainBimCad'

const ManageBimCad = () => {
  const [activeToggle, setActiveToggle] = useState('bimCad')
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setPageTitle('Manage Bim Cad'))
  }, [])

  const { pageAccess } = useSelector((state) => state.user)

  return checkPageAccess(
    pageAccess,
    allPages?.manageBimCad,
    allCrudNames?.read
  ) ? (
    <>
      {checkPageAccess(
        pageAccess,
        allPages?.manageBimCad,
        allCrudNames?.read
      ) && (
        <div className='card overflow-hidden'>
          <div className='card-body p-0'>
            <div className='nav-tabs-horizontal nav nav-tabs'>
              <Link
                onClick={() => setActiveToggle('bimCad')}
                data-toggle='tab'
                className={`nav-link fw-semibold ${
                  activeToggle === 'bimCad' ? 'active show' : ''
                }`}
              >
                <span className='nav-span'>
                  Bim Cad
                  <hr />
                </span>
              </Link>
            </div>

            <div className='tab-content p-3'>
              <div
                id='bimCad'
                className={`tab-pane fade ${
                  activeToggle === 'bimCad' ? 'active show' : ''
                }`}
              >
                {activeToggle === 'bimCad' && <MainBimCad />}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  ) : (
    <NotFound />
  )
}

export default ManageBimCad
