import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import AssignBrandToSeller from './AssignBrandToSeller'
import MainBrand from './MainBrand'
import { setPageTitle } from '../../redux/slice/pageTitleSlice'
import { useDispatch, useSelector } from 'react-redux'
import {
  allCrudNames,
  allPages,
  checkPageAccess
} from '../../../lib/AllPageNames'
import NotFound from '../../NotFound/NotFound'

const ManageBrand = () => {
  // const [activeToggle, setActiveToggle] = useState('brand')
  const { assignBrand } = useParams()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setPageTitle('Manage Brand'))
  }, [])

  // useEffect(() => {
  //   titleOfTab(activeToggle)
  // }, [activeToggle]);
  const { pageAccess } = useSelector((state) => state.user)

  const [activeToggle, setActiveToggle] = useState(
    Number(assignBrand)
      ? 'assign-brand'
      : checkPageAccess(pageAccess, allPages?.Brand, allCrudNames?.read)
      ? 'brand'
      : checkPageAccess(
          pageAccess,
          allPages?.assignBrandToSeller,
          allCrudNames?.read
        )
      ? 'assign-brand'
      : ''
  )

  return checkPageAccess(
    pageAccess,
    [allPages?.Brand, allPages?.assignBrandToSeller],
    allCrudNames?.read
  ) ? (
    <>
      <div className='card overflow-hidden'>
        <div className='card-body p-0'>
          {/* Tabs Links */}
          <div className='nav-tabs-horizontal nav nav-tabs'>
            {checkPageAccess(
              pageAccess,
              allPages?.Brand,
              allCrudNames?.read
            ) && (
              <Link
                onClick={() => setActiveToggle('brand')}
                data-toggle='tab'
                className={`nav-link fw-semibold ${
                  activeToggle === 'brand' ? 'active show' : ''
                }`}
              >
                <span className='nav-span'>
                  Brand
                  <hr />
                </span>
              </Link>
            )}
            {/* {checkPageAccess(
              pageAccess,
              allPages?.assignBrandToSeller,
              allCrudNames?.read
            ) && (
              <Link
                onClick={() => setActiveToggle('assign-brand')}
                data-toggle='tab'
                className={`nav-link fw-semibold ${
                  activeToggle === 'assign-brand' ? 'active show' : ''
                }`}
              >
                <span className='nav-span'>
                  Assign Brand To Seller
                  <hr />
                </span>
              </Link>
            )} */}
          </div>
          {/* Tabs Links End */}

          {/* Content Section Start */}

          <div className='tab-content p-3'>
            {/* First Tab content Start */}
            {checkPageAccess(
              pageAccess,
              allPages?.Brand,
              allCrudNames?.read
            ) && (
              <div
                id='brand'
                className={`tab-pane fade ${
                  activeToggle === 'brand' ? 'active show' : ''
                }`}
              >
                {activeToggle === 'brand' && <MainBrand />}

                {/* Pagination End */}
              </div>
            )}
            {/* {checkPageAccess(
              pageAccess,
              allPages?.assignBrandToSeller,
              allCrudNames?.read
            ) && (
              <div
                id='assign-brand'
                className={`tab-pane fade ${
                  activeToggle === 'assign-brand' ? 'active show' : ''
                }`}
              >
                {activeToggle === 'assign-brand' && <AssignBrandToSeller />}
              </div>
            )} */}
          </div>
        </div>
      </div>
    </>
  ) : (
    <NotFound />
  )
}

export default ManageBrand
