import React from 'react'
import Select from 'react-select'
import { Form as frm, ToggleButton, InputGroup } from 'react-bootstrap'
import FormikControl from '../../components/FormikControl'
import { getUniqueListBy } from '../../lib/GetBaseUrl'
import PricingCalculation from './PricingCalculation'
import PackagingDetails from './PackagingDetails'
import ManageSizeWarehouseQuantities from './ManageSizeWarehouseQuantities'
import ManageSizeValues from './ManageSizeValues'
import PricingDetails from './PricingDetails'
import FeaturesAndAttributes from './FeaturesAndAttributes'

const WarehouseNotPrice = ({
  allState,
  values,
  setFieldValue,
  fetchCalculation,
  calculation,
  setCalculation
}) => {
  return (
    <>
      {(allState?.color?.length > 0 || allState?.sizeType?.length > 0) && (
        <div className='card'>
          <div className='card-body'>
            <div>
              <h5 className='mb-3 head_h3'>Attributes</h5>
              <div className='row'>
                <FeaturesAndAttributes
                  values={values}
                  setFieldValue={setFieldValue}
                  allState={allState}
                />

                {values?.productPrices?.length > 0 &&
                  allState?.sizeType?.length > 0 && (
                    <ManageSizeValues
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                  )}

                {values?.productPrices?.length > 0 &&
                  values?.productPrices
                    ?.filter((productPrice) => productPrice?.isCheckedForQty)
                    ?.map((productPrice) => (
                      <ManageSizeWarehouseQuantities
                        productPrice={productPrice}
                        values={values}
                        setFieldValue={setFieldValue}
                        allState={allState}
                      />
                    ))}
              </div>
            </div>
          </div>
        </div>
      )}

      <div className='row'>
        {/* <div className='col-md-6'>
          <div className='card'>
            <div className='card-body'>
              <div className='row mb-3'>
                {
                  <PackagingDetails
                    values={values}
                    setFieldValue={setFieldValue}
                    allState={allState}
                    calculation={calculation}
                    setCalculation={setCalculation}
                    fetchCalculation={fetchCalculation}
                  />
                }
                
              </div>
            </div>
          </div>
        </div> */}
        {/* {
          <PricingDetails
            values={values}
            allState={allState}
            setFieldValue={setFieldValue}
            fetchCalculation={fetchCalculation}
            setCalculation={setCalculation}
            calculation={calculation}
          />
        } */}
        {/* {calculation?.displayCalculation && (
          <PricingCalculation
            displayCalculation={calculation?.displayCalculation}
          />
        )} */}
      </div>
    </>
  )
}

export default WarehouseNotPrice
