import React, { useState } from 'react'
import ModelComponent from '../../components/Modal'
import { ErrorMessage, Form, Formik } from 'formik'
import * as Yup from 'yup'
import axiosProvider from '../../lib/AxiosProvider'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Button, Col, Row } from 'react-bootstrap'
import Select from 'react-select'
import { customStyles } from '../../components/customStyles'
import TextError from '../../components/TextError'
import XLSX from 'sheetjs-style'
import {
  callApi,
  getBaseUrl,
  getDeviceId,
  getUserToken,
  showToast
} from '../../lib/GetBaseUrl'
import FileSaver from 'file-saver'
import Previewicon from '../../components/AllSvgIcon/Previewicon'
import IpFiletype from '../../components/IpFiletype'
import { _exception } from '../../lib/exceptionMessage'

const BulkUpload = ({
  modalShow,
  setModalShow,
  setLoading,
  allState,
  toast,
  setToast,
  filterDetails,
  fetchPageData,
  setAllState
}) => {
  const location = useLocation()
  const [modalDetails, setModalDetails] = useState()
  const { userId } = useSelector((state) => state?.user?.userInfo)
  const SUPPORTED_FORMATS = [
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  ]

  const validationSchema =
    modalDetails && modalDetails === 'download'
      ? Yup.object().shape({
          categoryId: Yup.string().required('Please select category'),
          sellerId: Yup.string().required('Please select seller')
        })
      : Yup.object().shape({
          categoryId: Yup.string().required('Please select category'),
          sellerId: Yup.string().required('Please select seller'),
          brandId: Yup.string().required('Please select brand'),
          file: Yup.mixed()
            .required('File is required')
            .test(
              'fileFormat',
              'File formate is not supported, Please use .xlsx format support',
              (value) => {
                if (typeof value === 'string') return true
                else {
                  return value && SUPPORTED_FORMATS?.includes(value.type)
                }
              }
            )
          // .test('fileSize', 'File must be less than 2MB', (value) => {
          //   if (typeof value === 'string') {
          //     return true
          //   } else
          //     return value !== undefined && value && value.size <= 2000000
          // })
        })

  const onSubmit = async (values, { setFieldValue }) => {
    try {
      if (modalDetails === 'download') {
        setLoading(true)
        let headers = new Headers()
        headers.append('Authorization', `Bearer ${getUserToken()}`)
        headers.append('device_id', `${getDeviceId()}`)
        let downloadUrl = `${getBaseUrl()}/Product/downloadProduct?categoryId=${
          values?.categoryId
        }&sellerId=${values?.sellerId}`
        fetch(downloadUrl, {
          method: 'GET',
          headers: headers
        })
          .then((response) => {
            setLoading(false)
            const blob = response.blob()
            return blob
          })
          .then((blob) => {
            const customFileName = 'Product.xlsx'
            FileSaver.saveAs(blob, customFileName)
            setModalShow(false)
          })
      } else {
        const dataofForm = {
          file: values.file,
          CategoryId: values?.categoryId,
          SellerId: values?.sellerId,
          BrandId: values?.brandId
        }

        const submitFormData = new FormData()

        const keys = Object.keys(dataofForm)

        keys.forEach((key) => {
          submitFormData.append(key, dataofForm[key])
        })

        const response = await axiosProvider({
          method: 'POST',
          endpoint: `Product/Bulkupload`,
          data: submitFormData,
          location: location?.pathname,
          userId
        })

        if (response?.data?.code === 200) {
          setModalShow(!modalShow)
          fetchPageData(filterDetails)
        }
        showToast(toast, setToast, response)
      }
    } catch (error) {
      showToast(toast, setToast, {
        data: {
          message: _exception?.message,
          code: 204
        }
      })
    }
  }

  return (
    <ModelComponent
      show={modalShow}
      modalsize={'md'}
      modalheaderclass={''}
      modeltitle={'Bulk Upload'}
      onHide={() => {
        setModalShow(!modalShow)
      }}
      btnclosetext={''}
      closebtnvariant={''}
      backdrop={'static'}
      setModalDetails={setModalDetails}
    >
      <Formik
        enableReinitialize={true}
        initialValues={{ categoryId: '', file: '', sellerId: '', brandId: '' }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form id='video'>
            {!modalDetails ? (
              <Row className='justify-content-around align-items-center'>
                <Col md={5}>
                  <Button
                    className='w-100'
                    variant='outline-primary'
                    style={{ height: '50px' }}
                    onClick={() => {
                      setFieldValue('categoryId', '')
                      setFieldValue('file', '')
                      setFieldValue('sellerId', '')
                      setFieldValue('brandId', '')
                      setModalDetails('download')
                    }}
                  >
                    Download
                  </Button>
                </Col>
                <Col md={5}>
                  <Button
                    className='w-100'
                    variant='outline-primary'
                    style={{ height: '50px' }}
                    onClick={() => {
                      setFieldValue('categoryId', '')
                      setFieldValue('file', '')
                      setFieldValue('sellerId', '')
                      setFieldValue('brandId', '')
                      setModalDetails('upload')
                    }}
                  >
                    Upload
                  </Button>
                </Col>
              </Row>
            ) : modalDetails === 'download' ? (
              <div className='row'>
                <span
                  onClick={() => {
                    setModalDetails()
                  }}
                  className='mb-3 w-auto'
                >
                  <svg
                    role='button'
                    fill='#212529a6'
                    xmlns='http://www.w3.org/2000/svg'
                    height='30'
                    viewBox='0 -960 960 960'
                    width='30'
                  >
                    <path d='m480-320 56-56-64-64h168v-80H472l64-64-56-56-160 160 160 160Zm0 240q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z' />
                  </svg>
                </span>
                <div className='col-md-12'>
                  <div className='input-select-wrapper mb-3'>
                    <label className='form-label required'>
                      Select Category
                    </label>
                    <Select
                      styles={customStyles}
                      id='categoryId'
                      menuPortalTarget={document.body}
                      placeholder='Select Category'
                      isClearable
                      value={
                        values?.categoryId && {
                          label: values?.categoryName,
                          value: values?.categoryId
                        }
                      }
                      options={
                        allState?.category &&
                        allState?.category?.map(({ id, pathNames, name }) => ({
                          value: id,
                          label: pathNames,
                          categoryName: name
                        }))
                      }
                      onChange={(e) => {
                        setFieldValue('categoryId', e?.value)
                        setFieldValue('categoryName', e?.label)
                      }}
                    />
                    <ErrorMessage name='categoryId' component={TextError} />
                  </div>
                </div>
                <div className='col-md-5'>
                  <div className='input-select-wrapper mb-3'>
                    <label className='form-label required'>Select seller</label>
                    <Select
                      styles={customStyles}
                      id='sellerDetails'
                      menuPortalTarget={document.body}
                      value={
                        values?.sellerId && {
                          label: values?.sellerName,
                          value: values?.sellerId
                        }
                      }
                      placeholder='Select Seller'
                      options={
                        allState?.sellerDetails &&
                        allState?.sellerDetails?.map(
                          ({ userID, fullName }) => ({
                            label: fullName,
                            value: userID
                          })
                        )
                      }
                      onChange={(e) => {
                        setFieldValue('sellerId', e?.value ?? '')
                        setFieldValue('sellerName', e?.label ?? '')
                        const fetchAllData = () => {
                          Promise.all([
                            callApi(
                              'AssignBrandToSeller/bySeller&BrandId',
                              `?sellerId=${e?.value}`
                            )
                          ])
                            .then(([brandResp]) => {
                              setAllState({
                                ...allState,
                                sellerSpecificBrand: brandResp ?? []
                              })
                            })
                            .catch(() => {})
                        }
                        if (e?.value) {
                          fetchAllData()
                        } else {
                          setAllState({
                            ...allState,
                            sellerBrand: null
                          })
                        }
                      }}
                    />
                    <ErrorMessage name='sellerId' component={TextError} />
                  </div>
                </div>
                <div className='col-12 text-center'>
                  <Button
                    variant='primary'
                    className='fw-semibold px-3'
                    type='submit'
                  >
                    Download
                  </Button>
                </div>
              </div>
            ) : (
              <div className='row w-100 m-auto'>
                <span
                  onClick={() => {
                    setModalDetails()
                  }}
                  className='col-md-2 m-auto'
                >
                  <svg
                    role='button'
                    fill='#212529a6'
                    xmlns='http://www.w3.org/2000/svg'
                    height='30'
                    viewBox='0 -960 960 960'
                    width='30'
                  >
                    <path d='m480-320 56-56-64-64h168v-80H472l64-64-56-56-160 160 160 160Zm0 240q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z' />
                  </svg>
                </span>

                <div className='col-md-5'>
                  <div className='input-select-wrapper mb-3'>
                    <label className='form-label required'>Select seller</label>
                    <Select
                      styles={customStyles}
                      id='sellerDetails'
                      menuPortalTarget={document.body}
                      value={
                        values?.sellerId && {
                          label: values?.sellerName,
                          value: values?.sellerId
                        }
                      }
                      placeholder='Select Seller'
                      options={
                        allState?.sellerDetails &&
                        allState?.sellerDetails?.map(
                          ({ userID, fullName }) => ({
                            label: fullName,
                            value: userID
                          })
                        )
                      }
                      onChange={(e) => {
                        setFieldValue('sellerId', e?.value ?? '')
                        setFieldValue('sellerName', e?.label ?? '')
                        const fetchAllData = () => {
                          Promise.all([
                            callApi(
                              'AssignBrandToSeller/bySeller&BrandId',
                              `?sellerId=${e?.value}`
                            )
                          ])
                            .then(([brandResp]) => {
                              setAllState({
                                ...allState,
                                sellerSpecificBrand: brandResp ?? []
                              })
                              if (brandResp?.length === 1) {
                                let data = brandResp[0]
                                setFieldValue('brandName', data?.brandName)
                                setFieldValue('brandId', data?.brandId)
                              }
                            })
                            .catch(() => {})
                        }
                        if (e?.value) {
                          fetchAllData()
                        } else {
                          setAllState({
                            ...allState,
                            sellerBrand: null
                          })
                        }
                      }}
                    />
                    <ErrorMessage name='sellerId' component={TextError} />
                  </div>
                </div>
                <div className='col-md-5'>
                  <div className='input-select-wrapper mb-3'>
                    <label className='form-label required'>Select brand</label>
                    <Select
                      styles={customStyles}
                      id='brandId'
                      menuPortalTarget={document.body}
                      value={
                        values?.brandId && {
                          label: values?.brandName,
                          value: values?.brandId
                        }
                      }
                      placeholder='Select Brand'
                      options={
                        allState?.sellerSpecificBrand
                          ? allState?.sellerSpecificBrand?.map(
                              ({ brandId, brandName }) => ({
                                label: brandName,
                                value: brandId
                              })
                            )
                          : allState?.brand?.map(({ id, name }) => ({
                              value: id,
                              label: name
                            }))
                      }
                      onChange={(e) => {
                        setFieldValue('brandId', e?.value)
                        setFieldValue('brandName', e?.label)
                      }}
                    />
                    <ErrorMessage name='brandId' component={TextError} />
                  </div>
                </div>
                <div className='col-md-12'>
                  <div className='input-select-wrapper mb-3'>
                    <label className='form-label required'>
                      Select Category
                    </label>
                    <Select
                      styles={customStyles}
                      id='categoryId'
                      menuPortalTarget={document.body}
                      placeholder='Select Category'
                      isClearable
                      value={
                        values?.categoryId && {
                          label: values?.categoryName,
                          value: values?.categoryId
                        }
                      }
                      options={
                        allState?.category &&
                        allState?.category?.map(({ id, pathNames, name }) => ({
                          value: id,
                          label: pathNames,
                          categoryName: name
                        }))
                      }
                      onChange={(e) => {
                        setFieldValue('categoryId', e?.value)
                        setFieldValue('categoryName', e?.label)
                      }}
                    />
                    <ErrorMessage name='categoryId' component={TextError} />
                  </div>
                </div>
                <div className='col-md-12'>
                  <IpFiletype
                    labelClass='required'
                    filelbtext='Select file'
                    accept='.xlsx'
                    capture='.xlsx'
                    onChange={(e) => {
                      setFieldValue('file', e?.target?.files[0])
                    }}
                  />
                  <ErrorMessage name='file' component={TextError} />
                </div>
                <div className='col-12 text-center'>
                  <Button
                    variant='primary'
                    className='fw-semibold px-3'
                    type='submit'
                  >
                    Upload
                  </Button>
                </div>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </ModelComponent>
  )
}
export default BulkUpload
