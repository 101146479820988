export const _integerRegex_ = /^[0-9\b]+$/
export const _percentageRegex_ = /^100$|^\d{0,2}(\.\d{0,2})?$/
export const _pincodeRegex_ = /^\d{6}$/
export const _positiveInteger_ = /^[1-9]\d*$/
export const _alphabetRegex_ = /^[A-Za-z\s]+$/
export const _emailRegex_ = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/
export const _phoneNumberRegex_ = /^[6-9]\d{0,9}$/
export const _weightSlabRegex_ = /^[0-9.]+$/
export const _decimalNumeberRegex_ = /^\d+(\.\d+)?$/
export const _passwordRegex_ =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z0-9]).{6,}$/

export const _gstNumberRegex_ =
  /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/
