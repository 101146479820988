import React, { useRef } from "react";
import { Col, Image, Row } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { _homePageImg_, _lendingPageImg_ } from "../../lib/ImagePath";
import { _SwalDelete } from "../../lib/exceptionMessage";
import Swal from "sweetalert2";
import ComponentEdit from "./ComponentEdit";
import ImgEditComponet from "./ImgEditComponet";
import DynamicPositionComponent from "./HeadingComponent";

const HomePageThumbnail = ({
  layoutsInfo,
  section,
  setLayoutDetails,
  layoutDetails,
  handleDelete,
  fromLendingPage = false,
  modalShow,
  setModalShow,
  handleImgDelete,
}) => {
  const minImagesLength = layoutsInfo?.layout_min_images;
  const maxImagesLength = layoutsInfo?.layout_max_images;
  const sliderRefs = useRef(null);
  const withSlider = layoutsInfo?.layout_class
    ?.toLowerCase()
    ?.includes("slider");
  const withPadding = layoutsInfo?.layout_class
    ?.toLowerCase()
    ?.includes("with-pd");

  const deleteThumbline = (id) => {
    Swal.fire({
      title: _SwalDelete.title,
      text: _SwalDelete.text,
      icon: _SwalDelete.icon,
      showCancelButton: _SwalDelete.showCancelButton,
      confirmButtonColor: _SwalDelete.confirmButtonColor,
      cancelButtonColor: _SwalDelete.cancelButtonColor,
      confirmButtonText: _SwalDelete.confirmButtonText,
      cancelButtonText: _SwalDelete.cancelButtonText,
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(id);
      } else if (result.isDenied) {
      }
    });
  };

  const settings = {
    dots: false,
    arrows: false,
    infinite: section?.totalRowsInSection ? false : true,
    speed: 500,
    slidesToShow:
      section?.SectionColumns > 0
        ? section?.SectionColumns
        : section?.columns?.left?.single?.length > 7
        ? 7
        : section?.columns?.left?.single?.length + 1,
    slidesToScroll: 1,
    rows: section?.totalRowsInSection ? section?.totalRowsInSection : 1,
  };

  const sectionDelete = (id, specificImgDelete = false) => {
    Swal.fire({
      title: _SwalDelete.title,
      text: _SwalDelete.text,
      icon: _SwalDelete.icon,
      showCancelButton: _SwalDelete.showCancelButton,
      confirmButtonColor: _SwalDelete.confirmButtonColor,
      cancelButtonColor: _SwalDelete.cancelButtonColor,
      confirmButtonText: _SwalDelete.confirmButtonText,
      cancelButtonText: _SwalDelete.cancelButtonText,
    }).then((result) => {
      if (result.isConfirmed) {
        if (specificImgDelete) {
          let endpoint = fromLendingPage
            ? `LendingPageSectionDetails?id=${id}`
            : `ManageHomePageDetails?id=${id}`;
          handleImgDelete(endpoint);
        } else {
          handleDelete(section?.section_id);
        }
      } else if (result.isDenied) {
      }
    });
  };

  const sectionEdit = () => {
    setModalShow({
      ...modalShow,
      show: !modalShow?.show,
      layoutId: layoutsInfo?.layout_id,
      layoutName: layoutsInfo?.layout_name?.toLowerCase()?.includes("product")
        ? "Product List"
        : layoutsInfo?.layout_name,
      layoutTypeId: layoutsInfo?.layout_type_id,
      layoutTypeName: layoutsInfo?.layout_type_name,
      sectionId: section?.section_id,
      type: "normalLayoutSelection",
    });
  };

  return (
    <Row className="justify-content-between">
      <Col className="p-0" xs={12} sm={6} md={12}>
        {/* <div className='position-relative'> */}
        {withSlider &&
        section?.columns?.left?.single?.length > section?.SectionColumns ? (
          <ComponentEdit
            sectionDelete={sectionDelete}
            sectionEdit={sectionEdit}
            sectionStatus={section?.status}
          >
            <DynamicPositionComponent
              heading={section?.title}
              paragraph={section?.sub_title}
              headingPosition={
                section?.title_position?.toLowerCase() === "left"
                  ? "start"
                  : section?.title_position?.toLowerCase() === "center"
                  ? "center"
                  : "end"
              }
              buttonPosition={
                section?.link_in?.toLowerCase() === "section"
                  ? section?.link_position?.toLowerCase() === "left"
                    ? "start"
                    : section?.link_position?.toLowerCase() === "center"
                    ? "center"
                    : "end"
                  : section?.title_position?.toLowerCase() === "left"
                  ? "end"
                  : section?.title_position?.toLowerCase() === "right"
                  ? "start"
                  : "center"
              }
              buttonPositionDirection={section?.link_in?.toLowerCase()}
              link_text={section?.link_text}
              link={section?.link}
              textColor={section?.text_color}
              titleColor={section?.title_color}
              backgroundColor={section?.background_color}
            >
              <div className="position-relative">
                <Slider {...settings} ref={sliderRefs}>
                  {section?.columns?.left?.single?.map((data, index) => (
                    <div
                      key={Math.floor(Math.random() * 100000)}
                      className="position-relative pv-globle-imgedit-btn temp p-2"
                    >
                      <Image
                        style={{ objectFit: "cover" }}
                        className={`w-100 h-100 thumbnail ${
                          withPadding ? "p-2" : ""
                        }`}
                        src={`${process.env.REACT_APP_IMG_URL}${
                          fromLendingPage ? _lendingPageImg_ : _homePageImg_
                        }${data?.image}`}
                        alt={data?.image_alt}
                        fluid
                      />
                      <ImgEditComponet
                        sectionDelete={() => {
                          sectionDelete(data?.section_details_id, true);
                        }}
                        sectionEdit={() =>
                          setLayoutDetails({
                            show: !layoutDetails.show,
                            layoutTypeName: layoutsInfo?.layout_type_name,
                            sectionId: section?.section_id,
                            minImagesLength,
                            maxImagesLength,
                            layoutName: layoutsInfo?.layout_name,
                            sectionDetailsId: data?.section_details_id,
                          })
                        }
                      />
                    </div>
                  ))}
                  {section?.columns?.left?.single?.length < maxImagesLength && (
                    <div
                      key={Math.floor(Math.random() * 100000)}
                      className="position-relative pv-globle-imgedit-btn temp"
                    >
                      <Image
                        onClick={() => {
                          setLayoutDetails({
                            show: !layoutDetails.show,
                            sectionId: section?.section_id,
                            layoutTypeName: layoutsInfo?.layout_type_name,
                            minImagesLength,
                            maxImagesLength,
                            layoutName: layoutsInfo?.layout_name,
                          });
                        }}
                        className={`w-100 thumbnail ${
                          withPadding ? "p-2" : ""
                        }`}
                        src="https://placehold.jp/600x600.png"
                        alt="Add Image"
                        fluid
                      />
                      <ImgEditComponet
                        showDeleteIcon={false}
                        sectionEdit={() =>
                          setLayoutDetails({
                            show: !layoutDetails.show,
                            layoutTypeName: layoutsInfo?.layout_type_name,
                            sectionId: section?.section_id,
                            minImagesLength,
                            maxImagesLength,
                            layoutName: layoutsInfo?.layout_name,
                          })
                        }
                      />
                    </div>
                  )}
                </Slider>
                <div className="pv-thumbline-btn-main">
                  <button
                    className="slide_btn pv-thumbline-slide_left border-0 position-absolute"
                    style={{ top: "41%", left: "0px" }}
                    onClick={() => {
                      sliderRefs.current.slickPrev();
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12.025"
                      height="21.549"
                      viewBox="0 0 12.025 21.549"
                    >
                      <g id="Icon" transform="translate(1.25 1.25)">
                        <g
                          id="Icon-2"
                          data-name="Icon"
                          transform="translate(0)"
                        >
                          <path
                            id="Path"
                            d="M17.025,25.3a1.246,1.246,0,0,1-.884-.366L6.616,15.409a1.25,1.25,0,0,1,0-1.768l9.525-9.525a1.25,1.25,0,0,1,1.768,1.768L9.268,14.525l8.641,8.641a1.25,1.25,0,0,1-.884,2.134Z"
                            transform="translate(-7.5 -5)"
                            fill="#3d3d3d"
                          />
                        </g>
                      </g>
                    </svg>
                  </button>
                  <button
                    className="slide_btn pv-thumbline-slide_right border-0 position-absolute"
                    style={{ top: "41%", right: "0px" }}
                    onClick={() => {
                      sliderRefs.current.slickNext();
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12.025"
                      height="21.549"
                      viewBox="0 0 12.025 21.549"
                    >
                      <g id="Icon" transform="translate(-6.25 -3.75)">
                        <g
                          id="Icon-2"
                          data-name="Icon"
                          transform="translate(7.5 5)"
                        >
                          <path
                            id="Path"
                            d="M7.5,25.3a1.25,1.25,0,0,1-.884-2.134l8.641-8.641L6.616,5.884A1.25,1.25,0,0,1,8.384,4.116l9.525,9.525a1.25,1.25,0,0,1,0,1.768L8.384,24.933A1.246,1.246,0,0,1,7.5,25.3Z"
                            transform="translate(-7.5 -5)"
                            fill="#3d3d3d"
                          />
                        </g>
                      </g>
                    </svg>
                  </button>
                </div>
              </div>
            </DynamicPositionComponent>
          </ComponentEdit>
        ) : section?.columns?.left?.single?.length > 0 ? (
          <ComponentEdit
            sectionDelete={sectionDelete}
            sectionEdit={sectionEdit}
            sectionStatus={section?.status}
          >
            <DynamicPositionComponent
              heading={section?.title}
              paragraph={section?.sub_title}
              headingPosition={
                section?.title_position?.toLowerCase() === "left"
                  ? "start"
                  : section?.title_position?.toLowerCase() === "center"
                  ? "center"
                  : "end"
              }
              buttonPosition={
                section?.link_in?.toLowerCase() === "section"
                  ? section?.link_position?.toLowerCase() === "left"
                    ? "start"
                    : section?.link_position?.toLowerCase() === "center"
                    ? "center"
                    : "end"
                  : section?.title_position?.toLowerCase() === "left"
                  ? "end"
                  : section?.title_position?.toLowerCase() === "right"
                  ? "start"
                  : "center"
              }
              buttonPositionDirection={section?.link_in?.toLowerCase()}
              link_text={section?.link_text}
              link={section?.link}
              textColor={section?.text_color}
              titleColor={section?.title_color}
              backgroundColor={section?.background_color}
            >
              <div
                className="pv-home-thumbline-main"
                style={{
                  gridTemplateColumns: `repeat(${section?.SectionColumns}, minmax(0, 1fr))`,
                }}
              >
                {section?.columns?.left?.single
                  ?.map((data, index) => (
                    <div
                      key={Math.floor(Math.random() * 100000)}
                      className="position-relative pv-globle-imgedit-btn"
                    >
                      <Image
                        style={{ objectFit: "cover" }}
                        className={`w-100 h-100 thumbnail ${
                          withPadding ? "p-2" : ""
                        }`}
                        src={`${process.env.REACT_APP_IMG_URL}${
                          fromLendingPage ? _lendingPageImg_ : _homePageImg_
                        }${data?.image}`}
                        alt={data?.image_alt}
                        fluid
                      />
                      <ImgEditComponet
                        sectionDelete={() => {
                          sectionDelete(data?.section_details_id, true);
                        }}
                        sectionEdit={() =>
                          setLayoutDetails({
                            show: !layoutDetails.show,
                            layoutTypeName: layoutsInfo?.layout_type_name,
                            sectionId: section?.section_id,
                            minImagesLength,
                            maxImagesLength,
                            layoutName: layoutsInfo?.layout_name,
                            sectionDetailsId: data?.section_details_id,
                          })
                        }
                      />
                    </div>
                  ))
                  .concat(
                    section?.columns?.left?.single?.length < minImagesLength ? (
                      Array(
                        minImagesLength -
                          (section?.columns?.left?.single?.length || 0)
                      )
                        .fill(null)
                        .map((_, index) => (
                          <div
                            key={Math.floor(Math.random() * 100000)}
                            className="position-relative pv-globle-imgedit-btn"
                          >
                            <Image
                              style={{ objectFit: "cover" }}
                              onContextMenu={() => {
                                deleteThumbline(section?.section_id);
                              }}
                              onClick={() => {
                                setLayoutDetails({
                                  show: !layoutDetails.show,
                                  layoutTypeName: layoutsInfo?.layout_type_name,
                                  sectionId: section?.section_id,
                                  minImagesLength,
                                  maxImagesLength,
                                  layoutName: layoutsInfo?.layout_name,
                                });
                              }}
                              className={`w-100 h-100 thumbnail ${
                                withPadding ? "p-2" : ""
                              }`}
                              src="https://placehold.jp/210x210.png"
                              alt={"Add Image"}
                              fluid
                            />
                            <ImgEditComponet
                              sectionEdit={() =>
                                setLayoutDetails({
                                  show: !layoutDetails.show,
                                  layoutTypeName: layoutsInfo?.layout_type_name,
                                  sectionId: section?.section_id,
                                  minImagesLength,
                                  maxImagesLength,
                                  layoutName: layoutsInfo?.layout_name,
                                })
                              }
                              showDeleteIcon={false}
                            />
                          </div>
                        ))
                    ) : section?.columns?.left?.single?.length <
                      maxImagesLength ? (
                      <div
                        key={Math.floor(Math.random() * 100000)}
                        className="position-relative pv-globle-imgedit-btn"
                      >
                        <Image
                          style={{ objectFit: "cover" }}
                          onContextMenu={() => {
                            deleteThumbline(section?.section_id);
                          }}
                          onClick={() => {
                            setLayoutDetails({
                              show: !layoutDetails.show,
                              layoutTypeName: layoutsInfo?.layout_type_name,
                              sectionId: section?.section_id,
                              minImagesLength,
                              maxImagesLength,
                              layoutName: layoutsInfo?.layout_name,
                            });
                          }}
                          className={`w-100 h-100 thumbnail ${
                            withPadding ? "p-2" : ""
                          }`}
                          src="https://placehold.jp/210x210.png"
                          alt={"Add Image"}
                          fluid
                        />
                        <ImgEditComponet
                          showDeleteIcon={false}
                          sectionEdit={() =>
                            setLayoutDetails({
                              show: !layoutDetails.show,
                              layoutTypeName: layoutsInfo?.layout_type_name,
                              sectionId: section?.section_id,
                              minImagesLength,
                              maxImagesLength,
                              layoutName: layoutsInfo?.layout_name,
                            })
                          }
                        />
                      </div>
                    ) : null
                  )}
              </div>
            </DynamicPositionComponent>
          </ComponentEdit>
        ) : (
          <ComponentEdit
            sectionDelete={sectionDelete}
            sectionEdit={sectionEdit}
            sectionStatus={section?.status}
          >
            <DynamicPositionComponent
              heading={section?.title}
              paragraph={section?.sub_title}
              headingPosition={
                section?.title_position?.toLowerCase() === "left"
                  ? "start"
                  : section?.title_position?.toLowerCase() === "center"
                  ? "center"
                  : "end"
              }
              buttonPosition={
                section?.link_in?.toLowerCase() === "section"
                  ? section?.link_position?.toLowerCase() === "left"
                    ? "start"
                    : section?.link_position?.toLowerCase() === "center"
                    ? "center"
                    : "end"
                  : section?.title_position?.toLowerCase() === "left"
                  ? "end"
                  : section?.title_position?.toLowerCase() === "right"
                  ? "start"
                  : "center"
              }
              buttonPositionDirection={section?.link_in?.toLowerCase()}
              link_text={section?.link_text}
              link={section?.link}
              textColor={section?.text_color}
              titleColor={section?.title_color}
              backgroundColor={section?.background_color}
            >
              <div className="d-flex justify-content-between">
                {Array.from({ length: minImagesLength }, (_, index) => (
                  <div
                    className="d-flex justify-content-between position-relative pv-globle-imgedit-btn"
                    key={Math.floor(Math.random() * 100000)}
                  >
                    <Image
                      style={{ objectFit: "cover" }}
                      className={`w-100 h-100 thumbnail ${
                        withPadding ? "p-2" : ""
                      }`}
                      src="https://placehold.jp/210x210.png"
                      alt={"Add Image"}
                      fluid
                    />
                    <ImgEditComponet
                      showDeleteIcon={false}
                      sectionEdit={() =>
                        setLayoutDetails({
                          show: !layoutDetails.show,
                          layoutTypeName: layoutsInfo?.layout_type_name,
                          sectionId: section?.section_id,
                          minImagesLength,
                          maxImagesLength,
                          layoutName: layoutsInfo?.layout_name,
                        })
                      }
                    />
                  </div>
                ))}
              </div>
            </DynamicPositionComponent>
          </ComponentEdit>
        )}
      </Col>
    </Row>
  );
};

export default HomePageThumbnail;
