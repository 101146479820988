import React, { useCallback, useRef, useState } from 'react'
import Loader from '../components/Loader'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { setPageTitle } from './redux/slice/pageTitleSlice'
import { Col, Row, Table } from 'react-bootstrap'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import Dropdown from 'react-bootstrap/Dropdown'
import {
  PieChart,
  Pie,
  Legend,
  Cell,
  ResponsiveContainer,
  Label,
  Tooltip,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  LineChart,
  Line,
  AreaChart,
  Area,
  ComposedChart,
  Sector,
  RadialBarChart,
  RadialBar
} from 'recharts'
import { callApi } from '../lib/GetBaseUrl'
import HomePageImageGrid from '../components/ManageHomePage/HomePageImageGrid'
import { Form, Formik } from 'formik'
import { useImmer } from 'use-immer'
import HeadingComponent from '../components/ManageHomePage/HeadingComponent'

const Dashboard = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [allState, setAllState] = useImmer({
    orders: { loading: false, data: {} },
    seller: {
      loading: false,
      data: {}
    },
    sellerData: [],
    kyc: {
      loading: false,
      data: {}
    },
    kycData: [],
    brand: {
      loading: false,
      data: {}
    },
    brandData: [],
    product: {
      loading: false,
      data: {}
    },
    productData: [],
    productChart: {
      loading: false,
      data: {}
    },
    productChartData: []
  })
  useEffect(() => {
    dispatch(setPageTitle('Dashboard'))
  }, [])

  const Bullet = ({ backgroundColor, size }) => {
    return (
      <div
        className='CirecleBullet'
        style={{
          backgroundColor,
          width: size,
          height: size
        }}
      ></div>
    )
  }

  const CustomizedLegend = (props) => {
    const { payload } = props
    return (
      <ul className='LegendList'>
        {payload?.map((entry, index) => (
          <li key={`item-${index}`}>
            <div className='BulletLabel'>
              <Bullet backgroundColor={entry.payload.fill} size='15px' />
              <div className='BulletLabelText'>{entry.value}</div>
            </div>
            <div style={{ marginLeft: '15px', fontWeight: 'bold' }}>
              {entry.payload.value}
            </div>
          </li>
        ))}
      </ul>
    )
  }

  const CustomLabel = ({ viewBox, labelText, value }) => {
    const { cx, cy } = viewBox
    return (
      <g className='pv-piechart-lable d-flex'>
        <text
          x={cx}
          y={cy - 10}
          className='recharts-text recharts-label'
          textAnchor='middle'
          dominantBaseline='central'
          alignmentBaseline='middle'
          fontSize='20'
          fontWeight='500'
        >
          {labelText}
        </text>
        <text
          x={cx}
          y={cy + 20}
          className='recharts-text recharts-label'
          textAnchor='middle'
          dominantBaseline='central'
          alignmentBaseline='middle'
          fontSize='20'
          fontWeight='600'
        >
          {value}
        </text>
      </g>
    )
  }

  // const orderdata = [
  //   {
  //     name: 'Page A',
  //     uv: '3.5'
  //   },
  //   {
  //     name: 'Page B',
  //     uv: '2.5'
  //   },
  //   {
  //     name: 'Page C',
  //     uv: 3
  //   },
  //   {
  //     name: 'Page D',
  //     uv: '6.7'
  //   },
  //   {
  //     name: 'Page E',
  //     uv: 5
  //   },
  //   {
  //     name: 'Page F',
  //     uv: 4
  //   },
  //   {
  //     name: 'Page G',
  //     uv: 10
  //   }
  // ]

  // const allordersdata = [
  //   { name: 'Pending', value: 242, color: '#205d92' },
  //   { name: 'Total Delivered', value: 95, color: '#3aa1d7' },
  //   { name: 'Confirmed', value: 28, color: '#a368ed' },
  //   { name: 'Cancelled', value: 8, color: '#41d1bc' },
  //   { name: 'Packed', value: 38, color: '#2c799e' },
  //   { name: 'Replaced', value: 19, color: '#6c1aac' },
  //   { name: 'Shipped', value: 24, color: '#46dfaf' },
  //   { name: 'Total', value: 60, color: '#c3cdeb' }
  // ]

  const evenGradient = ['#47dfb0', '#b0fbe3']
  const oddGradient = ['#2c799f', '#60afd5']

  const CustomBar = ({ fill, x, y, width, height, index }) => {
    const gradient = index % 2 === 0 ? evenGradient : oddGradient
    return (
      <g>
        <defs>
          <linearGradient id={`gradient-${index}`} x1='0' y1='0' x2='0' y2='1'>
            <stop offset='0%' stopColor={gradient[0]} />
            <stop offset='100%' stopColor={gradient[1]} />
          </linearGradient>
        </defs>
        <rect
          x={x}
          y={y}
          width={width}
          height={height}
          fill={`url(#gradient-${index})`}
        />
      </g>
    )
  }

  const fetchAllGenericData = async (apiUrls) => {
    try {
      const responseArray = await Promise.all(
        apiUrls.map((url) => callApi(url.endpoint, url.queryString, url.state))
      )

      return responseArray
    } catch (error) {}
  }

  const getSellerColor = (key) => {
    let color = ''
    switch (key) {
      case 'active':
        color = '#641d9f'
        break
      case 'inactive':
        color = '#eee1ff'
        break
      case 'archive':
        color = '#a76df2'
        break
      default:
        break
    }
    return color
  }

  const getKycColor = (key) => {
    let color = ''
    switch (key) {
      case 'completed':
        color = '#d6fffd'
        break
      case 'notApproved':
        color = '#35afaa'
        break
      case 'pending':
        color = '#42e1da'
        break
      default:
        break
    }
    return color
  }

  const getBrandColor = (key) => {
    let color = ''
    switch (key) {
      case 'active':
        color = '#d6fffd'
        break
      case 'inRequest':
        color = '#35afaa'
        break
      case 'inactive':
        color = '#42e1da'
        break
      default:
        break
    }
    return color
  }

  const getProductColor = (key) => {
    let color = ''
    switch (key) {
      case 'active':
        color = '#46dfaf'
        break
      case 'inBulkUpload':
        color = '#6c1aac'
        break
      case 'inExisting':
        color = '#2c799e'
        break
      case 'inRequest':
        color = '#41d1bc'
        break
      case 'inactivate':
        color = '#a368ed'
        break
      case 'totalActiveStocks':
        color = '#3aa1d7'
        break
      case 'totalStocks':
        color = '#205d92'
        break
      case 'unique':
        color = '#c3cdeb'
        break
      default:
        break
    }
    return color
  }

  const replaceString = (string) => {
    return string
      ?.replace(/([A-Z])/g, ' $1')
      .split(/(?=[A-Z])/)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  }

  const fetchDataFromApis = async (endpoint, queryString, setterFunc) => {
    const response = await callApi(endpoint, queryString)
    return setterFunc(response)
  }

  useEffect(() => {
    setAllState((draft) => {
      draft.seller = { loading: true, data: {} }
    })
    fetchDataFromApis('Dashboard/getSellerCounts', '?days=All', (seller) => {
      let sellerData = Object.entries(seller).reduce((arr, [key, value]) => {
        if (key !== 'total') {
          arr.push({
            name: replaceString(key),
            value,
            color: getSellerColor(key)
          })
        }
        return arr
      }, [])
      setAllState((draft) => {
        draft.seller = { loading: false, data: seller }
        draft.sellerData = sellerData
      })
    })
  }, [])

  // useEffect(() => {
  //   setAllState((draft) => {
  //     draft.kyc = { loading: true, data: {} }
  //   })
  //   fetchDataFromApis('Dashboard/getKycCounts', '?days=All', (kyc) => {
  //     let kycData = Object.entries(kyc).reduce((arr, [key, value]) => {
  //       if (key !== 'total') {
  //         arr.push({
  //           name: replaceString(key),
  //           value,
  //           color: getKycColor(key)
  //         })
  //       }
  //       return arr
  //     }, [])
  //     setAllState((draft) => {
  //       draft.kyc = { loading: false, data: kyc }
  //       draft.kycData = kycData
  //     })
  //   })
  // }, [])

  useEffect(() => {
    setAllState((draft) => {
      draft.brand = { loading: true, data: {} }
    })
    fetchDataFromApis('Dashboard/getBrandCounts', '?days=All', (brand) => {
      let brandData = Object.entries(brand).reduce((arr, [key, value]) => {
        if (key !== 'total') {
          arr.push({
            name: replaceString(key),
            value,
            color: getBrandColor(key)
          })
        }
        return arr
      }, [])
      setAllState((draft) => {
        draft.brand = { loading: false, data: brand }
        draft.brandData = brandData
      })
    })
  }, [])

  useEffect(() => {
    setAllState((draft) => {
      draft.product = { loading: true, data: {} }
    })
    fetchDataFromApis('Dashboard/getProductCounts', '?days=30', (product) => {
      let productData = Object.entries(product).reduce((arr, [key, value]) => {
        if (key !== 'total') {
          arr.push({
            name: replaceString(key),
            value,
            color: getProductColor(key)
          })
        }
        return arr
      }, [])
      setAllState((draft) => {
        draft.product = { loading: false, data: product }
        draft.productData = productData
      })
    })
  }, [])

  useEffect(() => {
    setAllState((draft) => {
      draft.productChart = { loading: true, data: [] }
    })
    fetchDataFromApis(
      'Dashboard/getProductChart',
      '?ReportDays=30',
      (productChart) => {
        let productChartData = productChart?.map((data) => {
          return {
            name: data?.monthDetails,
            uv: data?.monthStats
          }
        })
        setAllState((draft) => {
          draft.productChart = { loading: false, data: productChart }
          draft.productChartData = productChartData
        })
      }
    )
  }, [])

  const toPercent = (decimal, fixed = 0) => `${(decimal * 100).toFixed(fixed)}%`

  const getPercent = (value, total) => {
    const ratio = total > 0 ? value / total : 0

    return toPercent(ratio, 2)
  }

  const renderTooltipContent = (o) => {
    const { payload, label } = o
    const total = payload?.reduce((result, entry) => result + entry.value, 0)

    return (
      <div className='customized-tooltip-content'>
        <p className='total'>{`${label} (Total Products: ${total})`}</p>
        <ul className='list'>
          {payload?.map((entry, index) => (
            <li key={`item-${index}`} style={{ color: entry.color }}>
              {`${entry.name}: ${entry.value}(${getPercent(
                entry.value,
                total
              )})`}
            </li>
          ))}
        </ul>
      </div>
    )
  }

  const fetchFilterData = async (apiUrls, state) => {
    setAllState((draft) => {
      draft[state] = { loading: true, data: draft[state]?.data }
    })
    const responses = await fetchAllGenericData(apiUrls)
    setLoading(false)

    responses.forEach((response) => {
      switch (response.state) {
        case 'seller':
          let seller = response.data

          let sellerData = Object.entries(seller).reduce(
            (arr, [key, value]) => {
              if (key !== 'total') {
                arr.push({
                  name: replaceString(key),
                  value,
                  color: getSellerColor(key)
                })
              }
              return arr
            },
            []
          )
          setAllState((draft) => {
            draft.seller = { loading: false, data: seller }
            draft.sellerData = sellerData
          })
          break

        case 'kyc':
          let kyc = response?.data
          let kycData = Object.entries(kyc).reduce((arr, [key, value]) => {
            if (key !== 'total') {
              arr.push({
                name: replaceString(key),
                value,
                color: getKycColor(key)
              })
            }
            return arr
          }, [])
          setAllState((draft) => {
            draft.kyc = { loading: false, data: kyc }
            draft.kycData = kycData
          })
          break

        case 'brand':
          let brand = response?.data
          let brandData = Object.entries(brand).reduce((arr, [key, value]) => {
            if (key !== 'total') {
              arr.push({
                name: replaceString(key),
                value,
                color: getBrandColor(key)
              })
            }
            return arr
          }, [])

          setAllState((draft) => {
            draft.brand = { loading: false, data: brand }
            draft.brandData = brandData
          })

          break

        case 'product':
          let product = response?.data
          let productData = Object.entries(product).reduce(
            (arr, [key, value]) => {
              if (key !== 'total') {
                arr.push({
                  name: replaceString(key),
                  value,
                  color: getProductColor(key)
                })
              }
              return arr
            },
            []
          )

          setAllState((draft) => {
            draft.product = { loading: false, data: product }
            draft.productData = productData
          })

          break

        case 'productChart':
          let productChart = response?.data
          let productChartData = productChart?.map((data) => {
            return {
              ...data,
              name: data?.monthDetails,
              // uv: data?.monthStats,
              uv: data?.monthStats
            }
          })

          setAllState((draft) => {
            draft.productChart = { loading: false, data: productChart }
            draft.productChartData = productChartData
          })

          break

        default:
          break
      }
    })
  }

  return (
    <>
      <Formik
        initialValues={{
          seller: 'All',
          kyc: 'All',
          brands: 'All',
          product: 'This Month',
          orders: 'Today'
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <div className='pv-dashbord-main gy-4'>
              {allState?.seller?.loading ? (
                <div className='shimmer-main'>
                  <article>
                    <div className='line first'></div>
                    <div className='line second'></div>
                    <div className='line'></div>
                    <div className='shimmer'></div>
                  </article>
                </div>
              ) : (
                <div
                  style={{ boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }}
                  className='pv-dashbord-chart-col pv-PieChart-main rounded h-100'
                >
                  <Tabs
                    defaultActiveKey='Sellers'
                    id='justify-tab-example'
                    justify
                  >
                    <Tab eventKey='Sellers' title='Sellers'>
                      <ResponsiveContainer
                        className='pv-piechart-main'
                        width='100%'
                        height={200}
                      >
                        <PieChart>
                          <Tooltip />
                          <Pie
                            data={allState?.sellerData}
                            dataKey='value'
                            cx={'70%'}
                            cy={90}
                            innerRadius={60}
                            outerRadius={100}
                          >
                            {allState?.sellerData?.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={entry.color} />
                            ))}
                            <Label
                              content={
                                <CustomLabel
                                  labelText={'Total'}
                                  value={allState?.seller?.data?.total}
                                />
                              }
                              position='center'
                            />
                          </Pie>
                          <Legend
                            layout='vertical'
                            verticalAlign='middle'
                            align='left'
                            content={<CustomizedLegend />}
                          />
                        </PieChart>
                      </ResponsiveContainer>
                    </Tab>
                    {/* <Tab eventKey='Kyc' title='Kyc'>
                    <ResponsiveContainer
                      className='pv-piechart-main'
                      width='100%'
                      height={140}
                    >
                      <PieChart>
                        <Tooltip />
                        <Pie
                          data={allState?.kycData}
                          dataKey='value'
                          cx={'40%'}
                          cy={60}
                          innerRadius={60}
                          outerRadius={100}
                          fill='red'
                        >
                          {allState?.kycData?.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.color} />
                          ))}
                          <Label
                            content={
                              <CustomLabel
                                labelText='Total'
                                value={allState?.kyc?.data?.total}
                              />
                            }
                            position='center'
                          />
                        </Pie>
                        <Legend
                          layout='vertical'
                          verticalAlign='middle'
                          align='left'
                          content={<CustomizedLegend />}
                        />
                      </PieChart>
                    </ResponsiveContainer>
                  </Tab> */}
                  </Tabs>
                  <Dropdown className='pv-chartdash-dropbtn'>
                    <Dropdown.Toggle className='btn-light' id='dropdown-basic'>
                      {values?.seller ? values?.seller : 'All'}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          fetchFilterData(
                            [
                              {
                                endpoint: 'Dashboard/getSellerCounts',
                                queryString: '?days=All',
                                state: 'seller'
                              }
                              // {
                              //   endpoint: 'Dashboard/getKycCounts',
                              //   queryString: '?days=All',
                              //   state: 'kyc'
                              // }
                            ],
                            'seller'
                          )
                          setFieldValue('seller', 'All')
                        }}
                      >
                        All
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={async () => {
                          fetchFilterData(
                            [
                              {
                                endpoint: 'Dashboard/getSellerCounts',
                                queryString: '?days=Today',
                                state: 'seller'
                              }
                              // {
                              //   endpoint: 'Dashboard/getKycCounts',
                              //   queryString: '?days=Today',
                              //   state: 'kyc'
                              // }
                            ],
                            'seller'
                          )
                          setFieldValue('seller', 'Today')
                        }}
                      >
                        Today
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          fetchFilterData(
                            [
                              {
                                endpoint: 'Dashboard/getSellerCounts',
                                queryString: '?days=7',
                                state: 'seller'
                              }
                              // {
                              //   endpoint: 'Dashboard/getKycCounts',
                              //   queryString: '?days=7',
                              //   state: 'kyc'
                              // }
                            ],
                            'seller'
                          )
                          setFieldValue('seller', 'Last Week')
                        }}
                      >
                        Last Week
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          fetchFilterData(
                            [
                              {
                                endpoint: 'Dashboard/getSellerCounts',
                                queryString: '?days=15',
                                state: 'seller'
                              }
                              // {
                              //   endpoint: 'Dashboard/getKycCounts',
                              //   queryString: '?days=15',
                              //   state: 'kyc'
                              // }
                            ],
                            'seller'
                          )
                          setFieldValue('seller', 'Last 15 Days')
                        }}
                      >
                        Last 15 Days
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          fetchFilterData(
                            [
                              {
                                endpoint: 'Dashboard/getSellerCounts',
                                queryString: '?days=30',
                                state: 'seller'
                              }
                              // {
                              //   endpoint: 'Dashboard/getKycCounts',
                              //   queryString: '?days=30',
                              //   state: 'kyc'
                              // }
                            ],
                            'seller'
                          )
                          setFieldValue('seller', 'This Month')
                        }}
                      >
                        This Month
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          fetchFilterData(
                            [
                              {
                                endpoint: 'Dashboard/getSellerCounts',
                                queryString: '?days=90',
                                state: 'seller'
                              }
                              // {
                              //   endpoint: 'Dashboard/getKycCounts',
                              //   queryString: '?days=90',
                              //   state: 'kyc'
                              // }
                            ],
                            'seller'
                          )
                          setFieldValue('seller', 'Last 3 Months')
                        }}
                      >
                        Last 3 Months
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )}

              {/* {allState?.brand?.loading ? (
                <div className='shimmer-main'>
                  <article>
                    <div className='line first'></div>
                    <div className='line second'></div>
                    <div className='line'></div>
                    <div className='shimmer'></div>
                  </article>
                </div>
              ) : (
                <div
                  style={{ boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }}
                  className='pv-dashbord-chart-col pv-PieChart-main rounded h-100'
                >
                  <Tabs
                    defaultActiveKey='Brands'
                    id='justify-tab-example'
                    justify
                  >
                    <Tab className='border-0' eventKey='Brands' title='Brands'>
                      <ResponsiveContainer
                        className='pv-piechart-main'
                        width='100%'
                        height={200}
                      >
                        <PieChart>
                          <Pie
                            data={allState?.brandData}
                            dataKey='value'
                            cx={'70%'}
                            cy={90}
                            innerRadius={60}
                            outerRadius={100}
                          >
                            {allState?.brandData?.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={entry.color} />
                            ))}
                            <Label
                              content={
                                <CustomLabel
                                  labelText='Total :'
                                  value={allState?.brand?.data?.total}
                                />
                              }
                              position='center'
                            />
                          </Pie>
                          <Tooltip />
                          <Legend
                            layout='vertical'
                            verticalAlign='middle'
                            align='left'
                            content={<CustomizedLegend />}
                          />
                        </PieChart>
                      </ResponsiveContainer>
                    </Tab>
                  </Tabs>
                  <Dropdown className='pv-chartdash-dropbtn'>
                    <Dropdown.Toggle className='btn-light' id='dropdown-basic'>
                      {values?.brand ? values?.brand : 'All'}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          fetchFilterData(
                            [
                              {
                                endpoint: 'Dashboard/getBrandCounts',
                                queryString: '?days=All',
                                state: 'brand'
                              }
                            ],
                            'brand'
                          )
                          setFieldValue('brand', 'All')
                        }}
                      >
                        All
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          fetchFilterData(
                            [
                              {
                                endpoint: 'Dashboard/getBrandCounts',
                                queryString: '?days=Today',
                                state: 'brand'
                              }
                            ],
                            'brand'
                          )
                          setFieldValue('brand', 'Today')
                        }}
                      >
                        Today
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          fetchFilterData(
                            [
                              {
                                endpoint: 'Dashboard/getBrandCounts',
                                queryString: '?days=7',
                                state: 'brand'
                              }
                            ],
                            'brand'
                          )
                          setFieldValue('brand', 'Last Week')
                        }}
                      >
                        Last Week
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          fetchFilterData(
                            [
                              {
                                endpoint: 'Dashboard/getBrandCounts',
                                queryString: '?days=15',
                                state: 'brand'
                              }
                            ],
                            'brand'
                          )
                          setFieldValue('brand', 'Last 15 Days')
                        }}
                      >
                        Last 15 Days
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          fetchFilterData(
                            [
                              {
                                endpoint: 'Dashboard/getBrandCounts',
                                queryString: '?days=30',
                                state: 'brand'
                              }
                            ],
                            'brand'
                          )
                          setFieldValue('brand', 'This Month')
                        }}
                      >
                        This Month
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          fetchFilterData(
                            [
                              {
                                endpoint: 'Dashboard/getBrandCounts',
                                queryString: '?days=90',
                                state: 'brand'
                              }
                            ],
                            'brand'
                          )
                          setFieldValue('brand', 'Last 3 Month')
                        }}
                      >
                        Last 3 Month
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )} */}

              {allState?.product?.loading ? (
                <div className='shimmer-main'>
                  <article>
                    <div className='line first'></div>
                    <div className='line second'></div>
                    <div className='line'></div>
                    <div className='shimmer'></div>
                  </article>
                </div>
              ) : (
                <div
                  style={{ boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }}
                  className='pv-dashbord-chart-col pv-rounded-chart rounded h-100'
                >
                  <Tabs
                    defaultActiveKey='profile'
                    id='justify-tab-example'
                    className='mb-3'
                    justify
                  >
                    <Tab
                      className='border-0 '
                      eventKey='profile'
                      title='Products'
                    >
                      <Row className='w-100 m-auto pv-PercentArea-desc'>
                        <Col className='border text-center p-2' md={3}>
                          <div>
                            <h6 className='medium mb-1'>Total Products</h6>
                          </div>
                          <div>
                            <h5 className='mb-0 bold'>
                              {allState?.product?.data?.total ?? 0}
                            </h5>
                          </div>
                        </Col>
                        <Col className='border text-center p-1' md={3}>
                          <div>
                            <h6 className='medium mb-1'>Active Products</h6>
                          </div>
                          <div>
                            <h5 className='mb-0 bold'>
                              {allState?.product?.data?.active ?? 0}
                            </h5>
                          </div>
                        </Col>
                        <Col className='border text-center p-1' md={3}>
                          <div>
                            <h6 className='medium mb-1'>InActive Products</h6>
                          </div>
                          <div>
                            <h5 className='mb-0 bold'>
                              {allState?.product?.data?.inactivate ?? 0}
                            </h5>
                          </div>
                        </Col>
                        <Col className='border text-center p-1' md={3}>
                          <div>
                            <h6 className='medium mb-1'>
                              {' '}
                              Bulk Upload & In Approval
                            </h6>
                          </div>
                          <div>
                            <h5 className='mb-0 bold'>
                              {allState?.product?.data?.inBulkUpload ?? 0}
                            </h5>
                          </div>
                        </Col>
                      </Row>
                      <ResponsiveContainer width='100%' height={350}>
                        <AreaChart
                          data={allState?.productChartData}
                          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                        >
                          <defs>
                            <linearGradient
                              id='colorUv'
                              x1='0'
                              y1='0'
                              x2='0'
                              y2='1'
                            >
                              <stop
                                offset='5%'
                                stopColor='#8884d8'
                                stopOpacity={0.8}
                              />
                              <stop
                                offset='95%'
                                stopColor='#8884d8'
                                stopOpacity={0}
                              />
                            </linearGradient>
                            <linearGradient
                              id='colorPv'
                              x1='0'
                              y1='0'
                              x2='0'
                              y2='1'
                            >
                              <stop
                                offset='5%'
                                stopColor='#82ca9d'
                                stopOpacity={0.8}
                              />
                              <stop
                                offset='95%'
                                stopColor='#82ca9d'
                                stopOpacity={0}
                              />
                            </linearGradient>
                          </defs>
                          <XAxis dataKey='name' />
                          <YAxis />
                          <CartesianGrid strokeDasharray='2 2' />
                          <Tooltip content={renderTooltipContent} />
                          <Area
                            type='monotone'
                            dataKey='uv'
                            stroke='#8884d8'
                            fillOpacity={1}
                            fill='url(#colorUv)'
                          />
                          <Area
                            type='monotone'
                            dataKey='pv'
                            stroke='#82ca9d'
                            fillOpacity={1}
                            fill='url(#colorPv)'
                          />
                        </AreaChart>
                      </ResponsiveContainer>
                      {/* <Row className='w-100 m-auto pv-PercentArea-desc'>

                        <Col className='border text-center p-1' md={4}>
                          <h5 className='medium mb-1'>Total Stocks</h5>
                          <h5 className='mb-0 bold'>
                            {allState?.product?.data?.totalStocks ?? 0}
                          </h5>
                        </Col>
                        <Col className='border text-center p-1' md={4}>
                          <h5 className='medium mb-1'>Total Active Stocks</h5>
                          <h5 className='mb-0 bold'>
                            {allState?.product?.data?.totalActiveStocks ?? 0}
                          </h5>
                        </Col>
                      </Row> */}
                    </Tab>
                  </Tabs>
                  <Dropdown className='pv-chartdash-dropbtn'>
                    <Dropdown.Toggle className='btn-light' id='dropdown-basic'>
                      {values?.product ? values?.product : 'All'}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          fetchFilterData(
                            [
                              {
                                endpoint: 'Dashboard/getProductCounts',
                                queryString: '?days=Today',
                                state: 'product'
                              },
                              {
                                endpoint: 'Dashboard/getProductChart',
                                queryString: '?ReportDays=1',
                                state: 'productChart'
                              }
                            ],
                            'product'
                          )
                          setFieldValue('product', 'Today')
                        }}
                      >
                        Today
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          fetchFilterData(
                            [
                              {
                                endpoint: 'Dashboard/getProductCounts',
                                queryString: '?days=7',
                                state: 'product'
                              },
                              {
                                endpoint: 'Dashboard/getProductChart',
                                queryString: '?ReportDays=7',
                                state: 'productChart'
                              }
                            ],
                            'product'
                          )
                          setFieldValue('product', 'Last Week')
                        }}
                      >
                        Last Week
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          fetchFilterData(
                            [
                              {
                                endpoint: 'Dashboard/getProductCounts',
                                queryString: '?days=15',
                                state: 'product'
                              },
                              {
                                endpoint: 'Dashboard/getProductChart',
                                queryString: '?ReportDays=15',
                                state: 'productChart'
                              }
                            ],
                            'product'
                          )
                          setFieldValue('product', 'Last 15 Days')
                        }}
                      >
                        Last 15 Days
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          fetchFilterData(
                            [
                              {
                                endpoint: 'Dashboard/getProductCounts',
                                queryString: '?days=30',
                                state: 'product'
                              },
                              {
                                endpoint: 'Dashboard/getProductChart',
                                queryString: '?ReportDays=30',
                                state: 'productChart'
                              }
                            ],
                            'product'
                          )
                          setFieldValue('product', 'This Month')
                        }}
                      >
                        This Month
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          fetchFilterData(
                            [
                              {
                                endpoint: 'Dashboard/getProductCounts',
                                queryString: '?days=90',
                                state: 'product'
                              },
                              {
                                endpoint: 'Dashboard/getProductChart',
                                queryString: '?ReportDays=90',
                                state: 'productChart'
                              }
                            ],
                            'product'
                          )
                          setFieldValue('product', 'Last 3 Months')
                        }}
                      >
                        Last 3 Months
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          fetchFilterData(
                            [
                              {
                                endpoint: 'Dashboard/getProductCounts',
                                queryString: '?days=All',
                                state: 'product'
                              },
                              {
                                endpoint: 'Dashboard/getProductChart',
                                queryString: '?ReportDays=365',
                                state: 'productChart'
                              }
                            ],
                            'product'
                          )
                          setFieldValue('product', 'Last 1 year')
                        }}
                      >
                        Last 1 year
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default Dashboard
