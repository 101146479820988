import { createSlice } from '@reduxjs/toolkit'
import { getUserToken } from '../../../lib/GetBaseUrl'
import { getUserDetails, registerUser, userLogin } from '../actions/userActions'

// initialize userToken from local storage
const userToken = getUserToken()

const initialState = {
  loading: false,
  userInfo: null,
  pageAccess: null,
  userToken,
  error: null,
  success: false,
  sellerDetails: {}
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.removeItem('userToken') // delete token from storage
      localStorage.removeItem('refreshToken') // delete token from storage
      localStorage.removeItem('deviceId') // delete token from storage
      state.loading = false
      state.userInfo = null
      state.userToken = null
      state.error = null
    },
    setSellerDetails: (state, action) => {
      state.sellerDetails = action.payload
    },
    setUserInfo: (state, action) => {
      state.userInfo = action.payload.currentUser
      state.pageAccess = action.payload.pageAccess
      state.userToken = action.payload.tokens.accessToken
    },
    updateUserDetails: (state, action) => {
      state.userInfo = action?.payload
    }
  },
  extraReducers: {
    // login user
    [userLogin.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [userLogin.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.userInfo = payload
      state.userToken = payload.tokens.accessToken
    },
    [userLogin.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    // register user
    [registerUser.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [registerUser.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.success = true // registration successful
    },
    [registerUser.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    // get user details
    [getUserDetails.pending]: (state) => {
      state.loading = true
    },
    [getUserDetails.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.userInfo = payload
    },
    [getUserDetails.rejected]: (state, { payload }) => {
      state.loading = false
    }
  }
})

export const { logout, setSellerDetails, setUserInfo, updateUserDetails } = userSlice.actions

export default userSlice.reducer
