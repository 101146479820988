import { ErrorMessage, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Button, Table } from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import { customStyles } from '../../../components/customStyles'
import FormikControl from '../../../components/FormikControl'
import ModelComponent from '../../../components/Modal'
import SearchBox from '../../../components/Searchbox'
import TextError from '../../../components/TextError'
import * as Yup from 'yup'
import Select from 'react-select'
import axiosProvider from '../../../lib/AxiosProvider'
import CustomToast from '../../../components/Toast/CustomToast'
import Loader from '../../../components/Loader'
import { setPageTitle } from '../../redux/slice/pageTitleSlice'
import { useDispatch, useSelector } from 'react-redux'
import { _SwalDelete } from '../../../lib/exceptionMessage'
import EditIcon from '../../../components/AllSvgIcon/EditIcon'
import { _userProfileImg_ } from '../../../lib/ImagePath'
import {
  _alphabetRegex_,
  _emailRegex_,
  _passwordRegex_,
  _phoneNumberRegex_
} from '../../../lib/Regex'
import { _status_, pageRangeDisplayed } from '../../../lib/GetBaseUrl'
import useDebounce from '../../../lib/useDebounce'
import {
  allCrudNames,
  allPages,
  checkPageAccess
} from '../../../lib/AllPageNames'
import { useLocation } from 'react-router-dom'
import { useImmer } from 'use-immer'
import HKBadge from '../../../components/Badges'

const MainAdmin = () => {
  const [initialdata, setInnitialData] = useState(true)
  const [modalShow, setModalShow] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [data, setData] = useState()
  const [dropDownData, setDropDownData] = useState()
  const [editData, setEditData] = useState()
  const [loading, setLoading] = useState(false)
  const [toast, setToast] = useState({
    show: false,
    text: null,
    variation: null
  })

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setPageTitle('Manage Admin'))
  }, [])

  const { userId } = useSelector((state) => state?.user?.userInfo)
  const location = useLocation()
  const { pageAccess } = useSelector((state) => state.user)
  const [filterDetails, setFilterDetails] = useImmer({
    pageSize: 10,
    pageIndex: 1,
    searchText: ''
  })
  const debounceSearchText = useDebounce(searchText, 500)

  useEffect(() => {
    if (debounceSearchText) {
      setFilterDetails((draft) => {
        draft.searchText = debounceSearchText
        draft.pageIndex = 1
      })
    } else {
      setFilterDetails((draft) => {
        draft.searchText = ''
        draft.pageIndex = 1
      })
    }
  }, [debounceSearchText])

  const onSubmit = async (values, { resetForm }) => {
    const dataofForm = {
      FirstName: values?.firstName,
      LastName: values?.lastName,
      EmailID: values?.userName,
      MobileNo: values?.mobileNo,
      UserTypeId: values?.userTypeId,
      Password: values?.password,
      FileName: values?.filename,
      ProfileImage: values?.filename?.name
        ? values?.filename?.name
        : values?.filename,
      ReceiveNotifications: values?.receiveNotifications
        ?.map((data) => data?.value)
        ?.join(',')
    }

    const submitFormData = new FormData()

    const keys = Object.keys(dataofForm)

    keys.forEach((key) => {
      submitFormData.append(key, dataofForm[key])
    })

    setLoading(true)
    const response = await axiosProvider({
      method: 'POST',
      endpoint: `Account/Admin/SignUp`,
      data: submitFormData,
      logData: values,
      location: location?.pathname,
      userId
    })
      .then((res) => {
        if (res?.status === 200) {
          resetForm({ values: '' })
          fetchData()
          setModalShow(false)
        }
        setLoading(false)
        setToast({
          show: true,
          text: res?.data?.message,
          variation: res?.data?.code !== 200 ? 'error' : 'success'
        })

        setTimeout(() => {
          setToast({ ...toast, show: false })
        }, 2000)
      })
      .catch((err) => {
        setLoading(false)
      })
  }

  const handleUpdate = async (values, { resetForm }) => {
    const dataofForm = {
      Id: values?.id,
      FirstName: values.firstName,
      LastName: values?.lastName,
      UserName: values.userName,
      MobileNo: values.mobileNo,
      UserTypeId: values.userTypeId,
      UserType: values?.userType,
      FileName: values.filename ? values?.filename : values?.profileImage,
      Status: values?.status,
      OldProfileImage: values?.profileImage,
      ReceiveNotifications: values?.receiveNotifications
        ?.map((data) => data?.value)
        ?.join(',')
    }

    const submitFormData = new FormData()

    const keys = Object.keys(dataofForm)

    keys.forEach((key) => {
      submitFormData.append(key, dataofForm[key])
    })

    setLoading(true)
    const response = await axiosProvider({
      method: 'PUT',
      endpoint: `Account/Admin/Update`,
      data: submitFormData,
      logData: values,
      oldData: editData,
      location: location?.pathname,
      userId
    })
      .then((res) => {
        if (res?.status === 200) {
          resetForm({ values: '' })
          fetchData()
          setModalShow(false)
        }
        setLoading(false)
        setToast({
          show: true,
          text: res?.data?.message,
          variation: res?.data?.code !== 200 ? 'error' : 'success'
        })

        setTimeout(() => {
          setToast({ ...toast, show: false })
        }, 2000)
      })
      .catch((err) => {
        setLoading(false)
        setModalShow(false)
      })
  }

  const fetchDropDownData = async () => {
    const response = await axiosProvider({
      method: 'GET',
      endpoint: 'GetAllRoleTypes',
      queryString: `?pageIndex=0&pageSize=0`
    })
      .then((res) => {
        if (res.status === 200 && res.data.data.length > 0) {
          setDropDownData(res?.data)
        }
      })
      .catch((err) => {})
  }

  let initialValues = {
    firstName: '',
    lastName: '',
    emailID: '',
    mobileNo: '',
    userTypeId: null,
    status: null,
    password: '',
    confirmPassword: '',
    filename: '',
    cpass: '',
    receiveNotifications: [],
    isPasswordVisible: false,
    isConfirmPasswordVisible: false,
    userName: ''
  }

  const initialValuesedit = editData && {
    ...editData,
    receiveNotifications: editData?.receiveNotifications
      ?.split(',')
      ?.map((data) => {
        return { label: data, value: data }
      })
  }

  useEffect(() => {
    fetchData()
  }, [filterDetails])

  const handleEditData = async (id) => {
    setLoading(true)
    const response = await axiosProvider({
      method: 'GET',
      endpoint: 'Account/Admin/ById',
      queryString: `?Id=${id}`
    })
      .then((res) => {
        if (res?.status === 200) {
          setEditData(res.data.data)
          setInnitialData(false)
          fetchDropDownData()
          setModalShow(true)
        }
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
      })
  }

  const fetchData = async () => {
    setLoading(true)
    const response = await axiosProvider({
      method: 'GET',
      endpoint: 'Account/Admin/Search',
      queryString: `?searchText=${filterDetails?.searchText}&pageIndex=${filterDetails?.pageIndex}&pageSize=${filterDetails?.pageSize}`
    })
      .then((res) => {
        setLoading(false)
        if (res.status === 200) {
          setData(res)
        }
      })
      .catch((err) => {
        setLoading(false)
      })
  }

  const handlePageClick = (event) => {
    setFilterDetails((draft) => {
      draft.pageIndex = event.selected + 1
    })
  }

  const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png']

  const validationSchema = Yup.object().shape(
    {
      id: Yup.string(),
      userTypeId: Yup.string()
        .test(
          'nonull',
          'Please select User Type',
          (value) => value !== 'undefined'
        )
        .required('Please select User Type'),
      firstName: Yup.string()
        .required('First Name is Required')
        .matches(_alphabetRegex_, 'Only alphabets allowed'),
      lastName: Yup.string()
        .matches(_alphabetRegex_, 'Only alphabets allowed')
        .required('Last Name is Required'),
      userName: Yup.string()
        .matches(_emailRegex_, 'Please enter a valid email id')
        .required('Email Id is Required'),
      password: Yup.string().when('id', {
        is: (id) => !id,
        then: () =>
          Yup.string()
            .matches(
              _passwordRegex_,
              'Password must be at least 6 characters with a mix of lowercase, uppercase, digit, and special characters.'
            )
            .required('Password is Required'),
        otherwise: () => Yup.string().nullable()
      }),
      mobileNo: Yup.string()
        .required('Mobile Number is Required')
        .matches(
          _phoneNumberRegex_,
          'Mobile Number starting ranges between 6 - 9'
        ),
      cpass: Yup.string().when('id', {
        is: (id) => !id,
        then: () =>
          Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .required('Confirm password required'),
        otherwise: () => Yup.string().nullable()
      }),
      receiveNotifications: Yup.array()
        .min(1, 'Please select atleast one item')
        .required('Please select items'),
      status: Yup.string()
        .test(
          'nonull',
          'Please select Status',
          (value) => value !== 'undefined'
        )
        .required('Please select Status'),
      filename: Yup.mixed().when('filename', {
        is: (value) => value?.name,
        then: (schema) =>
          schema
            .test(
              'fileFormat',
              'File formate is not supported, Please use .jpg/.png/.jpeg format support',
              (value) => value && SUPPORTED_FORMATS.includes(value.type)
            )
            .test('fileSize', 'File must be less than 2MB', (value) => {
              return value !== undefined && value && value.size <= 2000000
            }),
        otherwise: (schema) => schema.notRequired()
      })
    },
    ['filename', 'filename']
  )

  return (
    <>
      {toast?.show && (
        <CustomToast text={toast?.text} variation={toast?.variation} />
      )}
      {loading && !modalShow && <Loader />}

      <div className='d-flex align-items-center mb-3 gap-3 flex-row-reverse'>
        {checkPageAccess(
          pageAccess,
          allPages?.manageAdmin,
          allCrudNames?.write
        ) && (
          <Button
            variant='warning'
            className='d-flex align-items-center gap-2 fw-semibold btn btn-warning ms-auto'
            onClick={() => {
              setInnitialData(true)
              fetchDropDownData()
              setModalShow(!modalShow)
            }}
          >
            <i className='m-icon m-icon--plusblack'></i>
            Create
          </Button>
        )}
        <div className='d-flex align-items-center'>
          <label className='me-1'>Show</label>
          <select
            styles={customStyles}
            menuPortalTarget={document.body}
            name='dataget'
            id='parpageentries'
            className='form-select me-1'
            onChange={(e) => {
              setFilterDetails((draft) => {
                draft.pageSize = e?.target?.value
                draft.pageIndex = 1
              })
            }}
          >
            <option value='10'>10</option>
            <option value='25'>25</option>
            <option value='50'>50</option>
            <option value='100'>100</option>
            <option value='200'>200</option>
            <option value='500'>500</option>
          </select>
        </div>
        <SearchBox
          placeholderText={'Search'}
          searchclassnamewrapper={'searchbox-wrapper'}
          value={searchText}
          onChange={(e) => {
            setSearchText(e?.target?.value)
          }}
        />
      </div>
      <div className='admin_table'>
        <Table className='align-middle table-list mt-3'>
          <thead>
            <tr>
              <th>Image</th>
              <th>Name</th>
              <th>Email</th>
              <th>Mobile</th>
              <th>User type</th>
              <th>Receive Notification</th>
              <th>Status</th>
              {checkPageAccess(
                pageAccess,
                allPages?.manageAdmin,
                allCrudNames?.update
              ) && <th className='text-center'>Action</th>}
            </tr>
          </thead>
          <tbody>
            {data?.data?.data?.length > 0 ? (
              data?.data?.data?.map((data) => (
                <tr key={data?.id}>
                  <td>
                    <img
                      style={{ width: '50px' }}
                      className='rounded-2'
                      src={
                        data?.profileImage
                          ? `${process.env.REACT_APP_IMG_URL}${_userProfileImg_}${data?.profileImage}`
                          : 'https://place-hold.it/50?text=50x50&fontsize=14'
                      }
                      alt='...'
                    />
                  </td>
                  <td>{`${data?.firstName} ${data?.lastName}`}</td>
                  <td>{data?.userName}</td>
                  <td>{data?.mobileNo}</td>
                  <td>{data?.userType}</td>
                  <td>{data?.receiveNotifications}</td>
                  <td>
                    <HKBadge
                      badgesbgname={
                        data?.status === 'Active' ? 'success' : 'danger'
                      }
                      badgesTxtname={data?.status}
                      badgeClassname={''}
                    />
                  </td>
                  {checkPageAccess(
                    pageAccess,
                    allPages?.manageAdmin,
                    allCrudNames?.update
                  ) && (
                    <td className='text-center'>
                      <div className='d-flex gap-2 justify-content-center'>
                        {checkPageAccess(
                          pageAccess,
                          allPages?.manageAdmin,
                          allCrudNames?.update
                        ) && (
                          <span
                            onClick={() => {
                              handleEditData(data.id)
                            }}
                          >
                            <EditIcon bg={'bg'} />
                          </span>
                        )}
                        {/* <span
                        onClick={() => {
                          Swal.fire({
                            title: _SwalDelete.title,
                            text: _SwalDelete.text,
                            icon: _SwalDelete.icon,
                            showCancelButton: _SwalDelete.showCancelButton,
                            confirmButtonColor: _SwalDelete.confirmButtonColor,
                            cancelButtonColor: _SwalDelete.cancelButtonColor,
                            confirmButtonText: _SwalDelete.confirmButtonText,
                            cancelButtonText: _SwalDelete.cancelButtonText
                          }).then((result) => {
                            if (result.isConfirmed) {
                            } else if (result.isDenied) {
                            }
                          })
                        }}
                      >
                        <DeleteIcon bg={'bg'} />
                      </span> */}
                      </div>
                    </td>
                  )}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6} className='text-center'>
                  {data?.data?.message}
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        <ReactPaginate
          className='list-inline m-cst--pagination d-flex justify-content-end gap-1'
          breakLabel='...'
          nextLabel=''
          onPageChange={handlePageClick}
          pageRangeDisplayed={pageRangeDisplayed}
          pageCount={data?.data?.pagination?.pageCount ?? 0}
          previousLabel=''
          renderOnZeroPageCount={null}
          forcePage={filterDetails?.pageIndex - 1}
        />
      </div>

      <ModelComponent
        show={modalShow}
        modalsize={'md'}
        modalheaderclass={''}
        modeltitle={'Manage Admin'}
        onHide={() => setModalShow(!modalShow)}
        btnclosetext={''}
        closebtnvariant={''}
        backdrop={'static'}
        formbuttonid={'main-tax-type'}
        submitname={initialdata === true ? 'Create' : 'Update'}
      >
        <Formik
          initialValues={
            initialdata === true ? initialValues : initialValuesedit
          }
          validationSchema={validationSchema}
          onSubmit={initialdata ? onSubmit : handleUpdate}
        >
          {({ values, setFieldValue }) => (
            <Form id='main-tax-type'>
              {loading && modalShow && <Loader />}
              <div className='row'>
                <div className='col-md-3'>
                  <div className='input-file-wrapper m--cst-filetype mb-3'>
                    <label className='form-label required' htmlFor='image'>
                      Image
                    </label>
                    <input
                      id='filename'
                      className='form-control'
                      name='filename'
                      type='file'
                      accept='image/jpg, image/png, image/jpeg'
                      onChange={(event) => {
                        const objectUrl = URL.createObjectURL(
                          event.target.files[0]
                        )
                        if (event.target.files[0].type !== '') {
                          setFieldValue('profileImage', objectUrl)
                        }
                        setFieldValue('filename', event.target.files[0])
                      }}
                      hidden
                    />
                    {values?.profileImage ? (
                      <div className='position-relative m--img-preview d-flex rounded-2 overflow-hidden'>
                        <img
                          src={
                            values?.profileImage?.includes('blob')
                              ? values?.profileImage
                              : `${process.env.REACT_APP_IMG_URL}${_userProfileImg_}${values?.profileImage}`
                          }
                          alt='Preview'
                          title={
                            values?.profileImage ? values?.filename?.name : ''
                          }
                          className='rounded-2'
                        ></img>
                        <span
                          onClick={(e) => {
                            setFieldValue('profileImage', null)
                            setFieldValue('filename', null)
                            document.getElementById('filename').value = null
                          }}
                        >
                          <i className='m-icon m-icon--close'></i>
                        </span>
                      </div>
                    ) : (
                      <>
                        <label
                          className='m__image_default d-flex align-items-center justify-content-center rounded-2'
                          htmlFor='filename'
                        >
                          <i className='m-icon m-icon--defaultpreview'></i>
                        </label>
                      </>
                    )}
                    <ErrorMessage
                      name='filename'
                      component={TextError}
                      customclass={'cfz-12 lh-sm'}
                    />
                  </div>
                </div>
                <div className='col-md-9'>
                  <div className='row'>
                    <div className='col-md-12'>
                      <FormikControl
                        isRequired
                        control='input'
                        label='First name'
                        type='text'
                        name='firstName'
                        value={values?.firstName}
                        placeholder='Enter first name'
                        onChange={(e) => {
                          const inputValue = e?.target?.value
                          const fieldName = e?.target?.name
                          const isValid = _alphabetRegex_.test(inputValue)
                          if (isValid || !inputValue)
                            setFieldValue([fieldName], e?.target?.value)
                        }}
                      />
                    </div>
                    <div className='col-md-12'>
                      <FormikControl
                        isRequired
                        control='input'
                        label='Last name'
                        type='text'
                        name='lastName'
                        value={values?.lastName}
                        placeholder='Enter last name'
                        onChange={(e) => {
                          const inputValue = e?.target?.value
                          const fieldName = e?.target?.name
                          const isValid = _alphabetRegex_.test(inputValue)
                          if (isValid || !inputValue)
                            setFieldValue([fieldName], e?.target?.value)
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col-md-6'>
                  <FormikControl
                    isRequired
                    control='input'
                    label='Email id'
                    type='email'
                    name='userName'
                    placeholder='Enter email'
                    disabled={values?.id ? true : false}
                    maxLength={50}
                  />
                </div>
                <div className='col-md-6'>
                  <FormikControl
                    isRequired
                    control='input'
                    label='Mobile number'
                    maxLength='10'
                    type='text'
                    disabled={values?.id ? true : false}
                    name='mobileNo'
                    value={values?.mobileNo}
                    placeholder='Enter mobile number'
                    onChange={(event) => {
                      const inputValue = event.target.value
                      const fieldName = event?.target?.name
                      const isValid = _phoneNumberRegex_.test(inputValue)
                      if (isValid || !inputValue) {
                        setFieldValue([fieldName], inputValue)
                      }
                    }}
                  />
                </div>
                {!values?.id && (
                  <>
                    <div className='col-md-6'>
                      <div className='input-wrapper pv-login-password mb-4 pos-relative'>
                        <FormikControl
                          isRequired
                          control='input'
                          label='Password'
                          id='password'
                          name='password'
                          placeholder='Password'
                          type={values.isPasswordVisible ? 'text' : 'password'}
                          maxLength={50}
                        />
                        <div
                          className='eye-absolute'
                          style={{
                            position: 'absolute',
                            top: '1.8rem',
                            right: '10px'
                          }}
                        >
                          {values.isPasswordVisible ? (
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='18'
                              height='18'
                              onClick={() => {
                                setFieldValue(
                                  'isPasswordVisible',
                                  !values.isPasswordVisible
                                )
                              }}
                              viewBox='0 0 24 24'
                              fill='none'
                              stroke='currentColor'
                              strokeWidth='2'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              className={`feather feather-eye link-icon toggle-password eye-icon w-75`}
                              data-password=''
                            >
                              <path d='M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z'></path>
                              <circle cx='12' cy='12' r='3'></circle>
                            </svg>
                          ) : (
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='18'
                              height='18'
                              viewBox='0 0 24 24'
                              fill='none'
                              stroke='currentColor'
                              onClick={() => {
                                setFieldValue(
                                  'isPasswordVisible',
                                  !values.isPasswordVisible
                                )
                              }}
                              strokeWidth='2'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              className='feather feather-eye-off link-icon toggle-password eye-off-icon w-75'
                              data-password=''
                            >
                              <path d='M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24'></path>
                              <line x1='1' y1='1' x2='23' y2='23'></line>
                            </svg>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='input-wrapper pv-login-password mb-4 pos-relative'>
                        <FormikControl
                          isRequired
                          control='input'
                          label='Confirm password'
                          id='cpass'
                          name='cpass'
                          placeholder='Confirm password'
                          type={
                            values?.isConfirmPasswordVisible
                              ? 'text'
                              : 'password'
                          }
                          maxLength={50}
                        />
                        <div
                          className='eye-absolute'
                          style={{
                            position: 'absolute',
                            top: '1.8rem',
                            right: '10px'
                          }}
                        >
                          {values.isConfirmPasswordVisible ? (
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='18'
                              height='18'
                              onClick={() => {
                                setFieldValue(
                                  'isConfirmPasswordVisible',
                                  !values.isConfirmPasswordVisible
                                )
                              }}
                              viewBox='0 0 24 24'
                              fill='none'
                              stroke='currentColor'
                              strokeWidth='2'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              className={`feather feather-eye link-icon toggle-password eye-icon w-75`}
                              data-password=''
                            >
                              <path d='M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z'></path>
                              <circle cx='12' cy='12' r='3'></circle>
                            </svg>
                          ) : (
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='18'
                              height='18'
                              viewBox='0 0 24 24'
                              fill='none'
                              stroke='currentColor'
                              onClick={() => {
                                setFieldValue(
                                  'isConfirmPasswordVisible',
                                  !values.isConfirmPasswordVisible
                                )
                              }}
                              strokeWidth='2'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              className='feather feather-eye-off link-icon toggle-password eye-off-icon w-75'
                              data-password=''
                            >
                              <path d='M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24'></path>
                              <line x1='1' y1='1' x2='23' y2='23'></line>
                            </svg>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}

                <div className='col-md-6'>
                  <div className='input-select-wrapper mb-3'>
                    <label className='form-label required'>
                      Select User Type
                    </label>
                    <Select
                      id='userTypeId'
                      menuPortalTarget={document.body}
                      menuPosition={'fixed'}
                      defaultValue={
                        initialdata
                          ? null
                          : {
                              value: values?.userTypeId,
                              label: values?.userType
                            }
                      }
                      styles={customStyles}
                      options={dropDownData?.data?.map(({ id, name }) => ({
                        value: id,
                        label: name
                      }))}
                      onChange={(e) => {
                        if (e) {
                          setFieldValue('userTypeId', e?.value)
                        }
                      }}
                    />
                    <ErrorMessage name='userTypeId' component={TextError} />
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='input-select-wrapper mb-3'>
                    <label className='form-label required'>
                      Receive Notification
                    </label>
                    <Select
                      styles={customStyles}
                      id='receiveNotification'
                      menuPortalTarget={document.body}
                      menuPosition={'fixed'}
                      isMulti
                      value={
                        values?.receiveNotifications?.length > 0 &&
                        values?.receiveNotifications?.map(
                          ({ label, value }) => {
                            return {
                              label,
                              value
                            }
                          }
                        )
                      }
                      options={[
                        { value: 'Seller', label: 'Seller' },
                        { value: 'Product', label: 'Product' }
                      ]}
                      onChange={(e) => {
                        if (e) {
                          setFieldValue('receiveNotifications', e)
                        }
                      }}
                    />
                    <ErrorMessage
                      name='receiveNotifications'
                      component={TextError}
                    />
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='input-select-wrapper mb-3'>
                    <label className='form-label required'>Select Status</label>
                    <Select
                      id='status'
                      menuPortalTarget={document.body}
                      menuPosition={'fixed'}
                      defaultValue={
                        initialdata
                          ? null
                          : {
                              value: values?.status,
                              label: values?.status
                            }
                      }
                      styles={customStyles}
                      options={_status_}
                      onChange={(e) => {
                        if (e) {
                          setFieldValue('status', e?.value)
                        }
                      }}
                    />
                    <ErrorMessage name='status' component={TextError} />
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </ModelComponent>
    </>
  )
}

export default MainAdmin
