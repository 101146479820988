import { ErrorMessage, Form, Formik } from 'formik'
import FormikControl from '../../../components/FormikControl'
import * as Yup from 'yup'
import Select from 'react-select'
import SellerModal from '../../../components/SellerModal'
import Loader from '../../../components/Loader'
import TextError from '../../../components/TextError'
import {
  _alphabetRegex_,
  _emailRegex_,
  _gstNumberRegex_,
  _passwordRegex_,
  _phoneNumberRegex_
} from '../../../lib/Regex'
import { Button } from 'react-bootstrap'
import { generatePassword } from '../../../lib/GetBaseUrl'
import { prepareNotificationData, showToast } from '../../../lib/GetBaseUrl'
import { getBaseUrl } from '../../../lib/GetBaseUrl'
import axiosProvider from '../../../lib/AxiosProvider'
import { customStyles } from '../../../components/customStyles'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { _exception } from '../../../lib/exceptionMessage'
const CreateSellerModal = ({
  loading,
  setLoading,
  initialValues,
  setInitialValues,
  modalShow,
  setModalShow,
  isModalRequired,
  fetchData,
  toast,
  setToast
}) => {
  const { userInfo } = useSelector((state) => state?.user)
  const location = useLocation()

  const validationSchema = Yup.object().shape({
    // gstNo:
    //   !initialValues?.createSeller?.id &&
    //   Yup.string()
    //     .required('Please enter GST number')
    //     .matches(_gstNumberRegex_, 'Please enter proper GST number'),
    // kycFor:
    //   !initialValues?.createSeller?.id &&
    //   Yup.string()
    //     .test(
    //       'nonull',
    //       'Please select kyc for',
    //       (value) => value !== 'undefined'
    //     )
    //     .required('Please select kyc for'),
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    emailID:
      !initialValues?.createSeller?.id &&
      Yup.string()
        .matches(_emailRegex_, 'Please enter a valid email id')
        .required('Email id is required'),
    // password:
    //   !initialValues?.createSeller?.id &&
    //   Yup.string()
    //     .matches(
    //       _passwordRegex_,
    //       'Password must be at least 6 characters with a mix of lowercase, uppercase, digit, and special characters.'
    //     )
    //     .required('Password is required'),
    mobileNo: Yup.string()
      .required('Mobile number is required')
      .matches(
        /^[6-9]{1}[0-9]{9}$/,
        'Mobile number starting ranges between 6 - 9'
      ),
    // cpass:
    //   !initialValues?.createSeller?.id &&
    //   Yup.string()
    //     .oneOf([Yup.ref('password'), null], 'Passwords must match')
    //     .required('Confirm password required'),
    displayName: Yup.string().required('Please enter display name'),
    ownerName: Yup.string().required('Please enter owner name'),
    contactPersonName: Yup.string().required(
      'Please enter contact person name'
    ),
    contactPersonMobileNo: Yup.string().required(
      'Please enter contact person Number'
    ),
    companyRegistrationNo: Yup.string().required(
      'Please enter company registration number'
    ),
    typeOfCompany: Yup.string()
      .test(
        'nonull',
        'Please select type of company',
        (value) => value !== 'undefined'
      )
      .required('Please select type of company'),
    bussinessType: Yup.string()
      .test(
        'nonull',
        'Please select entity type',
        (value) => value !== 'undefined'
      )
      .required('Please select entity type'),
    status: Yup.string()
      .test('nonull', 'Please select status', (value) => value !== 'undefined')
      .required('Please select status')
  })

  const renderComponent = () => {
    return (
      <Formik
        enableReinitialize
        initialValues={initialValues?.createSeller}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue, errors }) => (
          <Form id='createSeller' className='add_seller_form'>
            <h3 className='text-center my-3 head_h3'>
              {values?.id ? 'Update' : 'Create'} Seller Account
            </h3>
            {isModalRequired ? (
              <></>
            ) : (
              <div
                className='modal-header m-auto my-4'
                style={{ width: 'fit-content' }}
              >
                {!values?.userID ? (
                  <div className='ml-auto position-relative'>
                    <ul id='progressbar'>
                      <li className='active' id='account'>
                        <i className='m-icon m-icon--create_account active'></i>
                        <strong>Create Account</strong>
                      </li>
                      <li className='' id='personal'>
                        <i className='m-icon m-icon--kyc_info'></i>
                        <strong>Brand</strong>
                      </li>
                      {/* <li className='' id='payment'>
                        <i className='m-icon m-icon--gst_info'></i>
                        <strong>GST Info</strong>
                      </li>
                      <li className='' id='confirm'>
                        <i className='m-icon m-icon--warehouse'></i>
                        <strong>Warehouse</strong>
                      </li> */}
                    </ul>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            )}

            <div className='row'>
              <div className='col-md-6'>
                <div className='input-file-wrapper mb-3'>
                  <FormikControl
                    isRequired
                    control='input'
                    label='First Name'
                    id='firstName'
                    type='text'
                    name='firstName'
                    value={values?.firstName}
                    placeholder='First Name'
                    onChange={(e) => {
                      let inputValue = e?.target?.value
                      let isValid = _alphabetRegex_.test(inputValue)
                      let fieldName = e?.target?.name
                      if (!inputValue || isValid) {
                        setFieldValue(fieldName, inputValue)
                      }
                    }}
                    onBlur={(e) => {
                      let fieldName = e?.target?.name
                      setFieldValue(fieldName, values[fieldName]?.trim())
                    }}
                  />
                </div>
              </div>
              <div className='col-md-6'>
                <div className='input-file-wrapper mb-3'>
                  <FormikControl
                    isRequired
                    control='input'
                    label='Last Name'
                    id='lastName'
                    type='text'
                    name='lastName'
                    value={values?.lastName}
                    placeholder='Last Name'
                    onChange={(e) => {
                      let inputValue = e?.target?.value
                      let isValid = _alphabetRegex_.test(inputValue)
                      let fieldName = e?.target?.name
                      if (!inputValue || isValid) {
                        setFieldValue(fieldName, inputValue)
                      }
                    }}
                    onBlur={(e) => {
                      let fieldName = e?.target?.name
                      setFieldValue(fieldName, values[fieldName]?.trim())
                    }}
                  />
                </div>
              </div>
            </div>

            {/* {!editData && (
              <div className="row">
                <div className="col-md-6 m-auto">
                  <div className="input-file-wrapper mb-3">
                    <FormikControl
                      isRequired
                      control='input'
                      label='GST Number'
                      id='gstNo'
                      type='text'
                      name='gstNo'
                      placeholder='GST Number'
                      onBlur={(e) => {
                        let fieldName = e?.target?.name
                        setFieldValue(fieldName, values[fieldName]?.trim())
                      }}
                      // onChange={(e) => {
                      //   let regx = new RegExp(
                      //     /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/
                      //   )
                      //   if (regx.test(e?.target?.value)) {
                      //     const resp = axios
                      //       .get(
                      //         `https://gst-return-status.p.rapidapi.com/free/gstin/${e?.target?.value}`,
                      //         {
                      //           headers: {
                      //             'Content-Type': 'application/json',
                      //             'X-RapidAPI-Key':
                      //               '79dc2f4875msh7a098852c46042bp182f46jsna2abc4d2ddcb',
                      //             'X-RapidAPI-Host':
                      //               'gst-return-status.p.rapidapi.com'
                      //           }
                      //         }
                      //       )
                      //       .then((res) => {
                      //         if (res?.status === 200) {
                      //           // setFieldValue('bankName', res?.data?.BANK)
                      //         }
                      //       })
                      //     // fetchDetails(e?.target?.value)
                      //   }
                      // }}
                    />
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='input-file-wrapper mb-3'>
                    <label className='form-label required'>KYC For</label>
                    <Select
                      id='kycFor'
                      menuPortalTarget={document.body}
                      value={
                        values?.kycFor && {
                          value: values?.kycFor,
                          label: values?.kycFor
                        }
                      }
                      styles={customStyles}
                      options={[
                        {
                          label: 'Supplier',
                          value: 'Supplier'
                        },
                        {
                          label: 'Hubspot',
                          value: 'Hubspot'
                        },
                        {
                          label: 'Brand',
                          value: 'Brand'
                        }
                      ]}
                      onChange={(e) => {
                        if (e) {
                          setFieldValue('kycFor', e?.value)
                        }
                      }}
                    />
                    <ErrorMessage name='kycFor' component={TextError} />
                  </div>
                </div>
              </div>
            )} */}

            <div className='row'>
              <div className='col-md-6'>
                <div className='input-file-wrapper mb-3'>
                  <FormikControl
                    isRequired
                    disabled={values?.userID ? true : false}
                    control='input'
                    label='Email Id'
                    id='emailID'
                    type='email'
                    name='emailID'
                    value={
                      values?.userName ? values?.userName : values?.emailID
                    }
                    placeholder='Email Id'
                    onBlur={(e) => {
                      let fieldName = e?.target?.name
                      setFieldValue(fieldName, values[fieldName]?.trim())
                    }}
                  />
                </div>
              </div>
              <div className='col-md-6'>
                <div className='input-file-wrapper mb-3'>
                  <FormikControl
                    isRequired
                    control='input'
                    label='Mobile Number'
                    id='mobileNo'
                    type='text'
                    name='mobileNo'
                    maxLength='10'
                    placeholder='Mobile Number'
                    onChange={(e) => {
                      let inputValue = e?.target?.value
                      let isValid = _phoneNumberRegex_.test(inputValue)
                      let fieldName = e?.target?.name
                      if (!inputValue || isValid) {
                        setFieldValue(fieldName, inputValue)
                      }
                    }}
                    onBlur={(e) => {
                      let fieldName = e?.target?.name
                      setFieldValue(fieldName, values[fieldName]?.trim())
                    }}
                  />
                </div>
              </div>

              <div className='col-md-6'>
                <div className='input-file-wrapper mb-3'>
                  <FormikControl
                    isRequired
                    control='input'
                    label='Display Name'
                    id='displayName'
                    type='text'
                    name='displayName'
                    placeholder='Display Name'
                    onChange={(e) => {
                      const fieldName = e?.target?.name
                      let inputValue = e?.target?.value
                      let isValid = _alphabetRegex_.test(inputValue)
                      if (!inputValue || isValid) {
                        setFieldValue(fieldName, e?.target?.value)
                      }
                    }}
                  />
                </div>
              </div>
              <div className='col-md-6'>
                <div className='input-file-wrapper mb-3'>
                  <FormikControl
                    isRequired
                    control='input'
                    label='Owner Name'
                    id='ownerName'
                    type='text'
                    name='ownerName'
                    placeholder='Owner Name'
                    onChange={(e) => {
                      const fieldName = e?.target?.name
                      let inputValue = e?.target?.value
                      let isValid = _alphabetRegex_.test(inputValue)
                      if (!inputValue || isValid) {
                        setFieldValue(fieldName, e?.target?.value)
                      }
                    }}
                  />
                </div>
              </div>

              <div className='col-md-6'>
                <div className='input-file-wrapper mb-3'>
                  <FormikControl
                    isRequired
                    control='input'
                    label='Contact Person Name'
                    id='contactPersonName'
                    type='text'
                    name='contactPersonName'
                    placeholder='Contact Person Name'
                    onChange={(e) => {
                      const fieldName = e?.target?.name
                      let inputValue = e?.target?.value
                      let isValid = _alphabetRegex_.test(inputValue)
                      if (!inputValue || isValid) {
                        setFieldValue(fieldName, e?.target?.value)
                      }
                    }}
                  />
                </div>
              </div>
              <div className='col-md-6'>
                <div className='input-file-wrapper mb-3'>
                  <FormikControl
                    isRequired
                    control='input'
                    label='Contact Person Mobile No'
                    id='contactPersonMobileNo'
                    type='text'
                    maxLength='10'
                    name='contactPersonMobileNo'
                    placeholder='Contact Person Mobile No'
                    onChange={(event) => {
                      const inputValue = event.target.value
                      const regex = _phoneNumberRegex_
                      if (inputValue === '' || regex.test(inputValue)) {
                        setFieldValue('contactPersonMobileNo', inputValue)
                      }
                    }}
                  />
                </div>
              </div>

              <div className='col-md-6'>
                <div className='input-file-wrapper mb-3'>
                  <label className='form-label required'>Entity Type</label>
                  <Select
                    id='bussinessType'
                    menuPortalTarget={document.body}
                    value={
                      values?.bussinessType && {
                        value: values?.bussinessType,
                        label: values?.bussinessType
                      }
                    }
                    placeholder='Select Entity type'
                    styles={customStyles}
                    options={[
                      {
                        label: 'Brand Owner',
                        value: 'Brand Owner'
                      },
                      {
                        label: 'Manufacture',
                        value: 'Manufacture'
                      },
                      {
                        label: 'Sole Distributer',
                        value: 'Sole Distributer'
                      },
                      {
                        label: 'Sole Franchise',
                        value: 'Sole Franchise'
                      },
                      {
                        label: 'Sole Dealer',
                        value: 'Sole Dealer'
                      },
                      {
                        label: 'Distributor',
                        value: 'Distributor'
                      },
                      {
                        label: 'Franchisee',
                        value: 'Franchisee'
                      },
                      {
                        label: 'Dealer',
                        value: 'Dealer'
                      },
                      {
                        label: 'Retailer',
                        value: 'Retailer'
                      },
                      {
                        label: 'Wholesaler',
                        value: 'Wholesaler'
                      },
                      {
                        label: 'NGO',
                        value: 'NGO'
                      },

                      {
                        label: 'Government Agency',
                        value: 'Government Agency'
                      },
                      {
                        label: 'Cooperative MSME',
                        value: 'DealCooperative MSMEer'
                      },
                      {
                        label: 'Trust',
                        value: 'Trust'
                      },
                      {
                        label: 'Self-help Group',
                        value: 'Self-help Group'
                      },
                      {
                        label: 'Social Enterprise',
                        value: 'Social Enterprise'
                      },
                      {
                        label: 'Others',
                        value: 'Others'
                      }
                    ]}
                    onChange={(e) => {
                      if (e) {
                        setFieldValue('bussinessType', e?.value)
                      }
                    }}
                  />
                  <ErrorMessage name='bussinessType' component={TextError} />
                </div>
              </div>
              <div className='col-md-6'>
                <div className='input-file-wrapper mb-3'>
                  <label className='form-label required'>Type Of Company</label>
                  <Select
                    id='typeOfCompany'
                    menuPortalTarget={document.body}
                    placeholder='Select type of company'
                    value={
                      values?.typeOfCompany && {
                        value: values?.typeOfCompany,
                        label: values?.typeOfCompany
                      }
                    }
                    styles={customStyles}
                    options={[
                      {
                        label: 'Sole Proprietorship',
                        value: 'Sole Proprietorship'
                      },
                      {
                        label: 'Partnership',
                        value: 'Partnership'
                      },
                      {
                        label: 'Proprietorship',
                        value: 'Proprietorship'
                      },
                      {
                        label: 'Limited Liability Partnership',
                        value: 'Limited Liability Partnership'
                      },
                      {
                        label: 'Private Limited Company',
                        value: 'Private Limited Company'
                      },
                      {
                        label: 'Public Limited Company',
                        value: 'Public Limited Company'
                      },
                      {
                        label: 'Society/Trust',
                        value: 'Society/Trust'
                      },
                      {
                        label: 'Hindu Undivided Family',
                        value: 'Hindu Undivided Family'
                      }
                    ]}
                    onChange={(e) => {
                      if (e) {
                        setFieldValue('typeOfCompany', e?.value)
                      }
                    }}
                  />
                  <ErrorMessage name='typeOfCompany' component={TextError} />
                </div>
              </div>
              <div className='col-md-6'>
                <div className='input-file-wrapper mb-3'>
                  <FormikControl
                    isRequired
                    control='input'
                    label='Company Registration No'
                    id='companyRegistrationNo'
                    type='text'
                    name='companyRegistrationNo'
                    placeholder='Company Registration No'
                    onChange={(e) => {
                      setFieldValue('companyRegistrationNo', e?.target?.value)
                    }}
                  />
                </div>
              </div>
              <div className='col-md-6'>
                <div className='input-file-wrapper mb-3'>
                  <label className='form-label required'>Status</label>
                  <Select
                    id='status'
                    menuPortalTarget={document.body}
                    placeholder='Select status'
                    value={
                      values?.status && {
                        value: values?.status,
                        label: values?.status
                      }
                    }
                    styles={customStyles}
                    // isDisabled={!initialdata ? true : false}
                    options={[
                      {
                        label: 'Active',
                        value: 'Active'
                      },
                      {
                        label: 'Inactive',
                        value: 'Inactive'
                      }
                    ]}
                    onChange={(e) => {
                      if (e) {
                        setFieldValue('status', e?.value)
                      }
                    }}
                  />
                  <ErrorMessage name='status' component={TextError} />
                </div>
              </div>
              <div className='col-md-12'>
                <div className='input-file-wrapper mb-3'>
                  <FormikControl
                    control='input'
                    as='textarea'
                    label='Note'
                    id='note'
                    type='text'
                    name='note'
                    placeholder='Note'
                    onChange={(e) => {
                      setFieldValue('note', e?.target?.value)
                    }}
                  />
                </div>
              </div>
            </div>

            {/* {!values?.id && (
              <div className='row'>
                <div className='col-md-6'>
                  <div className='input-wrapper pv-login-password mb-4 pos-relative'>
                    <FormikControl
                      isRequired
                      control='input'
                      label='Password'
                      id='password'
                      type={values?.newPasswordVisible ? 'text' : 'password'}
                      name='password'
                      className='he-10'
                      placeholder='Password'
                      onBlur={(e) => {
                        let fieldName = e?.target?.name
                        setFieldValue(fieldName, values[fieldName]?.trim())
                      }}
                      maxLength={50}
                    />
                    <div
                      className='eye-absolute'
                      style={{
                        position: 'absolute',
                        top: '1.8rem',
                        right: '10px'
                      }}
                    >
                      {!values?.newPasswordVisible ? (
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='18'
                          height='18'
                          onClick={() => {
                            setFieldValue(
                              'newPasswordVisible',
                              !values?.newPasswordVisible
                            )
                          }}
                          viewBox='0 0 24 24'
                          fill='none'
                          stroke='currentColor'
                          strokeWidth='2'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          className={`feather feather-eye link-icon toggle-password eye-icon w-75`}
                          data-password=''
                        >
                          <path d='M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z'></path>
                          <circle cx='12' cy='12' r='3'></circle>
                        </svg>
                      ) : (
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='18'
                          height='18'
                          viewBox='0 0 24 24'
                          fill='none'
                          stroke='currentColor'
                          onClick={() => {
                            setFieldValue(
                              'newPasswordVisible',
                              !values?.newPasswordVisible
                            )
                          }}
                          strokeWidth='2'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          className='feather feather-eye-off link-icon toggle-password eye-off-icon w-75'
                          data-password=''
                        >
                          <path d='M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24'></path>
                          <line x1='1' y1='1' x2='23' y2='23'></line>
                        </svg>
                      )}
                    </div>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='input-wrapper pv-login-password mb-4 pos-relative'>
                    <FormikControl
                      isRequired
                      control='input'
                      label='Confirm Password'
                      id='cpass'
                      type={
                        values?.confirmPasswordVisible ? 'text' : 'password'
                      }
                      name='cpass'
                      placeholder='Confirm Password'
                      onBlur={(e) => {
                        let fieldName = e?.target?.name
                        setFieldValue(fieldName, values[fieldName]?.trim())
                      }}
                      maxLength={50}
                    />
                    <div
                      className='eye-absolute'
                      style={{
                        position: 'absolute',
                        top: '1.8rem',
                        right: '10px'
                      }}
                    >
                      {!values?.confirmPasswordVisible ? (
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='18'
                          height='18'
                          onClick={() => {
                            setFieldValue(
                              'confirmPasswordVisible',
                              !values?.confirmPasswordVisible
                            )
                          }}
                          viewBox='0 0 24 24'
                          fill='none'
                          stroke='currentColor'
                          strokeWidth='2'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          className={`feather feather-eye link-icon toggle-password eye-icon w-75`}
                          data-password=''
                        >
                          <path d='M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z'></path>
                          <circle cx='12' cy='12' r='3'></circle>
                        </svg>
                      ) : (
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='18'
                          height='18'
                          viewBox='0 0 24 24'
                          fill='none'
                          stroke='currentColor'
                          onClick={() => {
                            setFieldValue(
                              'confirmPasswordVisible',
                              !values?.confirmPasswordVisible
                            )
                          }}
                          strokeWidth='2'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          className='feather feather-eye-off link-icon toggle-password eye-off-icon w-75'
                          data-password=''
                        >
                          <path d='M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24'></path>
                          <line x1='1' y1='1' x2='23' y2='23'></line>
                        </svg>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )} */}

            {!isModalRequired && (
              <Button type='submit' id='createSeller'>
                Submit details
              </Button>
            )}
          </Form>
        )}
      </Formik>
    )
  }

  const onSubmit = async (values) => {
    let deviceId = `Archioo${Math.floor(Math.random() * 1000000000)}`
    values = { ...values, password: 'Admin@123#', deviceId }

    try {
      setLoading(true)
      const response = await axiosProvider({
        method: values?.userID ? 'PUT' : 'POST',
        endpoint: !values?.userID
          ? 'Account/Seller/Register'
          : 'Account/Seller/Update',
        data: values,
        oldData: initialValues?.createSeller,
        location: location?.pathname,
        userId: userInfo?.userId
      })
      setLoading(false)
      if (response?.status === 200) {
        if (!values?.userID) {
          setModalShow((draft) => {
            draft.createSeller = false
            draft.basicInfo = true
            draft.gstInfo = false
            draft.warehouse = false
          })
          let sellerID = response?.data?.data?.userID
          setInitialValues({
            ...initialValues,
            createSeller: {
              ...values,
              userID: sellerID
            },
            basicInfo: {
              ...initialValues?.basicInfo,
              sellerID
            },
            gstInfo: {
              ...initialValues?.gstInfo,
              gstNo: values?.gstNo
            },
            warehouse: {
              ...initialValues?.warehouse,
              gstNo: values?.gstNo
            }
          })
        } else {
          setModalShow((draft) => {
            draft.createSeller = false
          })
        }

        fetchData()
        const sendNotification = axiosProvider({
          endpoint: 'Notification',
          method: 'POST',
          data: prepareNotificationData({
            reciverId: values?.userID
              ? values?.userID
              : response?.data?.data?.userID,
            userId: userInfo?.userId,
            userType: userInfo?.userType,
            notificationTitle: `Seller: ${values?.displayName}
            ${
              values?.userID
                ? 'updated details successfully'
                : 'registered successfully'
            }`,
            notificationDescription: `${values?.id ? 'Update' : 'Created'} by ${
              userInfo?.fullName
            }`,
            url: `/manage-seller/seller-details/${
              values?.userID ? values?.userID : response?.data?.data?.userID
            }`,
            notifcationsof: 'Seller'
          })
        })
        showToast(toast, setToast, response)
      }
    } catch (error) {
      showToast(toast, setToast, {
        data: {
          message: 'Something went wrong!',
          code: 204
        }
      })
    }
  }

  return isModalRequired ? (
    <SellerModal
      show={modalShow.createSeller}
      modalsize={'lg'}
      modalheaderclass={''}
      onHide={() => setModalShow((draft) => (draft.createSeller = false))}
      btnclosetext={''}
      closebtnvariant={''}
      backdrop={'static'}
      buttonclass={'justify-content-center'}
      formbuttonid={'createSeller'}
      submitname={'Submit Details'}
      modalclass={'create_seller'}
      modalheader={
        !initialValues?.createSeller?.userID ? (
          <div className='ml-auto position-relative'>
            <ul id='progressbar'>
              <li className='active' id='account'>
                <i className='m-icon m-icon--create_account active'></i>
                <strong>Create Account</strong>
              </li>
              <li className='' id='personal'>
                <i className='m-icon m-icon--kyc_info'></i>
                <strong>Brand Info</strong>
              </li>
              {/* <li className='' id='payment'>
                <i className='m-icon m-icon--gst_info'></i>
                <strong>GST Info</strong>
              </li>
              <li className='' id='confirm'>
                <i className='m-icon m-icon--warehouse'></i>
                <strong>Warehouse</strong>
              </li> */}
            </ul>
          </div>
        ) : (
          <></>
        )
      }
    >
      {renderComponent()}
    </SellerModal>
  ) : (
    renderComponent()
  )
}

export default CreateSellerModal
