import React, { useState } from 'react';
import TooltipComponent from '../Tooltip';

const EditIcon = ({ bg }) => {
    // const [isHovered, setIsHovered] = useState(false);

    // let fillColor = "#6c6c6c"
    // let hoverColor = "101010"


    // let bgfillColor = "#cfe2ff"
    // let bghoverColor = "#bacbe6"
    // let bgfillstaticolor = "#052c65"
    // let bgfillhovercolor = "#000"

    // const handleMouseEnter = () => {
    //     setIsHovered(true);
    // };

    // const handleMouseLeave = () => {
    //     setIsHovered(false);
    // };

    const WithBackground = () => {
        return (
            <TooltipComponent toolplace='top' tooltipText='Edit'>
            <svg role="button" className='rounded pv-editicon-bg' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                <defs>
                    <clipPath id="clip-Edit">
                        <rect width="50" height="50" />
                    </clipPath>
                </defs>
                <g id="Edit" clipPath="url(#clip-Edit)">
                    {/* <rect width="50" height="50" fill="#fff" /> */}
                    <g id="noun-edit-5698707" transform="translate(-108.732 -31.702)">
                        <path id="Subtraction_28" data-name="Subtraction 28" d="M149.129-3713.639H129.953a4.1,4.1,0,0,1-4.091-4.091v-19.176a4.1,4.1,0,0,1,4.091-4.092h14.588a.773.773,0,0,1,.772.773.773.773,0,0,1-.772.772H129.953a2.55,2.55,0,0,0-2.547,2.547v19.176a2.55,2.55,0,0,0,2.547,2.547h19.176a2.55,2.55,0,0,0,2.547-2.547v-14.588a.773.773,0,0,1,.772-.772.773.773,0,0,1,.772.772v14.588A4.1,4.1,0,0,1,149.129-3713.639Z" transform="translate(-6.131 3785.192)" />
                        <path id="Subtraction_27" data-name="Subtraction 27" d="M127.629-3719.666a.071.071,0,0,1-.052-.028.061.061,0,0,1-.013-.059l.677-3.074a3.348,3.348,0,0,1,.9-1.65l13.165-14.166a2.27,2.27,0,0,1,1.6-.656,2.268,2.268,0,0,1,1.614.668,2.269,2.269,0,0,1,.669,1.614,2.268,2.268,0,0,1-.669,1.613l-13.166,14.166a3.354,3.354,0,0,1-1.637.892l-3.074.677Z" transform="translate(2.252 3782)" />
                    </g>
                </g>
            </svg>
            </TooltipComponent>
        )
    };

    const WithoutBackground = () => {
        return (
            <TooltipComponent toolplace='top' tooltipText='Edit'>
                <svg role="button" className='pv-editicon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                    <defs>
                        <clipPath id="clip-Edit">
                            <rect width="50" height="50" />
                        </clipPath>
                    </defs>
                    <g id="Edit" clipPath="url(#clip-Edit)">
                        <g id="noun-edit-5698707" transform="translate(-118.732 -42.703)">
                            <path id="Subtraction_28" data-name="Subtraction 28" d="M39.638,46.609H6.969A6.977,6.977,0,0,1,0,39.64V6.972A6.978,6.978,0,0,1,6.969,0H31.822a1.316,1.316,0,0,1,0,2.631H6.969a4.345,4.345,0,0,0-4.34,4.34V39.64a4.345,4.345,0,0,0,4.34,4.339H39.638a4.345,4.345,0,0,0,4.34-4.339V14.787a1.315,1.315,0,1,1,2.63,0V39.64A6.977,6.977,0,0,1,39.638,46.609Z" transform="translate(119.732 45.243)" />
                            <path id="Subtraction_27" data-name="Subtraction 27" d="M.115,33.446A.12.12,0,0,1,.027,33.4.1.1,0,0,1,0,33.3l1.153-5.237A5.706,5.706,0,0,1,2.7,25.251L25.126,1.118A3.888,3.888,0,0,1,30.6,6.637L8.175,30.77a5.711,5.711,0,0,1-2.79,1.52L.148,33.443Z" transform="translate(136.908 42.702)" />
                        </g>
                    </g>
                </svg>
            </TooltipComponent>

        )
    };

    return (
        <div className='pv-editeicon-main'>
            {bg ? <WithBackground /> : <WithoutBackground />}
        </div>
    )
};

export default EditIcon;