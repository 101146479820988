import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import RoleTypeWise from './RoleTypeWise'
import UserWise from './UserWise'
import { useEffect } from 'react'
import { setPageTitle } from '../../redux/slice/pageTitleSlice'
import { useDispatch, useSelector } from 'react-redux'
import NotFound from '../../NotFound/NotFound'

const ManageAssignRoles = () => {
  const [activeToggle, setActiveToggle] = useState('role_type_wise')
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setPageTitle('Assign Page Role'))
  }, [])
  const { userInfo } = useSelector((state) => state.user)
  const handleTabClick = (e, tabName) => {
    e.preventDefault()
    setActiveToggle(tabName)
  }

  return userInfo?.userType?.toLowerCase() === 'super admin' ? (
    <>
      <div className='card overflow-hidden'>
        <div className='card-body p-0'>
          <div className='nav-tabs-horizontal nav nav-tabs'>
            <Link
              onClick={(e) => handleTabClick(e, 'role_type_wise')}
              data-toggle='tab'
              className={`nav-link fw-semibold ${
                activeToggle === 'role_type_wise' ? 'active show' : ''
              }`}
            >
              <span className='nav-span'>
                Role Type Wise
                <hr />
              </span>
            </Link>
            <Link
              onClick={(e) => handleTabClick(e, 'user_wise')}
              data-toggle='tab'
              className={`nav-link fw-semibold ${
                activeToggle === 'user_wise' ? 'active show' : ''
              }`}
            >
              <span className='nav-span'>
                User Wise
                <hr />
              </span>
            </Link>
          </div>

          <div className='tab-content p-3'>
            <div
              id='role_type_wise'
              className={`tab-pane fade ${
                activeToggle === 'role_type_wise' ? 'active show' : ''
              }`}
            >
              {activeToggle === 'role_type_wise' && <RoleTypeWise />}
            </div>
            <div
              id='user_wise'
              className={`tab-pane fade ${
                activeToggle === 'user_wise' ? 'active show' : ''
              }`}
            >
              {activeToggle === 'user_wise' && <UserWise />}
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <NotFound />
  )
}

export default ManageAssignRoles
