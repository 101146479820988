import React, { useState, useEffect, useRef } from 'react'
import { Formik, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../components/FormikControl'
import Select from 'react-select'
import axiosProvider from '../../../lib/AxiosProvider'
import TextError from '../../../components/TextError'
import ModelComponent from '../../../components/Modal'
import Loader from '../../../components/Loader'
import { customStyles } from '../../../components/customStyles'
import CustomToast from '../../../components/Toast/CustomToast'
import IpCheckbox from '../../../components/IpCheckbox'
import { Table } from 'react-bootstrap'
import { _status_ } from '../../../lib/GetBaseUrl'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { _SwalDelete } from '../../../lib/exceptionMessage'
import Swal from 'sweetalert2'

const UserWise = () => {
  const [data, setData] = useState()
  const [filteredData, setFilteredData] = useState()
  const [dropDownData, setDropDownData] = useState()
  const [secondDropDownData, setSecondDropDownData] = useState([])
  const [pageEntries, setPageEntries] = useState(10)
  const [onClickPage, setOnClickPage] = useState(1)
  const [pageCount, setPageCount] = useState(1)
  const [filteredPageCount, setFilteredPageCount] = useState(1)
  const [loading, setLoading] = useState(false)
  const [toast, setToast] = useState({
    show: false,
    text: null,
    variation: null
  })
  const { userId } = useSelector((state) => state?.user?.userInfo)
  const location = useLocation()
  const ref = useRef()

  useEffect(() => {
    clearController()
  }, [secondDropDownData])

  const clearController = () => {
    if (!secondDropDownData?.length) {
      ref?.current?.clearValue()
    }
  }

  useEffect(() => {
    fetchDropDownData()
  }, [pageEntries, onClickPage])

  const fetchData = async (id, setFieldValue) => {
    setLoading(true)
    const response = await axiosProvider({
      method: 'GET',
      endpoint: 'PageModule',
      queryString: `?pageSize=0&pageIndex=0`
    })
      .then(async (res) => {
        if (res.status === 200 && res.data.data.length > 0) {
          let pageModuleArray = res?.data?.data
          let unionArr = res?.data?.data
          const api = await axiosProvider({
            method: 'GET',
            endpoint: 'AssignPageRolesByUserId',
            queryString: `?Id=${id}`
          }).then((res) => {
            if (res?.status === 200 && res?.data?.data.length > 0) {
              let pageModuleIdArray = res?.data?.data
              unionArr = pageModuleIdArray.concat(
                pageModuleArray.filter(
                  (obj2) =>
                    !pageModuleIdArray.find(
                      (obj1) => obj1.pageRoleId === obj2.id
                    )
                )
              )
            }
          })
          setFieldValue('pageRoleData', unionArr)
          setData(res)
          setFilteredData(res)
          setPageCount(res.data?.pagination?.pageCount)
          setFilteredPageCount(res.data?.pagination?.pageCount)
        } else {
          setPageCount(0)
          setFilteredPageCount(0)
        }
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
      })
  }

  const fetchDropDownData = async () => {
    setLoading(true)
    const response = await axiosProvider({
      method: 'GET',
      endpoint: 'Account/Admin/NoSuperAdminList',
      queryString: `?pageindex=0&PageSize=0`
    })
      .then((res) => {
        if (res.status === 200 && res.data.data.length > 0) {
          setDropDownData(res?.data)
        }
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
      })
  }

  const handlePageClick = (event) => {
    setOnClickPage(event.selected + 1)
  }

  const changeRight = async (data, setFieldValue, pageModuleArray) => {
    setLoading(true)
    const response = await axiosProvider({
      method: 'POST',
      endpoint: `AssignPageRoles`,
      data,
      location: location?.pathname,
      userId
    })
      .then((res) => {
        if (res?.status === 200) {
          let pageModule = pageModuleArray?.map((page) => {
            if (page?.pageRoleId === data?.pageRoleId) {
              return data
            } else {
              return page
            }
          })
          setFieldValue('pageRoleData', pageModule)
        }
        setLoading(false)
        setToast({
          show: true,
          text: res?.data?.message,
          variation: res?.data?.code !== 200 ? 'error' : 'success'
        })

        setTimeout(() => {
          setToast({ ...toast, show: false })
        }, 2000)
      })
      .catch((err) => {
        setLoading(false)
      })
  }
  const initValForTable = {}

  return (
    <>
      <Formik initialValues={initValForTable}>
        {({ values, setFieldValue }) => (
          <>
            <div className='d-flex align-items-center mb-3 gap-3'>
              <div className='input-select-wrapper flex-fill'>
                <label className='form-label'>Select User</label>
                <Select
                  id='userId'
                  menuPortalTarget={document.body}
                  menuPosition={'fixed'}
                  styles={customStyles}
                  options={dropDownData?.data?.map(
                    ({ id, userName, roleTypeId, firstName, lastName }) => ({
                      value: id,
                      label: `${firstName} ${lastName} - ${userName}`,
                      roleTypeId
                    })
                  )}
                  onChange={(e) => {
                    if (e) {
                      fetchData(e?.value, setFieldValue)
                      setFieldValue('roleTypeId', e?.roleTypeId)
                      setFieldValue('userID', e?.value)
                    }
                  }}
                />
              </div>
              {/* <Button
          variant='warning'
          className='d-flex align-items-center gap-2 fw-semibold'
          onClick={() => {
            !dropDownData && fetchDropDownData()
          }}
        >
          <i className='m-icon m-icon--plusblack'></i>
          Create
        </Button> */}
            </div>

            {values?.pageRoleData?.length > 0 && (
              <Table responsive className='align-middle table-list'>
                <thead>
                  <tr>
                    <th>Page Name</th>
                    <th className='text-center'>Select All</th>
                    <th className='text-center'>View</th>
                    <th className='text-center'>Add</th>
                    <th className='text-center'>Edit</th>
                    <th className='text-center'>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {values?.pageRoleData?.map((data, index) => (
                    <tr key={Math.floor(Math.random() * 1000000)}>
                      <td>
                        {data?.pageRoleId ? data?.pageRoleName : data?.name}
                      </td>

                      <td className='text-center'>
                        <IpCheckbox
                          checkboxid={`${data?.pageRoleId}${Math.floor(
                            Math.random() * 100000
                          )}`}
                          value='read'
                          checked={
                            data?.read &&
                            data?.add &&
                            data?.delete &&
                            data?.update
                              ? true
                              : false
                          }
                          changeListener={(e) => {
                            let tempData = data?.pageRoleId
                              ? {
                                  ...data,
                                  read: e?.checked,
                                  add: e?.checked,
                                  delete: e?.checked,
                                  update: e?.checked,
                                  selectAll: e?.checked
                                }
                              : {
                                  pageRoleId: data?.id,
                                  roleTypeId: values?.roleTypeId,
                                  pageRoleName: data?.name,
                                  read: e?.checked,
                                  add: e?.checked,
                                  delete: e?.checked,
                                  udpate: e?.checked,
                                  selectAll: e?.checked
                                }
                            changeRight(
                              tempData,
                              setFieldValue,
                              values?.pageRoleData
                            )
                          }}
                        />
                      </td>

                      <td className='text-center'>
                        <IpCheckbox
                          checkboxid={`${data?.pageRoleId}${Math.floor(
                            Math.random() * 100000
                          )}`}
                          value='read'
                          checked={data?.read ? true : false}
                          changeListener={(e) => {
                            if (data?.add || data?.update || data?.delete) {
                              if (!e?.checked) {
                                Swal.fire({
                                  title: 'Cannot Remove View Permission!',
                                  text: `Cannot Remove View Permission: Other permissions ${
                                    data?.add ? 'Create' : ''
                                  } ${data?.update ? 'Update' : ''} ${
                                    data?.delete ? 'Delete' : ''
                                  } already granted.`,
                                  icon: _SwalDelete.icon,
                                  showCancelButton:
                                    _SwalDelete.showCancelButton,
                                  confirmButtonColor:
                                    _SwalDelete.confirmButtonColor,
                                  cancelButtonColor:
                                    _SwalDelete.cancelButtonColor,
                                  confirmButtonText: 'Yes',
                                  cancelButtonText: _SwalDelete.cancelButtonText
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    let tempData = data?.pageRoleId
                                      ? {
                                          ...data,
                                          read: e?.checked,
                                          add: false,
                                          delete: false,
                                          update: false
                                        }
                                      : {
                                          pageRoleId: data?.id,
                                          roleTypeId: values?.roleTypeId,
                                          pageRoleName: data?.name,
                                          read: e?.checked,
                                          add: false,
                                          delete: false,
                                          udpate: false
                                        }
                                    changeRight(
                                      tempData,
                                      setFieldValue,
                                      values?.pageRoleData
                                    )
                                  } else if (result.isDenied) {
                                  }
                                })
                              } else {
                                let tempData = data?.pageRoleId
                                  ? {
                                      ...data,
                                      read: e?.checked
                                    }
                                  : {
                                      pageRoleId: data?.id,
                                      roleTypeId: values?.roleTypeId,
                                      pageRoleName: data?.name,
                                      read: e?.checked,
                                      add: false,
                                      delete: false,
                                      udpate: false
                                    }
                                changeRight(
                                  tempData,
                                  setFieldValue,
                                  values?.pageRoleData
                                )
                              }
                            } else {
                              let tempData = data?.pageRoleId
                                ? {
                                    ...data,
                                    read: e?.checked
                                  }
                                : {
                                    pageRoleId: data?.id,
                                    roleTypeId: values?.roleTypeId,
                                    pageRoleName: data?.name,
                                    read: e?.checked,
                                    add: false,
                                    delete: false,
                                    udpate: false
                                  }
                              changeRight(
                                tempData,
                                setFieldValue,
                                values?.pageRoleData
                              )
                            }
                          }}
                        />
                      </td>

                      <td className='text-center'>
                        <IpCheckbox
                          checkboxid={`${data?.pageRoleId}${Math.floor(
                            Math.random() * 100000
                          )}`}
                          value='add'
                          checked={data?.add ? true : false}
                          changeListener={(e) => {
                            let tempData = data?.pageRoleId
                              ? {
                                  ...data,
                                  add: e?.checked,
                                  read: data?.read ? data?.read : e?.checked
                                }
                              : {
                                  pageRoleId: data?.id,
                                  roleTypeId: values?.roleTypeId,
                                  add: e?.checked,
                                  read: e?.checked,
                                  delete: false,
                                  udpate: false
                                }
                            changeRight(
                              tempData,
                              setFieldValue,
                              values?.pageRoleData
                            )
                          }}
                        />
                      </td>

                      <td className='text-center'>
                        <IpCheckbox
                          checkboxid={`${data?.pageRoleId}${Math.floor(
                            Math.random() * 100000
                          )}`}
                          value='update'
                          checked={data?.update ? true : false}
                          changeListener={(e) => {
                            let tempData = data?.pageRoleId
                              ? {
                                  ...data,
                                  update: e?.checked,
                                  read: data?.read ? data?.read : e?.checked
                                }
                              : {
                                  pageRoleId: data?.id,
                                  roleTypeId: values?.roleTypeId,
                                  update: e?.checked,
                                  add: false,
                                  delete: false,
                                  read: e?.checked
                                }
                            changeRight(
                              tempData,
                              setFieldValue,
                              values?.pageRoleData
                            )
                          }}
                        />
                      </td>

                      <td className='text-center'>
                        <IpCheckbox
                          checkboxid={`${data?.pageRoleId}${Math.floor(
                            Math.random() * 100000
                          )}`}
                          checked={data?.delete ? true : false}
                          value='delete'
                          changeListener={(e) => {
                            let tempData = data?.pageRoleId
                              ? {
                                  ...data,
                                  delete: e?.checked,
                                  read: data?.read ? data?.read : e?.checked
                                }
                              : {
                                  pageRoleId: data?.id,
                                  roleTypeId: values?.roleTypeId,
                                  delete: e?.checked,
                                  add: false,
                                  read: e?.checked,
                                  udpate: false
                                }
                            changeRight(
                              tempData,
                              setFieldValue,
                              values?.pageRoleData
                            )
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </>
        )}
      </Formik>

      {toast?.show && (
        <CustomToast text={toast?.text} variation={toast?.variation} />
      )}

      {loading && <Loader />}
    </>
  )
}

export default UserWise
