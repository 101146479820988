import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import SizeType from './SizeType'
import SizeValue from './SizeValue'

const MainSize = () => {
  const [activeToggle, setActiveToggle] = useState('sizevalue')

  return (
    <>
      <div className='card overflow-hidden'>
        <div className='card-body p-0'>
          {/* Tabs Links */}
          <div className='nav-tabs-horizontal nav nav-tabs'>
            <Link
              onClick={() => setActiveToggle('sizevalue')}
              data-toggle='tab'
              className={`nav-link fw-semibold ${
                activeToggle === 'sizevalue' ? 'active show' : ''
              }`}
            >
              <span className='nav-span'>
                Value
                <hr />
              </span>
            </Link>
            <Link
              onClick={() => setActiveToggle('sizetype')}
              // to="#secondcategory"
              data-toggle='tab'
              className={`nav-link fw-semibold ${
                activeToggle === 'sizetype' ? 'active show' : ''
              }`}
            >
              <span className='nav-span'>
                Type
                <hr />
              </span>
            </Link>
          </div>
          {/* Tabs Links End */}

          {/* Content Section Start */}

          <div className='tab-content p-3'>
            {/* First Tab content Start */}
            <div
              id='sizevalue'
              className={`tab-pane fade ${
                activeToggle === 'sizevalue' ? 'active show' : ''
              }`}
            >
              {activeToggle === 'sizevalue' && <SizeValue />}
            </div>
            {/* First Tab content End */}

            {/* Second Tab content Start */}
            <div
              id='sizetype'
              className={`tab-pane fade ${
                activeToggle === 'sizetype' ? 'active show' : ''
              }`}
            >
              {activeToggle === 'sizetype' && <SizeType />}
            </div>
            {/* Second Tab content End */}
          </div>
        </div>
      </div>
    </>
  )
}

export default MainSize
