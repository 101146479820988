import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import MainCategory from './MainCategory'
import SubCategory from './SubCategory'
import { useDispatch, useSelector } from 'react-redux'
import { setPageTitle } from '../../redux/slice/pageTitleSlice'
import {
  allCrudNames,
  allPages,
  checkPageAccess
} from '../../../lib/AllPageNames'
import NotFound from '../../NotFound/NotFound'

const ManageCategory = () => {
  const [activeToggle, setActiveToggle] = useState('maincategory')
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setPageTitle('Manage Category'))
  }, [])

  // useEffect(() => {
  //   titleOfTab(activeToggle)
  // }, [activeToggle]);

  const { pageAccess } = useSelector((state) => state.user)

  return checkPageAccess(pageAccess, allPages?.category, allCrudNames?.read) ? (
    <>
      <div className='card overflow-hidden'>
        <div className='card-body p-0'>
          {/* Tabs Links */}
          <div className='nav-tabs-horizontal nav nav-tabs'>
            <Link
              onClick={() => setActiveToggle('maincategory')}
              data-toggle='tab'
              className={`nav-link fw-semibold ${activeToggle === 'maincategory' ? 'active show' : ''
                }`}
            >
              <span className='nav-span'>
                Main Category
                <hr />
              </span>
            </Link>
            <Link
              onClick={() => setActiveToggle('subcategory')}
              // to="#secondcategory"
              data-toggle='tab'
              className={`nav-link fw-semibold ${activeToggle === 'subcategory' ? 'active show' : ''
                }`}
            >
              <span className='nav-span'>
                Sub Category
                <hr />
              </span>
            </Link>
          </div>
          {/* Tabs Links End */}

          {/* Content Section Start */}

          <div className='tab-content p-3'>
            {/* First Tab content Start */}
            <div
              id='maincategory'
              className={`tab-pane fade ${activeToggle === 'maincategory' ? 'active show' : ''
                }`}
            >
              {activeToggle === 'maincategory' && <MainCategory />}

              {/* Pagination End */}
            </div>
            {/* First Tab content End */}
            <div
              id='subcategory'
              className={`tab-pane fade ${activeToggle === 'subcategory' ? 'active show' : ''
                }`}
            >
              {/* Second Tab content Start */}

              {activeToggle === 'subcategory' && <SubCategory />}

              {/* Second Tab content End */}
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <NotFound />
  )
}

export default ManageCategory
