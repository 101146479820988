import React from 'react'
import {
  _homePageImg_,
  _lendingPageImg_,
  _productImg_
} from '../../lib/ImagePath'
import Swal from 'sweetalert2'
import { _SwalDelete } from '../../lib/exceptionMessage'
import { Button, Card, Col, Row } from 'react-bootstrap'
import ImgEditComponet from '../ManageHomePage/ImgEditComponet'

const DoubleImageContainer = ({
  column,
  data,
  type,
  layoutsInfo,
  section,
  handleDelete,
  layoutDetails,
  setLayoutDetails,
  setModalShow,
  modalShow,
  fromLendingPage,
  handleImgDelete
}) => {
  const isRenderForProduct = section?.list_type
    ?.toLowerCase()
    ?.includes('product')
    ? true
    : false

  const deleteImage = (id) => {
    Swal.fire({
      title: _SwalDelete.title,
      text: _SwalDelete.text,
      icon: _SwalDelete.icon,
      showCancelButton: _SwalDelete.showCancelButton,
      confirmButtonColor: _SwalDelete.confirmButtonColor,
      cancelButtonColor: _SwalDelete.cancelButtonColor,
      confirmButtonText: _SwalDelete.confirmButtonText,
      cancelButtonText: _SwalDelete.cancelButtonText
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(id, isRenderForProduct ? true : false)
      } else if (result.isDenied) {
      }
    })
  }

  const sectionDelete = (id, specificImgDelete = false) => {
    Swal.fire({
      title: _SwalDelete.title,
      text: _SwalDelete.text,
      icon: _SwalDelete.icon,
      showCancelButton: _SwalDelete.showCancelButton,
      confirmButtonColor: _SwalDelete.confirmButtonColor,
      cancelButtonColor: _SwalDelete.cancelButtonColor,
      confirmButtonText: _SwalDelete.confirmButtonText,
      cancelButtonText: _SwalDelete.cancelButtonText
    }).then((result) => {
      if (result.isConfirmed) {
        if (specificImgDelete) {
          let endpoint = fromLendingPage
            ? `LendingPageSectionDetails?id=${id}`
            : `ManageHomePageDetails?id=${id}`
          handleImgDelete(endpoint)
        } else {
          handleDelete(section?.section_id)
        }
      } else if (result.isDenied) {
      }
    })
  }

  const minImagesLength = isRenderForProduct
    ? Number(layoutsInfo?.layout_min_images)
    : layoutsInfo?.layout_type_name?.toLowerCase()?.includes('column4')
    ? layoutsInfo?.layout_min_images
    : 2
  const maxImagesLength = isRenderForProduct
    ? Number(layoutsInfo?.layout_max_images)
    : layoutsInfo?.layout_type_name?.toLowerCase()?.includes('column4')
    ? layoutsInfo?.layout_max_images
    : 2

  const renderProduct = (obj) => {
    return (
      <Card
        className='pv-widgetinner-col'
        key={Math.floor(Math.random() * 1000000)}
        onContextMenu={() => {
          deleteImage(section?.section_id)
        }}
        onClick={() => {
          if (isRenderForProduct) {
            setModalShow({
              ...modalShow,
              show: !modalShow?.show,
              layoutId: layoutsInfo?.layout_id,
              layoutName: layoutsInfo?.layout_name,
              sectionId: section.section_id,
              layoutTypeId: layoutsInfo?.layout_type_id,
              layoutTypeName: layoutsInfo?.layout_type_name
            })
          } else {
            let layoutTypeDetailsId = layoutsInfo?.layout_details?.find(
              (obj) =>
                obj?.section_type?.toLowerCase() === type &&
                obj?.layout_type_detail_name?.toLowerCase() === column
            )?.layout_type_detail_id
            setLayoutDetails({
              show: !layoutDetails?.show,
              sectionId: section?.section_id,
              minImagesLength,
              maxImagesLength,
              layoutTypeDetailsId
            })
          }
        }}
      >
        <Card.Img
          variant='top'
          className='p-3'
          src={`${process.env.REACT_APP_IMG_URL}${_productImg_}${obj?.image1}`}
        />
        <div className='position-relative'>
          <Row className='pv-widhet-hoverbtn m-0'>
            <Col>
              <Button className='pv-product-btn pv-btn-whishlist'>
                Wishlist
              </Button>
            </Col>
            {layoutsInfo?.layout_class?.toLowerCase() === 'with-price' && (
              <Col>
                <Button className='pv-product-btn pv-btn-add-cart'>
                  Add to cart
                </Button>
              </Col>
            )}
          </Row>
        </div>
        <Card.Body>
          <div className='card_details'>
            <Card.Title className='bold'>{obj.brandName}</Card.Title>
            <Card.Text className='mb-0'>{obj.productName}</Card.Text>
            {/* <Card.Text className='mb-0'>Sizes: XS</Card.Text> */}
            {/* {layoutsInfo?.layout_class?.toLowerCase() !== 'without-price' && (
              <Card.Text className='d-flex align-items-center gap-2 mb-0'>
                {' '}
                <strong>Rs. {obj.sellingPrice}</strong>{' '}
                <span>
                  <del>Rs.{obj.mrp}</del>
                </span>
                <small style={{ color: '#BC405D' }}>
                  ({obj?.discount}% OFF)
                </small>{' '}
              </Card.Text>
            )} */}
          </div>
        </Card.Body>
      </Card>
    )
  }

  const renderComponent = (card, imgPath) => {
    return (
      <div
        key={card?.id ? card?.id : Math.floor(Math.random() * 100000)}
        className='pv-img-grid-odd-even position-relative pv-globle-imgedit-btn'
      >
        {/* <a href='#'> */}
        <img
          style={{ objectFit: 'cover' }}
          className='w-100 h-100'
          src={
            card?.image || card?.image1
              ? `${process.env.REACT_APP_IMG_URL}${
                  card?.image1
                    ? _productImg_
                    : fromLendingPage
                    ? _lendingPageImg_
                    : _homePageImg_
                }${card?.image1 ? card?.image1 : card?.image}`
              : imgPath
          }
        />
        <ImgEditComponet
          sectionDelete={() => {
            sectionDelete(card?.section_details_id, true)
          }}
          showDeleteIcon={imgPath ? false : true}
          sectionEdit={() => {
            if (isRenderForProduct) {
              setModalShow({
                ...modalShow,
                show: !modalShow?.show,
                layoutId: layoutsInfo?.layout_id,
                layoutName: layoutsInfo?.layout_name,
                sectionId: section.section_id,
                layoutTypeId: layoutsInfo?.layout_type_id,
                layoutTypeName: layoutsInfo?.layout_type_name
              })
            } else {
              let layoutTypeDetailsId = layoutsInfo?.layout_details?.find(
                (obj) =>
                  obj?.section_type?.toLowerCase() === type &&
                  obj?.layout_type_detail_name?.toLowerCase() === column
              )?.layout_type_detail_id
              setLayoutDetails({
                show: !layoutDetails?.show,
                sectionId: section?.section_id,
                minImagesLength,
                maxImagesLength,
                layoutTypeDetailsId,
                sectionDetailsId: card?.section_details_id
              })
            }
          }}
        />
        {/* </a> */}
      </div>
    )
  }
  return (
    <>
      {data
        ?.map((obj) =>
          isRenderForProduct ? renderProduct(obj) : renderComponent(obj)
        )
        .concat(
          !isRenderForProduct && data?.length < minImagesLength
            ? Array(minImagesLength - data?.length || 0)
                .fill(null)
                .map((_, index) =>
                  renderComponent(null, 'https://placehold.jp/300x300.png')
                )
            : []
        )}
    </>
  )
}

export default DoubleImageContainer
