import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { setPageTitle } from '../../redux/slice/pageTitleSlice'
import { useDispatch, useSelector } from 'react-redux'
import {
  allCrudNames,
  allPages,
  checkPageAccess
} from '../../../lib/AllPageNames'
import NotFound from '../../NotFound/NotFound'
import DownloadInfoReport from './DownloadInfoReport'
import RequestInfoReport from './RequestInfoReport'

const ManageReport = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setPageTitle('Reports'))
  }, [])

  const { pageAccess } = useSelector((state) => state.user)

  const [activeToggle, setActiveToggle] = useState('requst_info')

  return checkPageAccess(pageAccess, allPages?.report, allCrudNames?.read) ? (
    <>
      <div className='card overflow-hidden'>
        <div className='card-body p-0'>
          <div className='nav-tabs-horizontal nav nav-tabs'>
            {checkPageAccess(
              pageAccess,
              allPages?.report,
              allCrudNames?.read
            ) && (
              <Link
                onClick={() => setActiveToggle('requst_info')}
                data-toggle='tab'
                className={`nav-link fw-semibold ${
                  activeToggle === 'requst_info' ? 'active show' : ''
                }`}
              >
                <span className='nav-span'>
                  Request Information
                  <hr />
                </span>
              </Link>
            )}
            {checkPageAccess(
              pageAccess,
              allPages?.report,
              allCrudNames?.read
            ) && (
              <Link
                onClick={() => setActiveToggle('download_info')}
                data-toggle='tab'
                className={`nav-link fw-semibold ${
                  activeToggle === 'download_info' ? 'active show' : ''
                }`}
              >
                <span className='nav-span'>
                  Download Information
                  <hr />
                </span>
              </Link>
            )}
          </div>

          <div className='tab-content p-3'>
            {checkPageAccess(
              pageAccess,
              allPages?.report,
              allCrudNames?.read
            ) && (
              <div
                id='brand'
                className={`tab-pane fade ${
                  activeToggle === 'requst_info' ? 'active show' : ''
                }`}
              >
                {activeToggle === 'requst_info' && <RequestInfoReport />}
              </div>
            )}
            {checkPageAccess(
              pageAccess,
              allPages?.report,
              allCrudNames?.read
            ) && (
              <div
                id='bimcadDownload'
                className={`tab-pane fade ${
                  activeToggle === 'download_info' ? 'active show' : ''
                }`}
              >
                {activeToggle === 'download_info' && <DownloadInfoReport />}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  ) : (
    <NotFound />
  )
}

export default ManageReport
