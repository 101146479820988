import React, { useEffect, useState } from 'react'
import {
  Navbar,
  Container,
  Dropdown,
  Offcanvas,
  Row,
  Col
} from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { logout, setUserInfo } from '../pages/redux/slice/userSlice'
import axiosProvider from '../lib/AxiosProvider'
import { _exception } from '../lib/exceptionMessage'
import Loader from './Loader'
import ReactPaginate from 'react-paginate'
import { Form, Formik } from 'formik'
import { handleLogout } from '../lib/HandleLogout'
import { _userProfileImg_ } from '../lib/ImagePath'
import { pageRangeDisplayed } from '../lib/GetBaseUrl'
import { useImmer } from 'use-immer'

const openCloseSidebar = () => {
  document.querySelector('.app').classList.toggle('sidenav-toggled')
}

const Header = ({ pageTitle }) => {
  const navigate = useNavigate()
  const { user } = useSelector((state) => state)
  const dispatch = useDispatch()
  const [showNotification, setShowNotification] = useState(false)
  const [data, setData] = useState()
  const [loading, setLoading] = useState(false)
  const [toast, setToast] = useState({
    show: false,
    text: null,
    variation: null
  })
  const [filterDetails, setFilterDetails] = useImmer({
    pageIndex: 1,
    pageSize: 10,
    notificationsOf: ''
  })
  const { userInfo } = useSelector((state) => state?.user)

  const fetchData = async (
    endpoint = `Notification/byFilter`,
    queryString = `?ReceiverId=${userInfo?.userId}&notificationsOf=${filterDetails?.notificationsOf}&IsRead=false&pageindex=${filterDetails?.pageIndex}&PageSize=${filterDetails?.pageSize}`
  ) => {
    setLoading(true)
    const response = await axiosProvider({
      method: 'GET',
      endpoint,
      queryString
    })
      .then((res) => {
        if (res.status === 200) {
          setData(res)
        }
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        setToast({
          show: true,
          text: _exception.message,
          variation: _exception.variation
        })

        setTimeout(() => {
          setToast({ ...toast, show: false })
        }, 2000)
      })
  }

  useEffect(() => {
    fetchData()
  }, [filterDetails])

  const formatTime = (givenTime) => {
    const currentTime = new Date()
    const givenTimeObj = new Date(givenTime)
    const timeDifference = Math.floor((currentTime - givenTimeObj) / 1000) // Time difference in seconds

    if (timeDifference < 60) {
      return `Last updated ${timeDifference} seconds ago`
    } else if (timeDifference < 3600) {
      const minutes = Math.floor(timeDifference / 60)
      return `Last updated ${minutes} minute${minutes !== 1 ? 's' : ''} ago`
    } else if (timeDifference < 86400) {
      const hours = Math.floor(timeDifference / 3600)
      return `Last updated ${hours} hour${hours !== 1 ? 's' : ''} ago`
    } else {
      const days = Math.floor(timeDifference / 86400)
      return `Last updated ${days} day${days !== 1 ? 's' : ''} ago`
    }
  }

  return (
    <>
      <Navbar
        expand='md'
        className='app-header header sticky bg-white shadow-sm'
      >
        <Container fluid className='main-container'>
          <div className='d-flex align-items-center w-100 gap-2'>
            <Link
              aria-label='Hide Sidebar'
              className='app-sidebar__toggle d-inline-flex p-2 rounded-circle shadow btn btn-outline-light'
              to='#'
              onClick={() => openCloseSidebar()}
            >
              <i className='m-icon m-icon--hamburger' />
            </Link>
            <Link
              href='#'
              className='text-decoration-none text-black fs-5 d-flex align-items-center gap-1 fw-semibold text-capitalize'
            >
              {!pageTitle?.toLowerCase()?.includes('dashboard') && (
                <i
                  className='m-icon m-icon--arrow_back'
                  onClick={() => {
                    navigate(-1)
                  }}
                />
              )}
              {pageTitle || 'Admin'}
            </Link>

            <div className='ms-auto d-flex align-items-center gap-5'>
              <div>
                <span
                  type='button'
                  className='pv-notification-main position-relative px-2 pb-1 mt-2'
                  onClick={() => {
                    fetchData()
                    setShowNotification(true)
                  }}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='#000'
                    width='22px'
                    height='22px'
                    viewBox='0 0 16 16'
                  >
                    <path d='m13.58 11.6-1.33-2.18V6.33A4.36 4.36 0 0 0 10 2.26a2.45 2.45 0 0 0 0-.38A1.94 1.94 0 0 0 8 0a1.94 1.94 0 0 0-2 1.88 1.64 1.64 0 0 0 0 .38 4.36 4.36 0 0 0-2.25 4.07v3.09L2.42 11.6a1.25 1.25 0 0 0 1.06 1.9h1.77A2.68 2.68 0 0 0 8 16a2.68 2.68 0 0 0 2.75-2.5h1.77a1.25 1.25 0 0 0 1.06-1.9zM7.25 1.88A.7.7 0 0 1 8 1.25a.7.7 0 0 1 .75.63 6 6 0 0 0-.75 0 5.9 5.9 0 0 0-.75 0zM8 14.75a1.44 1.44 0 0 1-1.5-1.25h3A1.44 1.44 0 0 1 8 14.75zm-4.52-2.5 1.34-2.17.18-.31V6.33a4 4 0 0 1 .6-2.12A2.68 2.68 0 0 1 8 3.12a2.68 2.68 0 0 1 2.4 1.09 4 4 0 0 1 .6 2.12v3.44l.18.31 1.34 2.17z' />
                  </svg>
                  <span className='pv-notification-badge position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger'>
                    {data?.data?.data?.[0]?.unreadCount > 99
                      ? '99+'
                      : data?.data?.data?.[0]?.unreadCount
                      ? data?.data?.data?.[0]?.unreadCount
                      : 0}
                    <span className='visually-hidden'>unread messages</span>
                  </span>
                </span>
                <Offcanvas
                  placement='end'
                  show={showNotification}
                  backdrop={'static'}
                  onHide={() => setShowNotification(false)}
                >
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title className='bold'>
                      Recent Notifications
                    </Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <Row className='align-items-center'>
                      <Col>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant='Secondary'
                            id='dropdown-basic'
                            className='border border-2'
                          >
                            {filterDetails?.notificationsOf
                              ? filterDetails?.notificationsOf
                              : 'All'}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => {
                                setFilterDetails((draft) => {
                                  draft.notificationsOf = 'Seller'
                                })
                              }}
                            >
                              Seller
                            </Dropdown.Item>
                            {/* <Dropdown.Item
                                    onClick={() => {
                                      fetchData(
                                        "Notification/byFilter",
                                        `?ReceiverId=${userInfo?.userId}&notificationsof=KYC&IsRead=false&pageindex=${onClickPage}&PageSize=${pageEntries}`
                                      );
                                      setFieldValue("filter", "KYC");
                                    }}
                                  >
                                    KYC
                                  </Dropdown.Item> */}
                            <Dropdown.Item
                              onClick={() => {
                                setFilterDetails((draft) => {
                                  draft.notificationsOf = 'Product'
                                })
                              }}
                            >
                              Product
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Col>
                      <Col>
                        <span
                          className='text-nowrap border p-2 rounded'
                          role='button'
                          onClick={() => {
                            const reponse = axiosProvider({
                              method: 'PUT',
                              endpoint: 'Notification/markAllRead',
                              data: { receiverId: userInfo?.userId }
                            }).then((res) => {
                              if (res?.status === 200) {
                                fetchData()
                              }
                            })
                          }}
                        >
                          Mark All as Read
                        </span>
                      </Col>
                      <Col className='text-end'>
                        <span
                          className='border p-2 rounded'
                          role='button'
                          onClick={() => {
                            setShowNotification(false)
                            navigate('/notification')
                          }}
                        >
                          See All
                        </span>
                      </Col>
                    </Row>

                    {loading && <Loader />}
                    <Row className='mt-3 gy-3'>
                      {data?.data?.data?.length > 0 &&
                        data?.data?.data?.map((data) => (
                          <Col
                            md={12}
                            className='border-bottom'
                            onClick={() => {
                              setShowNotification(false)
                              navigate(data?.url)
                              const reponse = axiosProvider({
                                method: 'PUT',
                                endpoint: 'Notification',
                                data: { id: data?.id }
                              }).then((res) => {
                                if (res?.status === 200) {
                                  fetchData()
                                }
                              })
                            }}
                            key={Math.floor(Math.random() * 100000)}
                          >
                            <div className='card mb-0 p-2 pv-notification-col'>
                              {/* <div className='col-md-4'>
                                  <img
                                    style={{
                                      objectFit: 'cover',
                                      maxWidth: '100px'
                                    }}
                                    src='https://api.hashkart.hashtechy.space/Resources/ProductImage/Evok%20Tawas_1__08-07-2023_12_24_23_701.jpg'
                                    className='img-fluid rounded h-100'
                                    alt='...'
                                  />
                                </div> */}
                              <div className='col-md-12'>
                                <div className='card-body p-0 cp'>
                                  <h5 className='card-title mb-1 cfz-17'>
                                    {data?.notificationTitle}
                                  </h5>
                                  <p className='card-text mb-1 lh-1 cfz-15'>
                                    {data?.notificationDescription}
                                  </p>
                                  <p className='card-text'>
                                    <small className='text-muted cfz-13'>
                                      {formatTime(data?.updatedAt)}
                                    </small>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </Col>
                        ))}
                      <ReactPaginate
                        className='list-inline m-cst--pagination d-flex justify-content-end gap-1'
                        breakLabel='...'
                        nextLabel=''
                        onPageChange={(e) => {
                          setFilterDetails((draft) => {
                            draft.pageIndex = e?.selected + 1
                          })
                        }}
                        pageRangeDisplayed={'3'}
                        pageCount={data?.data?.pagination?.pageCount ?? 0}
                        previousLabel=''
                        renderOnZeroPageCount={null}
                        marginPagesDisplayed={1}
                      />
                    </Row>
                  </Offcanvas.Body>
                </Offcanvas>
              </div>
              <Dropdown className='profile_header'>
                <Dropdown.Toggle
                  variant='transparent'
                  id='dropdown-custom-components'
                  className='nav-link profile leading-none d-flex align-items-center gap-2 dropdown_arrow_hide border-0'
                  aria-haspopup='false'
                  aria-expanded='false'
                >
                  <div>
                    <span className='d-block text-muted fs-6 text-end'>
                      Hello
                    </span>
                    <span className='d-block fs-6 fw-semibold'>
                      {user?.userInfo?.fullName}
                    </span>
                  </div>
                  <img
                    height={'50px'}
                    width={'50px'}
                    src={
                      user?.userInfo?.profileImage
                        ? `${process.env.REACT_APP_IMG_URL}${_userProfileImg_}${user?.userInfo?.profileImage}`
                        : 'https://placehold.jp/45x45.png'
                    }
                    alt=''
                    className='rounded-circle'
                  />
                </Dropdown.Toggle>

                <Dropdown.Menu className='dropdown-menu-lg-end p-2'>
                  <Dropdown.Item
                    eventKey='2'
                    className='rounded-1 d-flex align-items-center gap-2'
                    onClick={() => {
                      navigate('/edit-profile')
                    }}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='20px'
                      height='20px'
                      viewBox='0 0 24 24'
                      fill='none'
                    >
                      <circle
                        cx='12'
                        cy='6'
                        r='4'
                        stroke='#1C274C'
                        strokeWidth='1.5'
                      />
                      <path
                        d='M15 13.3271C14.0736 13.1162 13.0609 13 12 13C7.58172 13 4 15.0147 4 17.5C4 19.9853 4 22 12 22C17.6874 22 19.3315 20.9817 19.8068 19.5'
                        stroke='#1C274C'
                        strokeWidth='1.5'
                      />
                      <circle
                        cx='18'
                        cy='16'
                        r='4'
                        stroke='#1C274C'
                        strokeWidth='1.5'
                      />
                      <path
                        d='M18 14.6667V17.3333'
                        stroke='#1C274C'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M16.6665 16L19.3332 16'
                        stroke='#1C274C'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                    <span> Edit Profile</span>
                  </Dropdown.Item>

                  <Dropdown.Item
                    eventKey='3'
                    className='rounded-1 d-flex align-items-center gap-2'
                    onClick={() => {
                      navigate('/settings/setting/change-password')
                    }}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='20px'
                      height='20px'
                      viewBox='0 0 24 24'
                      fill='none'
                    >
                      <path
                        d='M9 16C9 16.5523 8.55229 17 8 17C7.44772 17 7 16.5523 7 16C7 15.4477 7.44772 15 8 15C8.55229 15 9 15.4477 9 16Z'
                        fill='#1C274C'
                      />
                      <path
                        d='M13 16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16C11 15.4477 11.4477 15 12 15C12.5523 15 13 15.4477 13 16Z'
                        fill='#1C274C'
                      />
                      <path
                        d='M16 17C16.5523 17 17 16.5523 17 16C17 15.4477 16.5523 15 16 15C15.4477 15 15 15.4477 15 16C15 16.5523 15.4477 17 16 17Z'
                        fill='#1C274C'
                      />
                      <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M5.25 8V9.30277C5.02317 9.31872 4.80938 9.33948 4.60825 9.36652C3.70814 9.48754 2.95027 9.74643 2.34835 10.3483C1.74643 10.9503 1.48754 11.7081 1.36652 12.6082C1.24996 13.4752 1.24998 14.5775 1.25 15.9451V16.0549C1.24998 17.4225 1.24996 18.5248 1.36652 19.3918C1.48754 20.2919 1.74643 21.0497 2.34835 21.6516C2.95027 22.2536 3.70814 22.5125 4.60825 22.6335C5.47522 22.75 6.57754 22.75 7.94513 22.75H16.0549C17.4225 22.75 18.5248 22.75 19.3918 22.6335C20.2919 22.5125 21.0497 22.2536 21.6517 21.6516C22.2536 21.0497 22.5125 20.2919 22.6335 19.3918C22.75 18.5248 22.75 17.4225 22.75 16.0549V15.9451C22.75 14.5775 22.75 13.4752 22.6335 12.6082C22.5125 11.7081 22.2536 10.9503 21.6517 10.3483C21.0497 9.74643 20.2919 9.48754 19.3918 9.36652C19.1906 9.33948 18.9768 9.31872 18.75 9.30277V8C18.75 4.27208 15.7279 1.25 12 1.25C8.27208 1.25 5.25 4.27208 5.25 8ZM12 2.75C9.10051 2.75 6.75 5.10051 6.75 8V9.25344C7.12349 9.24999 7.52152 9.24999 7.94499 9.25H16.0549C16.4783 9.24999 16.8765 9.24999 17.25 9.25344V8C17.25 5.10051 14.8995 2.75 12 2.75ZM4.80812 10.8531C4.07435 10.9518 3.68577 11.1322 3.40901 11.409C3.13225 11.6858 2.9518 12.0743 2.85315 12.8081C2.75159 13.5635 2.75 14.5646 2.75 16C2.75 17.4354 2.75159 18.4365 2.85315 19.1919C2.9518 19.9257 3.13225 20.3142 3.40901 20.591C3.68577 20.8678 4.07435 21.0482 4.80812 21.1469C5.56347 21.2484 6.56459 21.25 8 21.25H16C17.4354 21.25 18.4365 21.2484 19.1919 21.1469C19.9257 21.0482 20.3142 20.8678 20.591 20.591C20.8678 20.3142 21.0482 19.9257 21.1469 19.1919C21.2484 18.4365 21.25 17.4354 21.25 16C21.25 14.5646 21.2484 13.5635 21.1469 12.8081C21.0482 12.0743 20.8678 11.6858 20.591 11.409C20.3142 11.1322 19.9257 10.9518 19.1919 10.8531C18.4365 10.7516 17.4354 10.75 16 10.75H8C6.56459 10.75 5.56347 10.7516 4.80812 10.8531Z'
                        fill='#1C274C'
                      />
                    </svg>
                    <span> Change Password</span>
                  </Dropdown.Item>

                  <Dropdown.Item
                    eventKey='1'
                    className='rounded-1 d-flex align-items-center gap-2'
                    onClick={() => {
                      handleLogout(true)
                      // dispatch(logout())
                    }}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='20px'
                      height='20px'
                      viewBox='0 0 24 24'
                      fill='none'
                    >
                      <path
                        d='M15 12L6 12M6 12L8 14M6 12L8 10'
                        stroke='#1C274C'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M12 21.9827C10.4465 21.9359 9.51995 21.7626 8.87865 21.1213C8.11027 20.3529 8.01382 19.175 8.00171 17M16 21.9983C18.175 21.9862 19.3529 21.8897 20.1213 21.1213C21 20.2426 21 18.8284 21 16V14V10V8C21 5.17157 21 3.75736 20.1213 2.87868C19.2426 2 17.8284 2 15 2H14C11.1715 2 9.75733 2 8.87865 2.87868C8.11027 3.64706 8.01382 4.82497 8.00171 7'
                        stroke='#1C274C'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                      />
                      <path
                        d='M3 9.5V14.5C3 16.857 3 18.0355 3.73223 18.7678C4.46447 19.5 5.64298 19.5 8 19.5M3.73223 5.23223C4.46447 4.5 5.64298 4.5 8 4.5'
                        stroke='#1C274C'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                      />
                    </svg>{' '}
                    <span> Log Out</span>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </Container>
      </Navbar>
    </>
  )
}

export default Header
