import React, { useState, useEffect, useRef } from 'react'
import { Button, Col, Row, Table } from 'react-bootstrap'
import SearchBox from '../../../../components/Searchbox'
import TooltipComponent from '../../../../components/Tooltip'
import ReactPaginate from 'react-paginate'
import ModelComponent from '../../../../components/Modal'
import { Formik, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import axiosProvider from '../../../../lib/AxiosProvider'
import FormikControl from '../../../../components/FormikControl'
import useDebounce from '../../../../lib/useDebounce'
import Loader from '../../../../components/Loader'
import CustomToast from '../../../../components/Toast/CustomToast'
import Select from 'react-select'
import DeleteIcon from '../../../../components/AllSvgIcon/DeleteIcon'
import Swal from 'sweetalert2'
import { _SwalDelete } from '../../../../lib/exceptionMessage'
import EditIcon from '../../../../components/AllSvgIcon/EditIcon'
import { useSelector } from 'react-redux'
import {
  allCrudNames,
  allPages,
  checkPageAccess
} from '../../../../lib/AllPageNames'
import { useImmer } from 'use-immer'
import { pageRangeDisplayed } from '../../../../lib/GetBaseUrl'
import { customStyles } from '../../../../components/customStyles'
import TextError from '../../../../components/TextError'
import { useLocation } from 'react-router-dom'

const SpeciTypeValue = () => {
  const [searchText, setSearchText] = useState()
  const [data, setData] = useState()
  const [modalShow, setModalShow] = useState(false)
  const [dropDownData, setDropDownData] = useState()
  const [loading, setLoading] = useState(false)
  const initVal = {
    parentId: null,
    name: ''
  }
  const [initialValues, setInitialValues] = useState(initVal)
  const [toast, setToast] = useState({
    show: false,
    text: null,
    variation: null
  })
  const [filterDetails, setFilterDetails] = useImmer({
    pageSize: 50,
    pageIndex: 1,
    searchText: '',
    specificationTypeID: '',
    specificationTypeName: ''
  })

  const { pageAccess, userInfo } = useSelector((state) => state.user)
  const location = useLocation()
  const debounceSearchText = useDebounce(searchText, 500)

  useEffect(() => {
    if (debounceSearchText) {
      setFilterDetails((draft) => {
        draft.searchText = debounceSearchText
        draft.pageIndex = 1
      })
    } else {
      setFilterDetails((draft) => {
        draft.searchText = ''
        draft.pageIndex = 1
      })
    }
  }, [debounceSearchText])

  useEffect(() => {
    if (!modalShow) fetchData()
  }, [filterDetails])

  useEffect(() => {
    fetchDropDownData(`SpecificationType/GetSpecTypeDropdown`, '', (data) =>
      setDropDownData(data)
    )
  }, [])

  const fetchData = async () => {
    setLoading(true)
    const response = await axiosProvider({
      method: 'GET',
      endpoint: 'SpecificationTypeValue/search',
      queryString: `?searchText=${filterDetails?.searchText}&pageIndex=${filterDetails?.pageIndex}&pageSize=${filterDetails?.pageSize}&SpecificationTypeID=${filterDetails?.specificationTypeID}`
    })
      .then((res) => {
        if (res.status === 200) {
          setData(res)
        }
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
      })
  }

  const fetchDropDownData = async (
    endpoint,
    queryString = '?pageindex=0&PageSize=0',
    setterFunc,
    sizeId
  ) => {
    setLoading(true)
    const response = await axiosProvider({
      method: 'GET',
      endpoint,
      queryString
    })
      .then((res) => {
        setLoading(false)
        if (res.status === 200 && res?.data?.data) {
          return setterFunc(res?.data?.data)
        }
      })
      .catch((err) => {
        setLoading(false)
      })
  }

  const handlePageClick = (event) => {
    setFilterDetails((draft) => {
      draft.pageIndex = event.selected + 1
    })
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Please enter Specification Type'),
    parentId: Yup.string()
      .test(
        'nonull',
        'Please select Specification Type',
        (value) => value !== 'undefined'
      )
      .required('Please select Specification Type')
  })

  const onSubmit = async (values) => {
    setLoading(true)
    let method = values?.id ? 'PUT' : 'POST'
    let endpoint = values?.id
      ? 'SpecificationTypeValue/update'
      : 'SpecificationTypeValue/save'
    const createCategory = await axiosProvider({
      method,
      endpoint,
      data: values,
      location: location?.pathname,
      userId: userInfo?.userId,
      oldData: initialValues
    })
      .then((res) => {
        if (res.status === 200) {
          setModalShow(false)
          setInitialValues(initVal)
          fetchData()
        }
        setLoading(false)
        setToast({
          show: true,
          text: res?.data?.message,
          variation: res?.data?.code === 200 ? 'success' : 'error'
        })

        setTimeout(() => {
          setToast({ ...toast, show: false })
        }, 2000)
      })
      .catch((err) => {
        setLoading(false)
      })
  }

  const handleEditData = async (id) => {
    setLoading(true)
    const response = await axiosProvider({
      method: 'GET',
      endpoint: 'SpecificationTypeValue/getById',
      queryString: `?id=${id}`
    })
      .then((res) => {
        if (res.status === 200) {
          setInitialValues(res?.data?.data)
          setModalShow(true)
        }
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
      })
  }

  const handleDelete = async (id) => {
    setLoading(true)
    const response = await axiosProvider({
      method: 'DELETE',
      endpoint: 'SpecificationTypeValue/delete',
      queryString: `?id=${id}`,
      userId: userInfo?.userId,
      location: location.pathname
    })
      .then((res) => {
        if (res?.status === 200) {
          fetchData()
        }
        setToast({
          show: true,
          text: res?.data?.message,
          variation: res?.data?.code === 200 ? 'success' : 'error'
        })

        setTimeout(() => {
          setToast({ ...toast, show: false })
        }, 2000)
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
      })
  }

  return (
    <>
      <Row className='my-3 justify-content-between-space'>
        <Col md={9}>
          <Row className='align-items-center'>
            <Col md={4}>
              <SearchBox
                placeholderText='Search'
                searchclassnamewrapper={'searchbox-wrapper'}
                value={searchText}
                onChange={(e) => {
                  setSearchText(e?.target?.value)
                }}
              />
            </Col>
            <Col md={4}>
              <Select
              isClearable={true}
                id='parentId'
                name='parentId'
                styles={customStyles}
                menuPortalTarget={document.body}
                placeholder='Specification'
                value={
                  filterDetails?.specificationTypeID && {
                    label: filterDetails?.specificationTypeName,
                    value: filterDetails?.specificationTypeID
                  }
                }
                options={dropDownData?.map(({ id, name }) => ({
                  value: id,
                  label: name
                }))}
                onChange={(e) => {
                  setFilterDetails((draft) => {
                    draft.specificationTypeID = e?.value ?? ''
                    draft.specificationTypeName = e?.label ?? ''
                  })
                }}
              />
            </Col>
            <Col md={3}>
              <div className='d-flex align-items-center me-2'>
                <label className='me-1'>Show</label>
                <select
                  name='dataget'
                  className='form-select me-1'
                  value={filterDetails?.pageSize}
                  onChange={(e) => {
                    setFilterDetails((draft) => {
                      draft.pageSize = e?.target?.value
                      draft.pageIndex = 1
                    })
                  }}
                >
                  <option value='10'>10</option>
                  <option value='25'>25</option>
                  <option value='50'>50</option>
                  <option value='100'>100</option>
                  <option value='200'>200</option>
                  <option value='500'>500</option>
                </select>
              </div>
            </Col>
          </Row>
        </Col>
        <Col>
          {checkPageAccess(
            pageAccess,
            allPages?.manageSpecifications,
            allCrudNames?.write
          ) && (
            <Button
              variant='warning'
              className='d-flex align-items-center gap-2 fw-semibold btn btn-warning ms-auto btn btn-warning'
              onClick={() => {
                setModalShow(true)
              }}
            >
              <i className='m-icon m-icon--plusblack'></i>
              Create
            </Button>
          )}
        </Col>
      </Row>

      {toast?.show && (
        <CustomToast text={toast?.text} variation={toast?.variation} />
      )}

      <ModelComponent
        show={modalShow}
        modalsize={'md'}
        modalheaderclass={''}
        modeltitle={'Specification Type Value'}
        onHide={() => {
          setInitialValues(initVal)
          setModalShow(false)
        }}
        btnclosetext={''}
        closebtnvariant={''}
        backdrop={'static'}
        formbuttonid='typeSize'
        submitname={!initialValues?.id ? 'Create' : 'Update'}
      >
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ values, setFieldValue }) => (
            <Form id='typeSize'>
              <div className='row align-items-center'>
                <div className='col-md-12 mb-3'>
                  <div className='input-file-wrapper'>
                    <label className='form-label required'>
                      Specification Type
                    </label>
                    <Select
                      id='parentId'
                      name='parentId'
                      isDisabled={values?.id}
                      styles={customStyles}
                      menuPortalTarget={document.body}
                      placeholder='Specification'
                      value={
                        values?.parentId && {
                          label: values?.parentName,
                          value: values?.parentId
                        }
                      }
                      options={dropDownData?.map(({ id, name }) => ({
                        value: id,
                        label: name
                      }))}
                      onChange={(e) => {
                        setFieldValue('parentId', e?.value)
                        setFieldValue('parentName', e?.label)
                      }}
                    />
                    <ErrorMessage name='parentId' component={TextError} />
                  </div>
                </div>
                <div className='col-md-12'>
                  {loading && <Loader />}
                  <div className='input-wrapper mb-3'>
                    <FormikControl
                      control='input'
                      label='Name'
                      type='text'
                      name='name'
                      placeholder='Enter Specification Name'
                      isRequired
                    />
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </ModelComponent>

      {loading && !modalShow && <Loader />}

      <Table responsive hover className='align-middle table-list'>
        <thead>
          <tr>
            {/* <th>Specification</th> */}
            <th>Specification Type</th>
            <th>Specification Value</th>
            {checkPageAccess(pageAccess, allPages?.manageSpecifications, [
              allCrudNames?.update,
              allCrudNames?.delete
            ]) && <th className='text-center'>Action</th>}
          </tr>
        </thead>
        <tbody>
          {data?.data?.data?.length > 0 ? (
            data?.data?.data?.map((data) => (
              <tr key={Math.floor(Math.random() * 100000)}>
                {/* <td>{data?.parentPathNames?.split('>')[0]}</td> */}
                <td>{data?.parentName}</td>
                <td>{data?.name}</td>
                {checkPageAccess(pageAccess, allPages?.manageSpecifications, [
                  allCrudNames?.update,
                  allCrudNames?.delete
                ]) && (
                  <td className='text-center'>
                    <div className='d-flex gap-2 justify-content-center'>
                      {checkPageAccess(
                        pageAccess,
                        allPages?.manageSpecifications,
                        allCrudNames?.update
                      ) && (
                        <span
                          onClick={() => {
                            setInitialValues(data)
                            setModalShow(true)
                          }}
                        >
                          <EditIcon bg={'bg'} />
                        </span>
                      )}
                      {checkPageAccess(
                        pageAccess,
                        allPages?.manageSpecifications,
                        allCrudNames?.delete
                      ) && (
                        <span
                          onClick={() => {
                            Swal.fire({
                              title: _SwalDelete.title,
                              text: _SwalDelete.text,
                              icon: _SwalDelete.icon,
                              showCancelButton: _SwalDelete.showCancelButton,
                              confirmButtonColor:
                                _SwalDelete.confirmButtonColor,
                              cancelButtonColor: _SwalDelete.cancelButtonColor,
                              confirmButtonText: _SwalDelete.confirmButtonText,
                              cancelButtonText: _SwalDelete.cancelButtonText
                            }).then((result) => {
                              if (result.isConfirmed) {
                                handleDelete(data?.id)
                              } else if (result.isDenied) {
                              }
                            })
                          }}
                        >
                          <DeleteIcon bg='bg' />
                        </span>
                      )}
                    </div>
                  </td>
                )}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={3} className='text-center'>
                {data?.data?.message}
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      <ReactPaginate
        className='list-inline m-cst--pagination d-flex justify-content-end gap-1'
        breakLabel='...'
        nextLabel=''
        onPageChange={handlePageClick}
        pageRangeDisplayed={pageRangeDisplayed}
        pageCount={data?.data?.pagination?.pageCount ?? 0}
        previousLabel=''
        renderOnZeroPageCount={null}
        forcePage={filterDetails?.pageIndex - 1}
      />
    </>
  )
}

export default SpeciTypeValue
