import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'

function DynamicPositionComponent({
  heading,
  paragraph,
  headingPosition,
  buttonPosition,
  buttonPositionDirection,
  link_text,
  link,
  children,
  textColor,
  titleColor,
  backgroundColor,
}) {
  const navigate = useNavigate()
  const renderOptionheading = () => {
    switch (headingPosition) {
      case 'start':
        return 'justify-content-between'
      case 'center':
        return 'flex-column'
      case 'end':
        return 'flex-row-reverse justify-content-between'
      default:
        return null
    }
  }
  const renderOptionbutton = () => {
    switch (buttonPosition) {
      case 'start':
        return 'me'
      case 'center':
        return 'm'
      case 'end':
        return 'ms'
      default:
        return null
    }
  }

  const handleRedirect = () => {
    window.location.href = link
  }

  return (
    <section
      className='py-5'
      style={{
        backgroundColor: backgroundColor ? backgroundColor : 'transparent'
      }}
    >
      <div className={`container ${headingPosition}`}>
        <div className={`d-flex align-items-center ${renderOptionheading()}`}>
          <div>
            <h2
              style={{
                textAlign: headingPosition,
                margin: '0',
                color: titleColor ? titleColor : '#000'
              }}
              className='flex-column '
            >
              {heading}
            </h2>
            <div className='text-center'>
              <p
                style={{
                  color: textColor ? textColor : '#000'
                }}
              >
                {paragraph}
              </p>
            </div>
          </div>
          {link_text && (
            <div className={`${renderOptionbutton()}-auto`}>
              {buttonPositionDirection === 'title' && (
                <button
                  className='btn mb-3'
                  style={{
                    color: titleColor ? titleColor : '#FFF',
                    border: titleColor
                      ? `1px solid ${titleColor}`
                      : '1px solid #FFF'
                  }}
                  onClick={handleRedirect}
                >
                  {link_text}
                </button>
              )}
            </div>
          )}
        </div>
        {children}
        {buttonPositionDirection === 'section' && link_text && (
          <div className={`d-flex justify-content-${buttonPosition}`}>
            <button
              className='btn my-3'
              style={{
                color: titleColor ? titleColor : '#FFF',
                border: titleColor
                  ? `1px solid ${titleColor}`
                  : '1px solid #FFF'
              }}
              onClick={handleRedirect}
            >
              {link_text}
            </button>
          </div>
        )}
      </div>
    </section>
  )
}

export default DynamicPositionComponent
