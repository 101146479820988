import { useEffect, useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Select from 'react-select'
import { ErrorMessage, Form, Formik } from 'formik'
import * as Yup from 'yup'
import axiosProvider from '../../lib/AxiosProvider'
import ModelComponent from '../Modal'
import FormikControl from '../FormikControl'
import { _integerRegex_ } from '../../lib/Regex'
import {
  callApi,
  showToast,
  splitStringOnCapitalLettersAndUnderscores
} from '../../lib/GetBaseUrl'
import Loader from '../Loader'
import { useImmer } from 'use-immer'
import { customStyles } from '../customStyles'
import TextError from '../TextError'
import { useLocation, useParams } from 'react-router-dom'
import IpCheckbox from '../IpCheckbox'
import ColorPicker from '../ColorPicker'
import { useSelector } from 'react-redux'
import { _exception } from '../../lib/exceptionMessage'

function ManageSection({
  fetchHomePageData,
  modalShow,
  setModalShow,
  loading,
  setLoading,
  toast,
  setToast,
  fromLendingPage
}) {
  const { id } = useParams()
  const [dropDownData, setDropDownData] = useImmer({
    category: [],
    brand: [],
    product: [],
    color: [],
    sizeType: [],
    collectionPage: [],
    country: [],
    state: [],
    city: [],
    landingPage: [],
    tableData: [],
    flashSale: [],
    editData: {}
  })
  const [initialValues, setInitialValues] = useState({
    listType: '',
    status: 'active',
    layoutId: modalShow?.layoutId,
    layoutName: modalShow?.layoutName,
    layoutTypeId: modalShow?.layoutTypeId,
    layoutTypeName: modalShow?.layoutTypeName,
    name: '',
    sequence: '',
    sectionColumns: '',
    title: '',
    subTitle: '',
    linkText: '',
    link: '',
    productSections: [],
    categoryId: 0,
    topProducts: null,
    isCustomGrid:
      modalShow?.layoutTypeName?.toLowerCase() === 'custom grid' ? true : false,
    columns: [],
    totalRowsInSection: '',
    isTitleVisible: false,
    titlePosition: 'Left',
    backgroundColor: '#FFFFFF',
    textColor: '#000000',
    titleColor: '#000000',
    linkIn: '',
    linkPosition: '',
    inContainer: true,
    isLinkRequired: false,
    numberOfImages: modalShow?.layoutColumnAndImages?.numberOfImages
      ? modalShow?.layoutColumnAndImages?.numberOfImages
      : 0,
    columns: modalShow?.layoutColumnAndImages?.columns
      ? modalShow?.layoutColumnAndImages?.columns
      : []
  })
  const location = useLocation()
  const { userId } = useSelector((state) => state?.user?.userInfo)

  const prepareIdsData = (data) => {
    return data?.map((option) => option?.id).join(',')
  }

  function convertIdsToArray(idsString) {
    if (!idsString) {
      return []
    }

    const idsArray = idsString
      .split(',')
      .map((id) => ({ id: parseInt(id, 10) }))
    return idsArray
  }

  const fetchData = async (id) => {
    try {
      setLoading(true)
      let apiUrls = []
      let data = await callApi(
        fromLendingPage
          ? 'LendingPageSections/byId'
          : 'ManageHomePageSections/byId',
        `?id=${id}`
      )
      if (data?.link) {
        data = { ...data, isLinkRequired: true }
      }
      if (data?.listType?.toLowerCase() === 'specific product') {
        let endpoint = fromLendingPage
          ? 'LendingPageSectionDetails/layoutTypeDetailsId&sectionId'
          : 'ManageHomePageDetails/layoutTypeDetailsId&sectionId'
        const productList = await callApi(
          endpoint,
          `?layoutTypeDetailsId=0&sectionId=${id}`
        )
        !dropDownData?.country?.length &&
          apiUrls.push({
            endpoint: 'Country',
            queryString: '?pageIndex=0&pageSize=0',
            state: 'country'
          })
        if (productList?.length) {
          let productSections = productList?.map((obj) => {
            return {
              productId: obj?.productId,
              productName: obj?.productName,
              assignCity: obj?.assignCity,
              assignCountry: obj?.assignCountry,
              assignState: obj?.assignState
            }
          })
          let assignCountry = productSections[0]?.assignCountry
          let assignState = productSections[0]?.assignState
          let assignCity = productSections[0]?.assignCity

          if (assignCountry) {
            apiUrls.push({
              endpoint: 'State/byCountryIds',
              queryString: `?countryIds=${assignCountry}&PageIndex=0&PageSize=0`,
              state: 'state'
            })
          }
          if (assignState) {
            apiUrls.push({
              endpoint: 'City/byStateandCountryIds',
              queryString: `?pageSize=0&pageIndex=0&stateIds=${assignState}`,
              state: 'city'
            })
          }

          setInitialValues({
            ...data,
            productSections,
            assignCountry: convertIdsToArray(assignCountry),
            assignState: convertIdsToArray(assignState),
            assignCity: convertIdsToArray(assignCity)
          })
        }

        !dropDownData?.product?.length &&
          apiUrls.push({
            endpoint: 'Product/AllChildProducts',
            queryString: '',
            state: 'product'
          })
      } else {
        if (data?.listType?.toLowerCase()?.includes('top')) {
          data = { ...data, productSections: [] }
          !dropDownData?.category?.length &&
            apiUrls.push({
              endpoint: 'MainCategory/getEndCategory',
              queryString: '?pageSize=0&pageIndex=0',
              state: 'category'
            })
        }
        if (data?.numberOfImages) {
          const columnsArray = []
          for (let i = 1; i <= data?.numberOfImages; i++) {
            const columnName = `column${i}`
            if (data[columnName] !== null && data[columnName] !== undefined) {
              columnsArray.push(data[columnName])
            }
          }
          data = { ...data, columns: columnsArray }
        }

        setInitialValues(data)
      }
      const fetchData = async () => {
        const responses = await fetchAllGenericData(apiUrls)
        setLoading(false)
        responses.forEach((response) => {
          switch (response.state) {
            case 'state':
              setDropDownData((draft) => {
                draft.state = response?.data
              })
              break

            case 'country':
              setDropDownData((draft) => {
                draft.country = response?.data
              })
              break

            case 'city':
              setDropDownData((draft) => {
                draft.city = response?.data
              })
              break

            case 'product':
              setDropDownData((draft) => {
                draft.product = response?.data
              })
              break

            case 'category':
              setDropDownData((draft) => {
                draft.category = response?.data
              })
              break

            default:
              break
          }
        })
      }
      fetchData()
    } catch (error) {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (modalShow?.sectionId) {
      fetchData(modalShow?.sectionId)
    }
  }, [])

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Please enter name'),
    sequence: Yup.string()
      .matches(_integerRegex_, 'Please enter a valid number')
      .required('Please enter sequence'),
    layoutName: Yup.string(),
    listType: Yup.string().when('layoutName', {
      is: 'Product List',
      then: () => Yup.string().required('List type required'),
      otherwise: () => Yup.string().nullable()
    }),
    topProducts: Yup.string().when('listType', {
      is: 'Top products',
      then: () => Yup.string().required('Please select top product'),
      otherwise: () => Yup.string().nullable()
    }),
    columns: Yup.array().when(['layoutTypeName', 'numberOfImages'], {
      is: (layoutTypeName, numberOfImages) =>
        layoutTypeName?.toLowerCase() === 'custom grid' && numberOfImages !== 1,
      then: () =>
        Yup.array()
          .of(
            Yup.mixed()
              .test(
                'is-valid-column',
                'Invalid column value',
                function (value) {
                  if (typeof value === 'string' || typeof value === 'number') {
                    return true
                  }
                  return false
                }
              )
              .required('Column value is required')
          )
          .test('is-valid-total', 'Total must be 12', function (value) {
            if (!Array.isArray(value)) return false

            const total = value.reduce(
              (sum, val) => sum + (typeof val === 'number' ? val : 0),
              0
            )

            return total === 12
          })
          .test(
            'is-valid-values',
            'Each value must be between 2 and 10',
            function (value) {
              if (!Array.isArray(value)) return false

              return value.every(
                (val) =>
                  (typeof val === 'number' || typeof val === 'string') &&
                  +val >= 2 &&
                  +val <= 10
              )
            }
          )
          .required('Columns are required')
    }),
    productSections: Yup.array().when('listType', {
      is: 'Specific product',
      then: () =>
        Yup.array()
          .min(1, 'Product sections must have a minimum length of 8')
          .max(32, 'Product sections must have a maximum length of 32')
          .required('Product sections is required'),
      otherwise: () => Yup.array().nullable()
    }),
    totalRowsInSection: Yup.string().when('layoutTypeName', {
      is: (value) => value?.toLowerCase() === 'custom row grid slider',
      then: () => Yup.string().required('Section Rows required'),
      otherwise: () => Yup.string().notRequired()
    }),
    sectionColumns: Yup.string().when('layoutTypeName', {
      is: (value) => {
        if (
          value?.toLowerCase()?.includes('grid') &&
          !value?.toLowerCase()?.includes('custom grid')
        ) {
          return true
        } else {
          return false
        }
      },
      then: () => Yup.string().required('Section Column required'),
      otherwise: () => Yup.string().notRequired()
    }),
    titlePosition: Yup.string().when('isTitleVisible', {
      is: (value) => value,
      then: () => Yup.string().required('Title position required'),
      otherwise: () => Yup.string().notRequired()
    }),
    title: Yup.string().when('isTitleVisible', {
      is: (value) => value,
      then: () => Yup.string().required('Title required'),
      otherwise: () => Yup.string().notRequired()
    }),
    linkPosition: Yup.string().when('isLinkRequired', {
      is: (value) => value,
      then: () => Yup.string().required('Link position required'),
      otherwise: () => Yup.string().notRequired()
    }),
    linkIn: Yup.string().when('isLinkRequired', {
      is: (value) => value,
      then: () => Yup.string().required('Link In required'),
      otherwise: () => Yup.string().notRequired()
    }),
    linkText: Yup.string().when('isLinkRequired', {
      is: (value) => value,
      then: () => Yup.string().required('Link Text required'),
      otherwise: () => Yup.string().notRequired()
    }),
    link: Yup.string().when('isLinkRequired', {
      is: (value) => value,
      then: () => Yup.string().required('Link is required'),
      otherwise: () => Yup.string().notRequired()
    })
  })

  const fetchAllGenericData = async (apiUrls) => {
    try {
      const responseArray = await Promise.all(
        apiUrls.map((url) => callApi(url.endpoint, url.queryString, url.state))
      )

      return responseArray
    } catch (error) {}
  }

  const onSubmit = async (values) => {
    let val = values
    if (val?.columns?.length) {
      val = val.columns.reduce(
        (acc, columnValue, index) => {
          const columnName = `column${index + 1}`
          acc[columnName] = columnValue
          return acc
        },
        { ...val }
      )
    }
    let endpoint = fromLendingPage
      ? 'LendingPageSections'
      : 'ManageHomePageSections'
    let method = ''

    if (fromLendingPage) {
      val = { ...val, lendingPageId: id }
    }

    if (
      values?.layoutName?.toLowerCase()?.includes('product') ||
      values?.layoutTypeName?.toLowerCase()?.includes('product')
    ) {
      let partEndpoint = ''
      if (fromLendingPage) {
        partEndpoint = 'LendingPageSections/'
      } else {
        partEndpoint = 'ManageHomePageSections/'
      }
      if (val?.id) {
        endpoint = `${partEndpoint}UpdateProductSection`
      } else {
        endpoint = `${partEndpoint}CreateProductSection`
      }
      let productSections = val?.productSections?.map((obj) => {
        return {
          productId: obj.productId,
          assignCity: prepareIdsData(val?.assignCity),
          assignCountry: prepareIdsData(val?.assignCountry),
          assignState: prepareIdsData(val?.assignState)
        }
      })
      val = { ...val, productSections }
    }
    if (val?.id) {
      method = 'PUT'
    } else {
      method = 'POST'
    }
    try {
      setLoading(true)
      const response = await axiosProvider({
        method,
        endpoint,
        data: val,
        location: location?.pathname,
        userId
      })
      setLoading(false)
      if (response?.data?.code === 200) {
        fetchHomePageData()
        setModalShow({
          show: !modalShow.show,
          layoutId: null,
          layoutTypeName: '',
          layoutTypeId: null,
          isDataUpdated: true
        })
      }
      showToast(toast, setToast, response)
    } catch {
      setLoading(false)
      showToast(toast, setToast, {
        data: {
          message: _exception?.message,
          code: 204
        }
      })
    }
  }

  const handleNumberOfImagesChange = (e, setFieldValue) => {
    const numberOfImages = e?.value || 2
    const columnValue = 12 / numberOfImages
    const columns = Array.from({ length: numberOfImages }, () => columnValue)

    setFieldValue('numberOfImages', numberOfImages)
    setFieldValue(`columns`, columns)
  }

  return (
    <ModelComponent
      show={modalShow?.show}
      modalsize={'lg'}
      modalheaderclass={''}
      modeltitle={
        modalShow?.layoutTypeName
          ? `Manage Section: ${splitStringOnCapitalLettersAndUnderscores(
              modalShow?.layoutTypeName
            )}`
          : `Manage Section`
      }
      onHide={() => {
        setInitialValues({})
        setModalShow({
          show: !modalShow.show,
          layoutId: null,
          layoutTypeId: null,
          isDataUpdated: false
        })
      }}
      btnclosetext={''}
      closebtnvariant={''}
      backdrop={'static'}
      formbuttonid={'return-policy-details'}
      submitname={'Save'}
    >
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validateOnChange={false}
        validateOnBlur={true}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          onSubmit({
            ...values,
            sectionColumns: values?.sectionColumns ? values?.sectionColumns : 0,
            totalRowsInSection: values?.totalRowsInSection
              ? values?.totalRowsInSection
              : 0,
            sequence: values?.sequence ? values?.sequence : 0
          })
        }}
      >
        {({ values, setFieldValue, errors }) => (
          <Form id='return-policy-details'>
            {loading && <Loader />}
            <Row className='gy-1'>
              <Col md={10}>
                <FormikControl
                  isRequired
                  control='input'
                  label='Section Name'
                  type='text'
                  name='name'
                  onChange={(e) => {
                    setFieldValue('name', e?.target?.value)
                  }}
                  placeholder='Section name'
                />
              </Col>

              <Col md={2}>
                <FormikControl
                  isRequired
                  control='input'
                  label='Sequence'
                  type='text'
                  name='sequence'
                  onChange={(e) => {
                    const inputValue = e?.target?.value
                    const isValid = _integerRegex_.test(inputValue)
                    const fieldName = e?.target?.name
                    if (isValid || !inputValue)
                      setFieldValue([fieldName], inputValue)
                  }}
                  placeholder='Sequence'
                />
              </Col>

              {(values?.layoutName?.toLowerCase()?.includes('product') ||
                values?.layoutTypeName?.toLowerCase()?.includes('product')) && (
                <>
                  <Col md={4} className='mb-3'>
                    <label htmlFor='listType' className='form-label required'>
                      List type
                    </label>
                    <Select
                      isClearable
                      isDisabled={values?.id ? true : false}
                      id='listType'
                      menuPortalTarget={document.body}
                      menuPosition={'fixed'}
                      styles={customStyles}
                      value={
                        values?.listType && {
                          label: values?.listType,
                          value: values?.listType
                        }
                      }
                      options={[
                        {
                          label: 'Top products',
                          value: 'Top products'
                        },
                        {
                          label: 'Specific product',
                          value: 'Specific product'
                        }
                      ]}
                      onChange={(e) => {
                        let apiUrls = []
                        switch (e?.value) {
                          case 'Top products':
                            !dropDownData?.category?.length &&
                              apiUrls.push({
                                endpoint: 'MainCategory/getEndCategory',
                                queryString: '?pageSize=0&pageIndex=0',
                                state: 'category'
                              })
                            break
                          case 'Specific product':
                            !dropDownData?.product?.length &&
                              apiUrls.push({
                                endpoint: 'Product/AllChildProducts',
                                queryString: '',
                                state: 'product'
                              })

                            !dropDownData?.country?.length &&
                              apiUrls.push({
                                endpoint: 'Country',
                                queryString: '?pageIndex=0&pageSize=0',
                                state: 'country'
                              })
                            break
                        }
                        const fetchData = async () => {
                          setLoading(true)
                          const responses = await fetchAllGenericData(apiUrls)
                          responses.forEach((response) => {
                            switch (response.state) {
                              case 'category':
                                setDropDownData((draft) => {
                                  draft.category = response.data
                                })
                                break

                              case 'product':
                                setDropDownData((draft) => {
                                  draft.product = response.data
                                })
                                break

                              case 'country':
                                setDropDownData((draft) => {
                                  draft.country = response.data
                                })
                                break

                              default:
                                break
                            }
                          })
                          setLoading(false)
                        }
                        apiUrls?.length && fetchData()
                        setFieldValue('listType', e?.value)
                        setFieldValue('categoryId', 0)
                        setFieldValue('productSections', [])
                      }}
                    />
                    <ErrorMessage
                      name='listType'
                      component={TextError}
                      customclass={'cfz-12 lh-sm'}
                    />
                  </Col>

                  {values?.listType?.toLowerCase() === 'top products' && (
                    <Col md={3} className='mb-3'>
                      <label
                        htmlFor='topProducts'
                        className='form-label required'
                      >
                        Top products
                      </label>
                      <Select
                        isClearable
                        id='topProducts'
                        menuPortalTarget={document.body}
                        menuPosition={'fixed'}
                        styles={customStyles}
                        value={
                          values?.topProducts && {
                            label: values?.topProducts,
                            value: values?.topProducts
                          }
                        }
                        options={
                          values?.layoutTypeName
                            ?.toLowerCase()
                            ?.includes('gallery')
                            ? [
                                {
                                  label: 12,
                                  value: 12
                                },
                                {
                                  label: 16,
                                  value: 16
                                },
                                {
                                  label: 20,
                                  value: 20
                                },
                                {
                                  label: 24,
                                  value: 24
                                },
                                {
                                  label: 28,
                                  value: 28
                                },
                                {
                                  label: 32,
                                  value: 32
                                }
                              ]
                            : [
                                {
                                  label: 10,
                                  value: 10
                                },
                                {
                                  label: 15,
                                  value: 15
                                },
                                {
                                  label: 20,
                                  value: 20
                                },
                                {
                                  label: 25,
                                  value: 25
                                }
                              ]
                        }
                        onChange={(e) => {
                          setFieldValue('topProducts', e?.value)
                        }}
                      />
                      <ErrorMessage
                        name='topProducts'
                        component={TextError}
                        customclass={'cfz-12 lh-sm'}
                      />
                    </Col>
                  )}

                  {values?.listType?.toLowerCase() === 'top products' && (
                    <Col md={5} className='mb-3'>
                      <label htmlFor='categoryId' className='form-label'>
                        Select category
                      </label>
                      <Select
                        isClearable
                        id='categoryId'
                        menuPortalTarget={document.body}
                        menuPosition={'fixed'}
                        styles={customStyles}
                        value={
                          values?.categoryId && {
                            label: dropDownData?.category?.find(
                              (obj) => obj.id === values?.categoryId
                            )?.pathNames,
                            value: values?.categoryId
                          }
                        }
                        options={dropDownData?.category?.map(
                          ({ id, pathNames }) => ({
                            label: pathNames,
                            value: id
                          })
                        )}
                        onChange={(e) => {
                          setFieldValue('categoryId', e?.value)
                        }}
                      />
                    </Col>
                  )}

                  {values?.listType?.toLowerCase() === 'specific product' && (
                    <Col md={5} className='mb-3'>
                      <label
                        htmlFor='productSections'
                        className='form-label required'
                      >
                        Select Products
                      </label>
                      <Select
                        isMulti
                        isClearable
                        id='productSections'
                        menuPortalTarget={document.body}
                        menuPosition={'fixed'}
                        styles={customStyles}
                        value={
                          values?.productSections &&
                          values.productSections?.map((data) => {
                            return {
                              label: dropDownData?.product?.find(
                                (obj) => obj?.productId === data?.productId
                              )?.productName,
                              value: data?.productId
                            }
                          })
                        }
                        options={dropDownData?.product?.map(
                          ({ productGuid, productName }) => ({
                            label: productName,
                            value: productGuid
                          })
                        )}
                        onChange={(e) => {
                          let productSections = e?.map((data) => {
                            return {
                              productName: data?.label,
                              productId: data.value
                            }
                          })
                          setFieldValue('productSections', productSections)
                        }}
                      />
                      <ErrorMessage
                        name='productSections'
                        component={TextError}
                        customclass={'cfz-12 lh-sm'}
                      />
                    </Col>
                  )}

                  {values?.listType?.toLowerCase() === 'specific product' && (
                    <>
                      <Col md={3} className='mb-3'>
                        <label htmlFor='AssignCountry' className='form-label'>
                          Assign Country
                        </label>
                        <Select
                          isMulti
                          id='AssignCountry'
                          menuPortalTarget={document.body}
                          menuPosition={'fixed'}
                          styles={customStyles}
                          value={
                            values?.assignCountry &&
                            values.assignCountry?.map((data) => {
                              return {
                                label: dropDownData?.country?.find(
                                  (obj) => obj?.id === data?.id
                                )?.name,
                                value: data?.id
                              }
                            })
                          }
                          options={dropDownData?.country?.map(
                            ({ name, id }) => ({
                              label: name,
                              value: id
                            })
                          )}
                          onChange={(e) => {
                            const fetchData = async (ids) => {
                              try {
                                let apiUrls = []
                                apiUrls.push({
                                  endpoint: 'State/byCountryIds',
                                  queryString: `?countryIds=${ids}&PageIndex=0&PageSize=0`,
                                  state: 'state'
                                })
                                const fetchData = async () => {
                                  let state
                                  setLoading(true)
                                  const responses = await fetchAllGenericData(
                                    apiUrls
                                  )
                                  responses.forEach((response) => {
                                    switch (response.state) {
                                      case 'state':
                                        state = response.data
                                        break
                                      default:
                                        break
                                    }
                                  })
                                  setDropDownData((draft) => {
                                    draft.state = state
                                    draft.city = []
                                  })
                                  setLoading(false)
                                }
                                fetchData()
                              } catch (error) {
                                setLoading(false)
                              }
                            }
                            let assignCountry = e?.map((data) => {
                              return { name: data?.label, id: data.value }
                            })
                            setFieldValue('assignCountry', assignCountry)
                            setFieldValue('assignState', [])
                            setFieldValue('assignCity', [])
                            let ids = prepareIdsData(assignCountry)
                            ids
                              ? fetchData(ids)
                              : setDropDownData((draft) => {
                                  draft.state = []
                                  draft.city = []
                                })
                          }}
                        />
                      </Col>
                      {dropDownData?.state?.length > 0 && (
                        <Col md={3} className='mb-3'>
                          <label htmlFor='AssignState' className='form-label'>
                            Assign State
                          </label>
                          <Select
                            isMulti
                            id='AssignState'
                            menuPortalTarget={document.body}
                            menuPosition={'fixed'}
                            styles={customStyles}
                            value={
                              values?.assignState &&
                              values.assignState?.map((data) => {
                                return {
                                  label: dropDownData?.state?.find(
                                    (obj) => obj?.id === data?.id
                                  )?.name,
                                  value: data?.id
                                }
                              })
                            }
                            options={dropDownData?.state?.map(
                              ({ name, id }) => ({
                                label: name,
                                value: id
                              })
                            )}
                            onChange={(e) => {
                              const fetchData = async (ids) => {
                                try {
                                  let apiUrls = []
                                  apiUrls.push({
                                    endpoint: 'City/byStateandCountryIds',
                                    queryString: `?pageSize=0&pageIndex=0&stateIds=${ids}`,
                                    state: 'city'
                                  })
                                  const fetchData = async () => {
                                    let city
                                    setLoading(true)
                                    const responses = await fetchAllGenericData(
                                      apiUrls
                                    )
                                    responses.forEach((response) => {
                                      switch (response.state) {
                                        case 'city':
                                          city = response.data
                                          break
                                        default:
                                          break
                                      }
                                    })
                                    setDropDownData((draft) => {
                                      draft.city = city
                                    })
                                    setLoading(false)
                                  }
                                  fetchData()
                                } catch (error) {
                                  setLoading(false)
                                }
                              }
                              let assignState = e?.map((data) => {
                                return { name: data?.label, id: data.value }
                              })
                              setFieldValue('assignState', assignState)
                              setFieldValue('assignCity', [])
                              let ids = prepareIdsData(assignState)
                              ids
                                ? fetchData(ids)
                                : setDropDownData((draft) => {
                                    draft.city = []
                                  })
                            }}
                          />
                        </Col>
                      )}

                      {dropDownData?.city?.length > 0 && (
                        <Col md={3} className='mb-3'>
                          <label htmlFor='AssignCity' className='form-label'>
                            Assign City
                          </label>
                          <Select
                            isMulti
                            id='AssignCity'
                            menuPortalTarget={document.body}
                            menuPosition={'fixed'}
                            styles={customStyles}
                            value={
                              values?.assignCity &&
                              values.assignCity?.map((data) => {
                                return {
                                  label: dropDownData?.city?.find(
                                    (obj) => obj?.id === data?.id
                                  )?.name,
                                  value: data?.id
                                }
                              })
                            }
                            options={dropDownData?.city?.map(
                              ({ name, id }) => ({
                                label: name,
                                value: id
                              })
                            )}
                            onChange={(e) => {
                              let assignCity = e?.map((data) => {
                                return { name: data?.label, id: data.value }
                              })
                              setFieldValue('assignCity', assignCity)
                            }}
                          />
                        </Col>
                      )}
                    </>
                  )}
                </>
              )}

              <Col
                md={3}
                className={
                  values?.layoutTypeName?.toLowerCase()?.includes('grid') &&
                  !values?.layoutTypeName
                    ?.toLowerCase()
                    ?.includes('custom grid')
                    ? 'd-block'
                    : 'd-none'
                }
              >
                <label htmlFor='sectionColumns' className='form-label required'>
                  Section Column
                </label>
                <Select
                  isClearable
                  id='sectionColumns'
                  menuPortalTarget={document.body}
                  menuPosition={'fixed'}
                  styles={customStyles}
                  value={
                    values?.sectionColumns && {
                      label: values?.sectionColumns,
                      value: values?.sectionColumns
                    }
                  }
                  options={[
                    { label: 1, value: 1 },
                    { label: 2, value: 2 },
                    { label: 3, value: 3 },
                    { label: 4, value: 4 },
                    { label: 5, value: 5 },
                    { label: 6, value: 6 },
                    { label: 7, value: 7 },
                    { label: 8, value: 8 },
                    { label: 9, value: 9 },
                    { label: 10, value: 10 },
                    { label: 11, value: 11 },
                    { label: 12, value: 12 }
                  ]}
                  onChange={(e) => {
                    setFieldValue('sectionColumns', e?.value)
                  }}
                />
                <ErrorMessage name='sectionColumns' component={TextError} />
              </Col>

              <Col
                md={3}
                className={
                  values?.layoutTypeName?.toLowerCase() ===
                  'custom row grid slider'
                    ? 'd-block'
                    : 'd-none'
                }
              >
                <label
                  htmlFor='totalRowsInSection'
                  className='form-label required'
                >
                  Select Number of Rows
                </label>
                <Select
                  isClearable
                  id='totalRowsInSection'
                  menuPortalTarget={document.body}
                  menuPosition={'fixed'}
                  styles={customStyles}
                  value={
                    values?.totalRowsInSection && {
                      label: values?.totalRowsInSection,
                      value: values?.totalRowsInSection
                    }
                  }
                  options={[
                    { label: 1, value: 1 },
                    { label: 2, value: 2 },
                    { label: 3, value: 3 },
                    { label: 4, value: 4 },
                    { label: 5, value: 5 }
                  ]}
                  onChange={(e) => {
                    setFieldValue('totalRowsInSection', e?.value)
                  }}
                />
                <ErrorMessage name='totalRowsInSection' component={TextError} />
              </Col>

              {!modalShow?.layoutColumnAndImages && (
                <>
                  <Col
                    md={3}
                    className={
                      values?.layoutTypeName?.toLowerCase() === 'custom grid'
                        ? 'd-block'
                        : 'd-none'
                    }
                  >
                    <label
                      htmlFor='numberOfImages'
                      className='form-label required'
                    >
                      Number of images
                    </label>
                    <Select
                      id='numberOfImages'
                      menuPortalTarget={document.body}
                      menuPosition={'fixed'}
                      styles={customStyles}
                      value={
                        values?.numberOfImages && {
                          label: values?.numberOfImages,
                          value: values?.numberOfImages
                        }
                      }
                      options={[
                        { label: 1, value: 1 },
                        {
                          label: 2,
                          value: 2
                        },
                        {
                          label: 3,
                          value: 3
                        },
                        {
                          label: 4,
                          value: 4
                        }
                      ]}
                      onChange={(e) => {
                        handleNumberOfImagesChange(e, setFieldValue)
                      }}
                      isDisabled={
                        modalShow?.layoutColumnAndImages ? true : false
                      }
                    />
                  </Col>

                  {values?.numberOfImages !== 1 && (
                    <Row>
                      {values?.columns?.map((item, index) => (
                        <Col md={3}>
                          <FormikControl
                            disabled={
                              modalShow?.layoutColumnAndImages ? true : false
                            }
                            control='input'
                            label={`Column ${index + 1}`}
                            type='text'
                            name={`column${index + 1}`}
                            min={2}
                            onChange={(e) => {
                              let inputValue = e?.target?.value
                              let isValid = _integerRegex_.test(inputValue)
                              if (isValid || !inputValue) {
                                let columns = values?.columns ?? []
                                columns = columns.map((item, itemIndex) => {
                                  if (itemIndex === index) {
                                    return parseInt(inputValue, 10)
                                  } else {
                                    return item
                                  }
                                })

                                setFieldValue('columns', columns)
                              }
                            }}
                            value={item ? item : ''}
                            placeholder={`Column ${index + 1}`}
                          />
                        </Col>
                      ))}
                      {errors?.columns && (
                        <div className='text-danger'>{errors?.columns}</div>
                      )}
                    </Row>
                  )}
                </>
              )}
              <IpCheckbox
                checked={values?.isTitleVisible ? true : false}
                checkboxLabel={'Title Visibility'}
                checkboxid={'isTitleVisible'}
                value={'isTitleVisible'}
                changeListener={(e) => {
                  setFieldValue('isTitleVisible', e?.checked)
                  setFieldValue('title', '')
                  setFieldValue('subTitle', '')
                  setFieldValue('titlePosition', 'Left')
                }}
              />

              {values?.isTitleVisible && (
                <>
                  <Col md={3}>
                    <FormikControl
                      control='input'
                      label='Title'
                      type='text'
                      name='title'
                      onChange={(e) => {
                        setFieldValue('title', e?.target?.value)
                      }}
                      placeholder='Title'
                      isRequired
                    />
                  </Col>

                  <Col md={7}>
                    <FormikControl
                      control='input'
                      label='Sub title'
                      type='text'
                      name='subTitle'
                      onChange={(e) => {
                        setFieldValue('subTitle', e?.target?.value)
                      }}
                      placeholder='Sub title'
                    />
                  </Col>
                  <Col md={2}>
                    <label
                      htmlFor='titlePosition'
                      className='form-label required'
                    >
                      Title position
                    </label>
                    <Select
                      id='titlePosition'
                      menuPortalTarget={document.body}
                      menuPosition={'fixed'}
                      styles={customStyles}
                      value={
                        values?.titlePosition && {
                          label: values?.titlePosition,
                          value: values?.titlePosition
                        }
                      }
                      options={[
                        {
                          label: 'Left',
                          value: 'Left'
                        },
                        {
                          label: 'Center',
                          value: 'Center'
                        },
                        {
                          label: 'Right',
                          value: 'Right'
                        }
                      ]}
                      onChange={(e) => {
                        setFieldValue('titlePosition', e?.label)
                      }}
                    />
                    <ErrorMessage
                      name='titlePosition'
                      component={TextError}
                      customclass={'cfz-12 lh-sm'}
                    />
                  </Col>
                </>
              )}

              <IpCheckbox
                checked={values?.isLinkRequired ? true : false}
                checkboxLabel={'Has Link'}
                checkboxid={'isLinkRequired'}
                value={'isLinkRequired'}
                changeListener={(e) => {
                  setFieldValue('isLinkRequired', e?.checked)
                }}
              />

              {values?.isLinkRequired && (
                <>
                  <Col md={6}>
                    <FormikControl
                      isRequired
                      control='input'
                      label='Link text'
                      type='text'
                      name='linkText'
                      onChange={(e) => {
                        setFieldValue('linkText', e?.target?.value)
                      }}
                      placeholder='Link text'
                    />
                  </Col>
                  <Col md={3}>
                    <label htmlFor='linkIn' className='form-label required'>
                      Link In
                    </label>
                    <Select
                      id='linkIn'
                      menuPortalTarget={document.body}
                      menuPosition={'fixed'}
                      styles={customStyles}
                      value={
                        values?.linkIn && {
                          label: values?.linkIn,
                          value: values?.linkIn
                        }
                      }
                      options={[
                        {
                          label: 'Title',
                          value: 'Title'
                        },
                        {
                          label: 'Section',
                          value: 'Section'
                        }
                      ]}
                      onChange={(e) => {
                        setFieldValue('linkIn', e?.label)
                      }}
                    />
                    <ErrorMessage
                      name='linkIn'
                      component={TextError}
                      customclass={'cfz-12 lh-sm'}
                    />
                  </Col>
                  <Col md={3}>
                    <label
                      htmlFor='linkPosition'
                      className='form-label required'
                    >
                      Link position
                    </label>
                    <Select
                      id='linkPosition'
                      menuPortalTarget={document.body}
                      menuPosition={'fixed'}
                      styles={customStyles}
                      value={
                        values?.linkPosition && {
                          label: values?.linkPosition,
                          value: values?.linkPosition
                        }
                      }
                      options={[
                        {
                          label: 'Left',
                          value: 'Left'
                        },
                        {
                          label: 'Center',
                          value: 'Center'
                        },
                        {
                          label: 'Right',
                          value: 'Right'
                        }
                      ]}
                      onChange={(e) => {
                        setFieldValue('linkPosition', e?.label)
                      }}
                    />
                    <ErrorMessage
                      name='linkPosition'
                      component={TextError}
                      customclass={'cfz-12 lh-sm'}
                    />
                  </Col>
                  <Col md={12}>
                    <FormikControl
                      control='input'
                      label='Link'
                      type='text'
                      name='link'
                      onChange={(e) => {
                        setFieldValue('link', e?.target?.value)
                      }}
                      placeholder='Link'
                      isRequired
                    />
                  </Col>
                </>
              )}

              <div className='col-4 input-wrapper'>
                <label htmlFor='' className='form-label'>
                  Background color
                </label>
                <div className='input-group'>
                  <ColorPicker
                    value={values?.backgroundColor}
                    onChange={(code) => {
                      setFieldValue('backgroundColor', code)
                    }}
                  />
                </div>
              </div>

              <div className='col-4 input-wrapper'>
                <label htmlFor='' className='form-label'>
                  Title color
                </label>
                <div className='input-group'>
                  <ColorPicker
                    value={values?.titleColor}
                    onChange={(code) => {
                      setFieldValue('titleColor', code)
                    }}
                  />
                </div>
              </div>

              <div className='col-4 input-wrapper'>
                <label htmlFor='' className='form-label'>
                  Text color
                </label>
                <div className='input-group'>
                  <ColorPicker
                    value={values?.textColor}
                    onChange={(code) => {
                      setFieldValue('textColor', code)
                    }}
                  />
                </div>
              </div>

              <Col md={4}>
                <label className='form-label required'>Status</label>
                <div className='switch'>
                  <input
                    type='radio'
                    value={true}
                    id='yes'
                    checked={values?.status?.toLowerCase() === 'active' && true}
                    name='status'
                    onChange={(e) => {
                      if (e?.target?.checked) setFieldValue('status', 'Active')
                    }}
                  />
                  <label htmlFor='yes'>Active</label>
                  <input
                    type='radio'
                    value={false}
                    id='no'
                    name='status'
                    checked={
                      values?.status?.toLowerCase() === 'inactive' && true
                    }
                    onChange={(e) => {
                      if (e?.target?.checked)
                        setFieldValue('status', 'Inactive')
                    }}
                  />
                  <label htmlFor='no'>Inactive</label>
                  <span className='switchFilter'></span>
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </ModelComponent>
  )
}

export default ManageSection
