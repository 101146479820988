import React, { useEffect } from 'react'
import { Button, Offcanvas, Form, Row, Col, Card } from 'react-bootstrap'
import Loader from '../../components/Loader'
import Select from 'react-select'
import {
  arrangeNamesBySequence,
  callApi,
  changeHandler,
  isAllowPriceVariant,
  isAllowWarehouseManagement,
  prepareDisplayCalculationData,
  prepareProductName,
  productStatus
} from '../../lib/GetBaseUrl'
import WarehouseNotPrice from './WarehouseNotPrice'
import NotWarehouseAndPrice from './NotWarehouseAndPrice'
import NotWarehousePrice from './NotWarehousePrice'
import WarehouseAndPrice from './WarehouseAndPrice'
import { Formik } from 'formik'
import axiosProvider from '../../lib/AxiosProvider'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { customStyles } from '../../components/customStyles'

const QuickUpdate = ({
  quickUpdate,
  setQuickUpdate,
  toast,
  setToast,
  fetchArchiveProduct = null
}) => {
  const [allState, setAllState] = useState()
  const [loading, setLoading] = useState(false)
  const [initialValues, setInitialValues] = useState()
  const [calculation, setCalculation] = useState()
  const { userId } = useSelector((state) => state?.user?.userInfo)
  const location = useLocation()

  const fetchCalculation = async (endpoint, data, setterFunc) => {
    const response = await axiosProvider({
      method: 'POST',
      endpoint,
      data
    })
      .then((res) => {
        if (res.status === 200) {
          return setterFunc(res?.data)
        }
      })
      .catch((err) => {})
  }

  const fetchAllGenericData = async (apiUrls) => {
    try {
      const responseArray = await Promise.all(
        apiUrls.map((url) => callApi(url.endpoint, url.queryString, url.state))
      )

      return responseArray.filter((response) => response !== undefined)
    } catch (error) {}
  }

  const prepareEditData = (productEditData, sizeType, warehouseDetails) => {
    let tempData = {
      ...productEditData,
      isExistingProduct: false,
      keywords: productEditData?.keywords
        ? productEditData?.keywords?.split(',')
        : [],
      live: productEditData?.sellerProducts?.live,
      packingWeight: productEditData?.sellerProducts?.packingWeight,
      packingLength: productEditData?.sellerProducts?.packingLength,
      packingHeight: productEditData?.sellerProducts?.packingHeight,
      packingBreadth: productEditData?.sellerProducts?.packingBreadth,
      discount: productEditData?.sellerProducts?.productPrices[0]?.discount,
      sellingPrice:
        productEditData?.sellerProducts?.productPrices[0]?.sellingPrice,
      mrp: productEditData?.sellerProducts?.productPrices[0]?.mrp,
      sellerSKU: productEditData?.sellerProducts?.sellerSKU,
      sellerID: productEditData?.sellerProducts?.sellerID,
      status: productEditData?.sellerProducts?.status,
      weightSlabId: productEditData?.sellerProducts?.weightSlabId,
      sellerName: productEditData?.sellerProducts?.sellerName,
      brandName: productEditData?.sellerProducts?.brandName,
      tempColorId: productEditData?.productColorMapping[0]?.colorId,
      isSizeWisePriceVariant:
        productEditData?.sellerProducts?.isSizeWisePriceVariant,
      isSellerSKUAvailable: true,
      isCompanySKUAvailable: true
    }

    let sizeTypeID
    let productPricesTemp = []

    const tempSizeType = sizeType
    let productName = Array.isArray(tempData?.productName)
      ? tempData?.productName
      : []

    if (productEditData?.sellerProducts?.brandName) {
      productName = prepareProductName(
        productEditData?.sellerProducts?.brandName,
        -2,
        'brand',
        { productName },
        true
      )
    }

    if (productEditData?.categoryName) {
      productName = prepareProductName(
        productEditData?.categoryName,
        -1,
        'category',
        { productName },
        true
      )
    }

    if (productEditData?.isAllowColorsInTitle) {
      productName = prepareProductName(
        productEditData?.productColorMapping[0]?.colorName,
        productEditData?.titleSequenceOfColor,
        'color',
        { productName },
        true
      )
    }

    if (tempSizeType?.length) {
      if (tempData?.sellerProducts?.productPrices?.length > 0) {
        sizeTypeID = tempSizeType?.find(
          (obj) =>
            obj.sizeId === tempData?.sellerProducts?.productPrices[0]?.sizeID
        )?.sizeTypeID

        let getAllSizeValues = tempSizeType?.filter(
          (obj) => obj.sizeTypeID === sizeTypeID
        )
        productPricesTemp = getAllSizeValues?.map((obj1) => {
          let productWarehouse = []
          const obj2 = tempData?.sellerProducts?.productPrices?.find(
            (obj2) => obj2?.sizeID === obj1?.sizeId
          )

          if (obj2) {
            if (warehouseDetails) {
              productWarehouse = warehouseDetails?.map((warehouseData) => {
                let productPriceWarehouseData = obj2?.productWarehouses?.find(
                  (data) => data?.warehouseId === warehouseData?.id
                )

                return productPriceWarehouseData
                  ? productPriceWarehouseData
                  : {
                      warehouseId: warehouseData?.id,
                      warehouseName: warehouseData?.name,
                      quantity: 0,
                      sizeID: obj2?.sizeID
                    }
              })
              return {
                ...obj2,
                quantity: productWarehouse?.reduce((acc, current) => {
                  return acc + current?.quantity
                }, 0),
                productWarehouses: productWarehouse,
                isCheckedForQty: tempData?.sellerProducts
                  ?.isSizeWisePriceVariant
                  ? false
                  : true,
                manageWarehouseStock: true,
                isDataInTable: tempData?.sellerProducts?.isSizeWisePriceVariant
                  ? true
                  : false
              }
            } else {
              return {
                ...obj2,
                quantity: productWarehouse?.reduce((acc, current) => {
                  return acc + current?.quantity
                }, 0),
                isCheckedForQty: tempData?.sellerProducts
                  ?.isSizeWisePriceVariant
                  ? false
                  : true,
                isDataInTable: tempData?.sellerProducts?.isSizeWisePriceVariant
                  ? true
                  : false
              }
            }
          } else {
            return {
              mrp: '',
              discount: '',
              sellingPrice: '',
              productWarehouses: productWarehouse,
              sizeID: obj1?.sizeId,
              sizeName: obj1?.sizeName,
              sellerProductId: tempData?.sellerProducts?.id
            }
          }
        })
      } else {
        productPricesTemp = tempSizeType?.map((data) => {
          return {
            mrp: '',
            sellingPrice: '',
            quantity: '',
            productWarehouse: '',
            sizeID: data?.sizeId,
            sizeName: data?.sizeName,
            isCheckedForQty: false,
            isDataInTable: false
          }
        })
      }
      if (tempData?.sellerProducts?.productPrices?.length === 1) {
        if (productEditData?.isAllowSizeInTitle) {
          productName = prepareProductName(
            productEditData?.sellerProducts?.productPrices[0]?.sizeName,
            productEditData?.titleSequenceOfSize,
            'size',
            { productName },
            true
          )
        }
      }
    } else {
      let productPrices = tempData?.sellerProducts?.productPrices[0]
      if (warehouseDetails) {
        let productWarehouse = warehouseDetails?.map((warehouseData) => {
          let productPriceWarehouseData =
            productPrices?.productWarehouses?.find(
              (data) => data?.warehouseId === warehouseData?.id
            )
          return productPriceWarehouseData
            ? productPriceWarehouseData
            : {
                warehouseId: warehouseData?.id,
                warehouseName: warehouseData?.name,
                quantity: 0
              }
        })
        productPrices = {
          ...productPrices,
          productWarehouses: productWarehouse,
          isCheckedForQty: true,
          manageWarehouseStock: true
        }
        productPricesTemp.push(productPrices)
      } else {
        productPricesTemp.push(productPrices)
      }
    }
    tempData = {
      ...tempData,
      productPrices: productPricesTemp,
      tempSizeId: sizeTypeID,
      productName
    }

    if (tempData?.mrp && tempData?.sellingPrice) {
      fetchCalculation(
        'Product/DisplayCalculation',
        {
          mrp: tempData?.isSizeWisePriceVariant ? '0' : tempData?.mrp,
          sellingPrice: tempData?.isSizeWisePriceVariant
            ? '0'
            : tempData?.sellingPrice
        },
        (data) => {
          setCalculation({ ...calculation, displayCalculation: data })
        }
      )
    }

    if (tempData?.isSizeWisePriceVariant) {
      tempData = { ...tempData, mrp: '', sellingPrice: '', discount: '' }
    }

    setInitialValues(tempData)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        let apiUrls = [
          {
            endpoint: 'WeightSlab',
            queryString: `?pageIndex=0&pageSize=0`,
            state: 'weight'
          }
        ]
        let productEditData = await callApi(
          'Product/GetProductDetailsWithSellerId',
          `?ProductId=${quickUpdate?.id}&sellerId=${quickUpdate?.sellerId}`
        )

        const assignSpecificationData = await callApi(
          'AssignSpecificationToCategory/getByCatId',
          `?catId=${productEditData?.categoryId}`
        )
        if (assignSpecificationData?.isAllowColorsInTitle) {
          if (assignSpecificationData?.titleSequenceOfColor) {
            productEditData = {
              ...productEditData,
              titleSequenceOfColor:
                assignSpecificationData?.titleSequenceOfColor,
              isAllowColorsInTitle:
                assignSpecificationData?.isAllowColorsInTitle
            }
          }
        }

        if (assignSpecificationData?.isAllowSize) {
          apiUrls.push({
            endpoint: 'AssignSizeValuesToCategory/byAssignSpecId',
            queryString: `?assignSpecId=${assignSpecificationData?.id}&pageindex=0&PageSize=0`,
            state: 'sizeType'
          })
        }
        if (isAllowWarehouseManagement) {
          productEditData?.sellerProducts?.sellerID &&
            apiUrls.push({
              endpoint: 'seller/Warehouse/WarehouseSearch',
              queryString: `?UserID=${productEditData?.sellerProducts?.sellerID}&status=Active`,
              state: 'warehouseDetails'
            })
        }
        if (assignSpecificationData?.isAllowSpecifications) {
          apiUrls.push({
            endpoint: 'AssignSpecValuesToCategory/GetSpecsList',
            queryString: `?AssignSpecId=${assignSpecificationData?.id}`,
            state: 'specificationData'
          })
        }
        const fetchData = async () => {
          let sizeType, warehouseDetails, weight, specificationData
          let productName = []
          const responses = await fetchAllGenericData(apiUrls)
          setLoading(false)
          responses.forEach((response) => {
            switch (response.state) {
              case 'sizeType':
                sizeType = response.data
                if (sizeType[0]?.isAllowSizeInTitle) {
                  productEditData = {
                    ...productEditData,
                    isAllowSizeInTitle: sizeType[0]?.isAllowSizeInTitle,
                    titleSequenceOfSize: sizeType[0]?.titleSequenceOfSize
                  }
                }
                break

              case 'weight':
                weight = response.data
                break

              case 'warehouseDetails':
                warehouseDetails = response?.data
                break

              case 'specificationData':
                specificationData = response.data
                const matchingSpecs =
                  productEditData?.productSpecificationsMapp?.filter((item) => {
                    return specificationData?.some(
                      (spec) => spec?.specId === item?.specId
                    )
                  })

                matchingSpecs?.forEach((matchingSpec) => {
                  for (const specification of specificationData) {
                    if (specification?.specId === matchingSpec?.specId) {
                      const types = specification?.types

                      for (const type of types) {
                        if (type?.specTypeId === matchingSpec?.specTypeId) {
                          const values = type?.values

                          const hasTitleSpec = values?.some(
                            (value) => value?.isAllowSpecInTitle
                          )

                          let titleSequence = null
                          if (hasTitleSpec) {
                            const specificationValues = values?.find(
                              (value) => value?.isAllowSpecInTitle
                            )
                            productName = prepareProductName(
                              specificationValues?.name,
                              specificationValues?.titleSequenceOfSpecification,
                              type?.name,
                              { productName },
                              true
                            )
                          }
                        }
                      }
                    }
                  }
                })
                break

              default:
                break
            }
          })
          setAllState({
            ...allState,
            sizeType,
            warehouseDetails,
            weight,
            editData: productEditData
          })
          prepareEditData(
            { ...productEditData, productName },
            sizeType,
            warehouseDetails
          )
        }
        fetchData()
      } catch (error) {
        setLoading(false)
      }
    }
    fetchData()
  }, [quickUpdate?.id])

  const checkAllRequiredField = (values, setFieldValue) => {
    if (!values?.productPrices?.length) {
      setFieldValue('finalValidation', 'Please Select atleast one value')
      return false
    }
    // if (!values?.mrp && !values?.isSizeWisePriceVariant) {
    //   setFieldValue('finalValidation', 'MRP Required')
    //   return false
    // }
    // if (!values?.sellingPrice && !values?.isSizeWisePriceVariant) {
    //   setFieldValue('finalValidation', 'Selling Price Required')
    //   return false
    // }
    // if (!values?.discount && !values?.isSizeWisePriceVariant) {
    //   setFieldValue('finalValidation', 'Discount Required')
    //   return false
    // }
    return true
  }

  const handleSubmit = async (values, setFieldValue) => {
    let val = {
      ...values,
      productPrices: values?.productPrices?.filter((data) => data?.quantity > 0)
    }
    if (checkAllRequiredField(val, setFieldValue)) {
      let finalData = {
        sellerProductId: val?.sellerProducts?.id,
        productId: val?.productId,
        productSku: val?.companySKUCode,
        sellerSku: val?.sellerProducts?.sellerSKU,
        sizeTypeId: val?.sizeTypeId,
        sizeTypeName: val?.sizeTypeName,
        isSizeWisePriceVariant: val?.isSizeWisePriceVariant,
        isExistingProduct: val?.isExistingProduct,
        productPrice: val?.productPrices,
        productName: arrangeNamesBySequence(val?.productName ?? []),
        status: val?.status,
        live: val?.live,
        packingBreadth: val?.packingBreadth ?? 0,
        packingHeight: val?.packingHeight ?? 0,
        packingLength: val?.packingLength ?? 0,
        packingWeight: val?.packingWeight ?? 0,
        weightSlabId: val?.weightSlabId
      }

      setLoading(true)

      const response = await axiosProvider({
        method: 'PUT',
        endpoint: 'Product/QuickUpdate',
        data: finalData,
        oldData: allState?.editData,
        location: location?.pathname,
        userId
      })
        .then((res) => {
          if (res?.status === 200) {
            if (fetchArchiveProduct) {
              fetchArchiveProduct()
            }
            setToast({
              show: true,
              text: res?.data?.message,
              variation: res?.data?.code !== 200 ? 'error' : 'success'
            })
            setTimeout(() => {
              setToast({ ...toast, show: false })
            }, 2000)
          }
          setQuickUpdate({
            ...quickUpdate,
            show: !quickUpdate?.show,
            isDataUpdated: true
          })

          setLoading(false)
        })
        .catch((err) => {
          setLoading(false)
        })
    }
  }

  return (
    <Offcanvas
      className='pv-offcanvas'
      placement='end'
      show={quickUpdate.show}
      backdrop='static'
      onHide={() => {
        setQuickUpdate({ ...quickUpdate, show: !quickUpdate, id: null })
      }}
    >
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={(values, { setFieldValue }) =>
          handleSubmit(values, setFieldValue)
        }
      >
        {({ values, setFieldValue }) => (
          <Form className='product_form overflow-auto'>
            <Offcanvas.Header closeButton>
              <Offcanvas.Title className='bold'>
                {arrangeNamesBySequence(values?.productName ?? [])}
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              {loading && <Loader />}

              <div className='card-body mb-3'>
                <div className='box d-flex justify-content-between'>
                  <div>
                    <label className='form-label fw-normal text-nowrap mb-0'>
                      Product Name
                    </label>

                    <p
                      className='mb-0 bold text-nowrap'
                      style={{ fontSize: '22px', color: '#001B5A' }}
                    >
                      {arrangeNamesBySequence(values?.productName ?? [])}
                    </p>
                    {!values?.productName && (
                      <p
                        className='mb-0 bold text-nowrap'
                        style={{ fontSize: '14px' }}
                      >
                        Product Name
                      </p>
                    )}
                  </div>
                  <div className='d-flex align-items-center gap-4 w-100 justify-content-end'>
                    <div className='col-md-4 col-xxl-3'>
                      <div className='input-file-wrapper'>
                        <label className='form-label fw-normal required'>
                          Product Status
                        </label>

                        <Select
                          styles={customStyles}
                          menuPortalTarget={document.body}
                          id='papproval'
                          placeholder='Product Status'
                          value={
                            values?.status && {
                              value: values?.status,
                              label: values?.status
                            }
                          }
                          options={productStatus}
                          onBlur={() => {
                            if (values?.status) {
                              setFieldValue('validation', {
                                ...values?.validation,
                                status: ''
                              })
                            } else {
                              setFieldValue('validation', {
                                ...values?.validation,
                                status: 'Product Status Required'
                              })
                            }
                          }}
                          onChange={(e) => {
                            changeHandler('status', e?.value, setFieldValue)
                            if (e?.value) {
                              setFieldValue('validation', {
                                ...values?.validation,
                                status: ''
                              })
                            } else {
                              setFieldValue('validation', {
                                ...values?.validation,
                                status: 'Product Status Required'
                              })
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div>
                      <label className='form-label fw-normal'>
                        Is Product Live
                      </label>

                      <div className='switch'>
                        <input
                          type='radio'
                          value={true}
                          id='yes'
                          checked={values?.live}
                          name='choice'
                          onChange={(e) => {
                            if (e?.target?.checked) setFieldValue('live', true)
                          }}
                        />
                        <label htmlFor='yes'>Yes</label>
                        <input
                          type='radio'
                          value={false}
                          id='no'
                          name='choice'
                          checked={!values?.live}
                          onChange={(e) => {
                            if (e?.target?.checked) setFieldValue('live', false)
                          }}
                        />
                        <label htmlFor='no'>No</label>
                        <span className='switchFilter'></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Card>
                <Card.Body>
                  <div>
                    <small>
                      <b>Company SKU :</b> {allState?.editData?.companySKUCode}
                    </small>
                  </div>
                  <div>
                    <small>
                      <b>Seller SKU : </b>
                      {allState?.editData?.sellerProducts?.sellerSKU}
                    </small>
                  </div>
                </Card.Body>
              </Card>

              {!isAllowWarehouseManagement && !isAllowPriceVariant && (
                <NotWarehouseAndPrice
                  allState={allState}
                  values={values}
                  setFieldValue={setFieldValue}
                  fetchCalculation={fetchCalculation}
                  calculation={calculation}
                  setCalculation={setCalculation}
                />
              )}

              {isAllowWarehouseManagement && !isAllowPriceVariant && (
                <WarehouseNotPrice
                  allState={allState}
                  values={values}
                  setFieldValue={setFieldValue}
                  fetchCalculation={fetchCalculation}
                  calculation={calculation}
                  setCalculation={setCalculation}
                />
              )}

              {!isAllowWarehouseManagement && isAllowPriceVariant && (
                <NotWarehousePrice
                  allState={allState}
                  values={values}
                  setFieldValue={setFieldValue}
                  fetchCalculation={fetchCalculation}
                  calculation={calculation}
                  setCalculation={setCalculation}
                />
              )}

              {isAllowWarehouseManagement && isAllowPriceVariant && (
                <WarehouseAndPrice
                  allState={allState}
                  values={values}
                  setFieldValue={setFieldValue}
                  fetchCalculation={fetchCalculation}
                  calculation={calculation}
                  setCalculation={setCalculation}
                />
              )}

              {values?.finalValidation && (
                <span className='text-danger'>{values?.finalValidation}</span>
              )}

              <Button
                variant='primary'
                className='d-flex align-items-center gap-2 justify-content-center col-md-1 fw-semibold'
                type='submit'
                onClick={(e) => {
                  e.preventDefault()
                  handleSubmit(values, setFieldValue)
                }}
              >
                Save
              </Button>
            </Offcanvas.Body>
          </Form>
        )}
      </Formik>
    </Offcanvas>
  )
}

export default QuickUpdate
