import React, { useState, useEffect } from 'react'
import { Table } from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../components/FormikControl'
import { Button } from 'react-bootstrap'
import axiosProvider from '../../../lib/AxiosProvider'
import ModelComponent from '../../../components/Modal'
import Loader from '../../../components/Loader'
import CustomToast from '../../../components/Toast/CustomToast'
import Swal from 'sweetalert2'
import { _SwalDelete } from '../../../lib/exceptionMessage'
import DeleteIcon from '../../../components/AllSvgIcon/DeleteIcon'
import EditIcon from '../../../components/AllSvgIcon/EditIcon'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { pageRangeDisplayed } from '../../../lib/GetBaseUrl'
import { customStyles } from '../../../components/customStyles'

const UserRole = (props) => {
  const [initialdata, setInnitialData] = useState(true)
  const [data, setData] = useState()
  const [filteredData, setFilteredData] = useState()
  const [modalShow, setModalShow] = useState(false)
  const [pageEntries, setPageEntries] = useState(10)
  const [onClickPage, setOnClickPage] = useState(1)
  const [pageCount, setPageCount] = useState(1)
  const [filteredPageCount, setFilteredPageCount] = useState(1)
  const [editData, setEditData] = useState()
  const [toast, setToast] = useState({
    show: false,
    text: null,
    variation: null
  })
  const [loading, setLoading] = useState(false)
  const { userId } = useSelector((state) => state?.user?.userInfo)
  const location = useLocation()

  useEffect(() => {
    fetchData()
  }, [pageEntries, onClickPage])

  useEffect(() => {
    if (modalShow === false) {
      setInnitialData(true)
    }
  }, [modalShow])

  const fetchData = async () => {
    setLoading(true)
    const response = await axiosProvider({
      method: 'GET',
      endpoint: 'GetAllRoleTypes',
      queryString: `?pageindex=${onClickPage}&pageSize=${pageEntries}`
      //      headers: {
      //     'Access-Control-Allow-Origin': '*',
      // }
    })
      .then((res) => {
        if (res.status === 200 && res.data.data.length > 0) {
          setData(res)
          setFilteredData(res)
          setPageCount(res.data?.pagination?.pageCount)
          setFilteredPageCount(res.data?.pagination?.pageCount)
        } else {
          setPageCount(0)
          setFilteredPageCount(0)
        }
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
      })
  }

  const handlePageClick = (event) => {
    setOnClickPage(event.selected + 1)
  }

  const initialValues = {
    name: ''
  }

  const initialValuesEdit = editData && { ...editData }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Please enter Role Name')
  })

  const onSubmit = async (values, { resetForm }) => {
    setLoading(true)
    const response = await axiosProvider({
      method: 'POST',
      endpoint: `CreateRoleType`,
      data: values,
      location: location?.pathname,
      userId
      //   headers: {
      //     'Access-Control-Allow-Origin': '*',
      //   'Content-Type': 'multipart/form-data',
      //   'Access-Control-Allow-Methods' : "*"
      // }
    })
      .then((res) => {
        if (res?.status === 200) {
          resetForm({ values: '' })
          fetchData()
          setModalShow(false)
        }
        setLoading(false)
        setToast({
          show: true,
          text: res?.data?.message,
          variation: res?.data?.code !== 200 ? 'error' : 'success'
        })

        setTimeout(() => {
          setToast({ ...toast, show: false })
        }, 2000)
      })
      .catch((err) => {
        setLoading(false)
      })
  }

  const handleDelete = async (id) => {
    setLoading(true)
    const response = await axiosProvider({
      method: 'DELETE',
      endpoint: `DeleteRoleType?Id=${id}`,
      userId,
      location: location.pathname
    })
      .then((res) => {
        if (res?.status === 200) {
          fetchData()
        }
        setLoading(false)
        setToast({
          show: true,
          text: res?.data?.message,
          variation: res?.data?.code !== 200 ? 'error' : 'success'
        })

        setTimeout(() => {
          setToast({ ...toast, show: false })
        }, 2000)
      })
      .catch((err) => {
        setLoading(false)
      })
  }

  const handleEditData = async (id) => {
    setLoading(true)
    const response = await axiosProvider({
      method: 'GET',
      endpoint: 'GetRoleTypeById',
      queryString: `?Id=${id}`
    })
      .then((res) => {
        if (res?.status === 200) {
          setEditData(res.data.data)
          setInnitialData(false)
          setModalShow(true)
        }
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
      })
  }

  const handleUpdate = async (values, { resetForm }) => {
    setLoading(true)
    const response = await axiosProvider({
      method: 'PUT',
      endpoint: `UpdateRoleType`,
      data: values,
      oldData: editData,
      location: location?.pathname,
      userId
      //     mode: 'no-cors',
      //     headers: {
      //         'Content-Type': 'multipart/form-data',
      //         'Access-Control-Allow-Origin':'*',
      // },
    })
      .then((res) => {
        if (res?.status === 200) {
          resetForm({ values: '' })
          fetchData()
          setModalShow(false)
        }
        setLoading(false)
        setToast({
          show: true,
          text: res?.data?.message,
          variation: res?.data?.code !== 200 ? 'error' : 'success'
        })

        setTimeout(() => {
          setToast({ ...toast, show: false })
        }, 2000)
      })
      .catch((err) => {
        setLoading(false)
      })
  }

  return (
    <>
      <div className='d-flex align-items-center justify-content-between mb-3 gap-3 flex-row-reverse'>
        <Button
          variant='warning'
          className='d-flex align-items-center gap-2 fw-semibold'
          onClick={() => setModalShow(true)}
        >
          <i className='m-icon m-icon--plusblack'></i>
          Create
        </Button>

        <div className='d-flex justify-content-between align-items-center'>
          <label className='me-1'>Show</label>
          <select
            styles={customStyles}
            name='dataget'
            id='parpageentries'
            className='form-select me-1'
            onChange={(e) => {
              setPageEntries(e.target.value)
            }}
          >
            <option value='10'>10</option>
            <option value='25'>25</option>
            <option value='50'>50</option>
            <option value='100'>100</option>
            <option value='200'>200</option>
            <option value='500'>500</option>
          </select>
          entries
        </div>
      </div>

      {toast?.show && (
        <CustomToast text={toast?.text} variation={toast?.variation} />
      )}

      <ModelComponent
        show={modalShow}
        modalsize={'md'}
        modalheaderclass={''}
        modeltitle={
          initialdata === true ? 'Create User Role' : 'Update User Role'
        }
        onHide={() => setModalShow(false)}
        btnclosetext={''}
        closebtnvariant={''}
        backdrop={'static'}
        formbuttonid={'mainCountry'}
        submitname={initialdata === true ? 'Create' : 'Update'}
      >
        <Formik
          initialValues={
            initialdata === true ? initialValues : initialValuesEdit
          }
          validationSchema={validationSchema}
          onSubmit={initialdata === true ? onSubmit : handleUpdate}
        >
          {({ values, setFieldValue }) => (
            <Form id='mainCountry'>
              <div className='row'>
                <div className='col-md-12'>
                  {loading && <Loader />}
                  <div className='input-file-wrapper mb-3'>
                    <FormikControl
                      isRequired
                      control='input'
                      label='Role Name'
                      type='text'
                      name='name'
                      placeholder='Enter role name'
                    />
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </ModelComponent>

      {loading && !modalShow && <Loader />}

      <Table responsive className='align-middle table-list'>
        <thead>
          <tr>
            <th>Role Name</th>
            <th className='text-center'>Action</th>
          </tr>
        </thead>
        <tbody>
          {filteredData?.data?.data?.length > 0 ? (
            filteredData?.data?.data?.map((data, index) => (
              <tr key={data.id}>
                <td>{data.name}</td>
                <td className='text-center'>
                  <div className='d-flex gap-2 justify-content-center'>
                    <span
                      onClick={() => {
                        handleEditData(data?.id)
                      }}
                    >
                      <EditIcon bg={'bg'} />
                    </span>
                    <span
                      onClick={() => {
                        Swal.fire({
                          title: _SwalDelete.title,
                          text: _SwalDelete.text,
                          icon: _SwalDelete.icon,
                          showCancelButton: _SwalDelete.showCancelButton,
                          confirmButtonColor: _SwalDelete.confirmButtonColor,
                          cancelButtonColor: _SwalDelete.cancelButtonColor,
                          confirmButtonText: _SwalDelete.confirmButtonText,
                          cancelButtonText: _SwalDelete.cancelButtonText
                        }).then((result) => {
                          if (result.isConfirmed) {
                            handleDelete(data?.id)
                          } else if (result.isDenied) {
                          }
                        })
                      }}
                    >
                      <DeleteIcon bg={'bg'} />
                    </span>
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td>{filteredData?.data?.message}</td>
            </tr>
          )}
        </tbody>
      </Table>

      <ReactPaginate
        className='list-inline m-cst--pagination d-flex justify-content-end gap-1'
        breakLabel='...'
        nextLabel=''
        onPageChange={handlePageClick}
        pageRangeDisplayed={pageRangeDisplayed}
        pageCount={filteredPageCount}
        previousLabel=''
        renderOnZeroPageCount={null}
      />
    </>
  )
}

export default UserRole
