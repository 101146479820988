import React, { useState, useEffect } from 'react'
import { Col, Row, Table } from 'react-bootstrap'
import TooltipComponent from '../../../components/Tooltip'
import { Link, useNavigate } from 'react-router-dom'
import Select from 'react-select'
import ModelComponent from '../../../components/Modal'
import ReactPaginate from 'react-paginate'
import axiosProvider from '../../../lib/AxiosProvider'
import Loader from '../../../components/Loader'
import CustomToast from '../../../components/Toast/CustomToast'
import { useDispatch, useSelector } from 'react-redux'
import { setPageTitle } from '../../redux/slice/pageTitleSlice'
import Swal from 'sweetalert2'
import { _SwalDelete } from '../../../lib/exceptionMessage'
import EditIcon from '../../../components/AllSvgIcon/EditIcon'
import DeleteIcon from '../../../components/AllSvgIcon/DeleteIcon'
import {
  allCrudNames,
  allPages,
  checkPageAccess
} from '../../../lib/AllPageNames'
import NotFound from '../../NotFound/NotFound'
import { pageRangeDisplayed } from '../../../lib/GetBaseUrl'
import { customStyles } from '../../../components/customStyles'

const AssignCategory = () => {
  const navigate = useNavigate()
  const [categoriesToShowInList, setCategoriesToShowInList] = useState()
  const [categories, setCategories] = useState()
  const [pageEntries, setPageEntries] = useState(10)
  const [onClickPage, setOnClickPage] = useState(1)
  const [catName, setCatName] = useState()
  const [pageCount, setPageCount] = useState(1)
  const [modalSizeShow, setSizeModalShow] = useState(false)
  const [modalAttributesShow, setAttributesModalShow] = useState(false)
  const [modalSpecificationShow, setSpecificationModalShow] = useState(false)
  const [specSpecificationData, setSpecSpecificationData] = useState()
  const [attributesModalData, setAttributesModalData] = useState()
  const [sizeSpecificationData, setSizeSpecificationData] = useState()
  const [SpecificationData, setSpecificationData] = useState()
  const [loading, setLoading] = useState(false)
  const [toast, setToast] = useState({
    show: false,
    text: null,
    variation: null
  })

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setPageTitle('Assign Attributes'))
  }, [])
  const { pageAccess } = useSelector((state) => state.user)

  // const customStyles = {
  //   menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
  //   menu: (provided) => ({ ...provided, zIndex: 9999 }),
  //   control: (base, state) => ({
  //     ...base,
  //     background: '#fff',
  //     borderRadius: '0.375rem',
  //     borderColor: state.isFocused ? '#001B5A' : '#CED4DA',
  //     boxShadow: state.isFocused ? '0 0 0 0.25rem #001b5a1a;' : null,
  //     '&:hover': {
  //       borderColor: state.isFocused ? '#001B5A' : '#CED4DA'
  //     }
  //   }),
  //   option: (styles, { data, isDisabled, isFocused, isSelected, isActive }) => {
  //     return {
  //       ...styles,
  //       backgroundColor: isFocused ? '#001B5A' : 'null',
  //       color: isFocused ? '#fff' : '#fff',
  //       color: isSelected ? '#0DCAF0' : '#333 ',
  //       '&:hover': {
  //         color: isSelected ? '#fff' : '#fff'
  //       }
  //     }
  //   }
  // }

  const getUniqueListBy = (arr, key) => {
    return [...new Map(arr.map((item) => [item[key], item])).values()]
  }

  const handlePageClick = (event) => {
    setOnClickPage(event.selected + 1)
  }

  const fetchSpecSpecificationData = async (id) => {
    const response = await axiosProvider({
      method: 'GET',
      endpoint: 'AssignSpecValuesToCategory/byAssignSpecID',
      queryString: `?assignSpecId=${id}`
    })
      .then((res) => {
        if (res.status === 200) {
          setSpecSpecificationData(res?.data?.data)
        } else {
          setSizeSpecificationData(null)
        }
      })
      .catch((err) => {
        alert(err)
      })
  }

  const fetchSizeSpecificationData = async (id) => {
    const response = await axiosProvider({
      method: 'GET',
      endpoint: 'AssignSizeValuesToCategory/ByAssignSpecId',
      queryString: `?AssignSpecID=${id}`
    })
      .then((res) => {
        if (res.status === 200) {
          let tempArr = res?.data?.data
          let emptyArr = []
          tempArr.map((x) => {
            if (emptyArr.length > 0) {
              emptyArr.map((y) => {
                if (y.sizeTypeID === x.sizeTypeID) {
                  let newData = {
                    ...y,
                    sizeName: `${y.sizeName}, ${x.sizeName}`
                  }
                  emptyArr.push(newData)
                } else {
                  emptyArr.push(x)
                }
              })
            } else {
              emptyArr.push(x)
            }
          })

          emptyArr = getUniqueListBy(emptyArr, 'sizeTypeID')

          setSizeSpecificationData(emptyArr)
        } else {
          setSizeSpecificationData(null)
        }
      })
      .catch((err) => {
        alert(err)
      })
  }

  const fetchCategories = async () => {
    const response = await axiosProvider({
      method: 'GET',
      endpoint: 'SubCategory',
      queryString: `?pageindex=0&PageSize=0`
    })
      .then((res) => {
        if (res?.status === 200) {
          setCategoriesToShowInList(res?.data?.data)
        }
      })
      .catch((err) => {})
  }

  useEffect(() => {
    fetchData()
    !categoriesToShowInList && fetchCategories()
  }, [pageEntries, onClickPage])

  const handleDelete = async (id) => {
    const response = await axiosProvider({
      method: 'DELETE',
      endpoint: `AssignSpecificationToCategory?id=${id}`
    })
      .then((res) => {
        if (res?.status === 200) {
          fetchData()
        }
        setToast({
          show: true,
          text: res?.data?.message,
          variation: res?.data?.code !== 200 ? 'error' : 'success'
        })

        setTimeout(() => {
          setToast({ ...toast, show: false })
        }, 2000)
      })
      .catch((err) => {})
  }

  const fetchData = async () => {
    setLoading(true)
    const response = await axiosProvider({
      method: 'GET',
      endpoint: 'AssignSpecificationToCategory',
      queryString: `?pageindex=${onClickPage}&PageSize=${pageEntries}`
    })
      .then((res) => {
        if (res.status === 200) {
          setCategories(res.data)
          setPageCount(res.data?.pagination.pageCount)
        } else {
        }
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
      })
  }

  const fetchSelectedData = async (id) => {
    if (id) {
      const response = await axiosProvider({
        method: 'GET',
        endpoint: 'AssignSpecificationToCategory/getListByCatId',
        queryString: `?catId=${id}`
      })
        .then((res) => {
          if (res.status === 200) {
            setCategories(res.data)
            setPageCount(res.data?.pagination?.pageCount)
          } else {
            setCategories(res?.data)
            setPageCount(0)
          }
        })
        .catch((err) => {})
    } else {
      fetchData()
    }
  }

  return checkPageAccess(
    pageAccess,
    allPages?.assignAttributes,
    allCrudNames?.read
  ) ? (
    <>
      <div className='card'>
        <div className='card-body'>
          <Row>
            <Col md={5}>
              <div className='input-select-wrapper flex-fill'>
                <Select
                  menuPortalTarget={document.body}
                  styles={customStyles}
                  isClearable={true}
                  menuPosition={'fixed'}
                  options={
                    categoriesToShowInList &&
                    categoriesToShowInList?.map(({ id, pathNames }) => ({
                      label: pathNames,
                      value: id
                    }))
                  }
                  onChange={(e) => {
                    fetchSelectedData(e?.value)
                  }}
                  placeholder={'Select Categories'}
                />
              </div>
            </Col>
            <Col>
              <div className='d-flex align-items-center gap-2 flex-row-reverse'>
                {checkPageAccess(
                  pageAccess,
                  allPages?.assignAttributes,
                  allCrudNames?.write
                ) && (
                  <Link
                    className='btn btn-warning d-inline-flex align-items-center gap-2 fw-semibold'
                    to='/category/assign-category/manage-filter'
                  >
                    <i className='m-icon m-icon--plusblack'></i>
                    Create Attributes
                  </Link>
                )}
                <div className='d-flex align-items-center'>
                  <label className='me-1'>Show</label>
                  <select
                    name='dataget'
                    id='parpageentries'
                    className='form-select me-1'
                    onChange={(e) => {
                      setPageEntries(e.target.value)
                    }}
                  >
                    <option value='10'>10</option>
                    <option value='25'>25</option>
                    <option value='50'>50</option>
                    <option value='100'>100</option>
                    <option value='200'>200</option>
                    <option value='500'>500</option>
                  </select>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      {toast?.show && (
        <CustomToast text={toast?.text} variation={toast?.variation} />
      )}

      {loading && <Loader />}

      <div className='card'>
        <div className='card-body'>
          <Table responsive className='align-middle table-list'>
            <thead>
              <tr>
                <th>Categories</th>
                <th className='text-center'>Size</th>
                <th className='text-center'>Attributes</th>
                <th className='text-center'>Specification</th>
                {checkPageAccess(pageAccess, allPages?.assignAttributes, [
                  allCrudNames?.update,
                  allCrudNames?.delete
                ]) && <th className='text-center'>Action</th>}
              </tr>
            </thead>
            <tbody>
              {categories && categories?.data?.length > 0 ? (
                categories?.data.map((category) => (
                  <tr key={category.id}>
                    <td>
                      <div>
                        <p className='fw-bold mb-1'>{category.categoryName}</p>
                        <small>{category.categoryPathNames}</small>
                      </div>
                    </td>
                    <td>
                      {category.isAllowSize ? (
                        <button
                          className='btn btn-ct-size'
                          onClick={() => {
                            setCatName(category.categoryName)
                            fetchSizeSpecificationData(category.id)
                            setSizeModalShow(true)
                          }}
                        >
                          Size
                        </button>
                      ) : (
                        <button className='btn btn-outline-secondary disabled'>
                          Size
                        </button>
                      )}
                    </td>
                    <td>
                      {category.isAllowColors ? (
                        <button
                          className='btn btn-ct-specification'
                          onClick={() => {
                            setCatName(category.categoryName)
                            setAttributesModalData(category)
                            setAttributesModalShow(true)
                          }}
                        >
                          Attributes
                        </button>
                      ) : (
                        <button className='btn btn-outline-secondary disabled'>
                          Attributes
                        </button>
                      )}
                    </td>
                    <td className='text-center'>
                      {category.isAllowSpecifications ? (
                        <button
                          className='btn btn-secondary'
                          onClick={() => {
                            setCatName(category.categoryName)
                            fetchSpecSpecificationData(category.id)
                            setSpecificationModalShow(true)
                          }}
                        >
                          Specification
                        </button>
                      ) : (
                        <button className='btn btn-outline-secondary disabled'>
                          Specification
                        </button>
                      )}
                    </td>
                    {checkPageAccess(pageAccess, allPages?.assignAttributes, [
                      allCrudNames?.update,
                      allCrudNames?.delete
                    ]) && (
                      <td className='text-center'>
                        <div className='d-flex gap-2 justify-content-center'>
                          {checkPageAccess(
                            pageAccess,
                            allPages?.assignAttributes,
                            allCrudNames?.update
                          ) && (
                            <span
                              onClick={() => {
                                navigate(
                                  `/category/assign-category/manage-filter/${category.id}`
                                )
                              }}
                            >
                              <EditIcon bg={'bg'} />
                            </span>
                          )}
                          {checkPageAccess(
                            pageAccess,
                            allPages?.assignAttributes,
                            allCrudNames?.delete
                          ) && (
                            <span
                              onClick={() => {
                                Swal.fire({
                                  title: _SwalDelete.title,
                                  text: _SwalDelete.text,
                                  icon: _SwalDelete.icon,
                                  showCancelButton:
                                    _SwalDelete.showCancelButton,
                                  confirmButtonColor:
                                    _SwalDelete.confirmButtonColor,
                                  cancelButtonColor:
                                    _SwalDelete.cancelButtonColor,
                                  confirmButtonText:
                                    _SwalDelete.confirmButtonText,
                                  cancelButtonText: _SwalDelete.cancelButtonText
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    handleDelete(category.id)
                                  } else if (result.isDenied) {
                                  }
                                })
                              }}
                            >
                              <DeleteIcon bg={'bg'} />
                            </span>
                          )}
                        </div>
                      </td>
                    )}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={4} className='text-center'>
                    {categories?.message}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>

          {}
          <ReactPaginate
            className='list-inline m-cst--pagination d-flex justify-content-end gap-1'
            breakLabel='...'
            nextLabel=''
            onPageChange={handlePageClick}
            pageRangeDisplayed={pageRangeDisplayed}
            pageCount={pageCount}
            previousLabel=''
            renderOnZeroPageCount={null}
          />
        </div>
      </div>

      <ModelComponent
        show={modalSizeShow}
        modalsize={'md'}
        modalheaderclass={''}
        modeltitle={'Selected Category Size'}
        onHide={() => {
          setSizeSpecificationData(null)
          setCatName()
          setSizeModalShow(false)
        }}
        btnclosetext={''}
        closebtnvariant={''}
        backdrop={'static'}
        formbuttonid={''}
      >
        <Table responsive className='align-middle table-list' bordered>
          {sizeSpecificationData &&
            sizeSpecificationData.map((elem) => (
              <tbody key={elem.id}>
                <tr>
                  <td>
                    <th className='text-nowrap'>Size Type</th>
                  </td>
                  <td>{elem.sizeTypeName}</td>
                </tr>
                <tr>
                  <td>
                    <th className='text-nowrap'>Size Name</th>
                  </td>
                  <td>{elem.sizeName}</td>
                </tr>
                <tr>
                  <td>
                    <th className='text-nowrap'>Allow As Filter</th>
                  </td>
                  <td>{elem?.isAllowSizeInFilter ? 'Yes' : 'No'}</td>
                </tr>
                <tr>
                  <td>
                    <th className='text-nowrap'>Allow As Title</th>
                  </td>
                  <td>{elem?.isAllowSizeInTitle ? 'Yes' : 'No'}</td>
                </tr>
                {elem?.isAllowSizeInTitle && (
                  <tr>
                    <td>
                      <th className='text-nowrap'>Title Sequence</th>
                    </td>
                    <td>
                      {elem?.titleSequenceOfSize
                        ? elem?.titleSequenceOfSize
                        : '0'}
                    </td>
                  </tr>
                )}
                {/* <tr>
                  <td>
                    <th className='text-nowrap'>Allow As Comparision</th>
                  </td>
                  <td>{elem?.isAllowSizeInComparision ? 'Yes' : 'No'}</td>
                </tr> */}
              </tbody>
            ))}
        </Table>
      </ModelComponent>

      <ModelComponent
        show={modalAttributesShow}
        modalsize={'md'}
        modalheaderclass={''}
        modeltitle={'Selected Attributes'}
        onHide={() => {
          setAttributesModalData(null)
          setCatName()
          setAttributesModalShow(false)
        }}
        btnclosetext={''}
        closebtnvariant={''}
        backdrop={'static'}
        formbuttonid={''}
      >
        <Table responsive className='align-middle table-list'>
          {/* <thead className="align-middle">
            <tr></tr>
          </thead> */}
          {attributesModalData && (
            <tbody key={attributesModalData.id}>
              <tr>
                <td>
                  <th className='text-nowrap'>Allow As Filter</th>
                </td>
                <td>
                  {attributesModalData?.isAllowColorsInFilter ? 'Yes' : 'No'}
                </td>
              </tr>
              <tr>
                <td>
                  <th className='text-nowrap'>Allow As Title</th>
                </td>
                <td>
                  {attributesModalData?.isAllowColorsInTitle ? 'Yes' : 'No'}
                </td>
              </tr>
              {attributesModalData?.isAllowColorsInTitle && (
                <tr>
                  <td>
                    <th className='text-nowrap'>Title Sequence</th>
                  </td>
                  <td>
                    {attributesModalData?.titleSequenceOfColor
                      ? attributesModalData?.titleSequenceOfColor
                      : '0'}
                  </td>
                </tr>
              )}
              {/* <tr>
                <td>
                  <th className='text-nowrap'>Allow As Comparision</th>
                </td>
                <td>
                  {attributesModalData?.isAllowColorsInComparision
                    ? 'Yes'
                    : 'No'}
                </td>
              </tr> */}
            </tbody>
          )}
        </Table>
      </ModelComponent>

      <ModelComponent
        show={modalSpecificationShow}
        modalsize={'md'}
        modalheaderclass={''}
        modeltitle={`Specifications of ${catName}`}
        onHide={() => {
          setCatName()
          setSpecificationData(null)
          setSpecificationModalShow(false)
        }}
        btnclosetext={''}
        closebtnvariant={''}
        backdrop={'static'}
        formbuttonid={''}
      >
        <Table responsive hover className='align-middle table-list'>
          <thead className='align-middle'>
            <tr>
              <th className='text-nowrap'>Name</th>
              <th className='text-nowrap'>Type</th>
              <th className='text-nowrap'>Type Value</th>
            </tr>
          </thead>
          <tbody>
            {specSpecificationData &&
              specSpecificationData.map((elem) => (
                <tr key={Math.floor(Math.random() * 1000000)}>
                  <td>{elem.specificationName}</td>
                  <td>{elem.specificationTypeName}</td>
                  <td
                    className={
                      elem.specificationTypeValueName ? '' : 'text-center'
                    }
                  >
                    {elem.specificationTypeValueName
                      ? elem.specificationTypeValueName
                      : '-'}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </ModelComponent>
    </>
  ) : (
    <NotFound />
  )
}

export default AssignCategory