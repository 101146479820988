export const _exception = {
  message: 'Something went wrong',
  variation: 'error'
}

export const _SwalDelete = {
  title: 'Are you sure want to delete?',
  text: 'You will not be able to undo this action.',
  icon: 'warning',
  showCancelButton: true,
  // confirmButtonColor: '#dc3545',
  confirmButtonColor: '#dc3545',
  cancelButtonColor: '#c9c9c9',
  otherButtonColor: '#0A0A0A',  
  confirmButtonText: 'Delete',
  cancelButtonText: 'Cancel',
  confirmButtonColor2: '#002988'
}
