import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { setPageTitle } from '../redux/slice/pageTitleSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { allCrudNames, allPages, checkPageAccess } from '../../lib/AllPageNames'
import NotFound from '../NotFound/NotFound'
import MainNotification from './MainNotification'

const Notification = () => {
  const [activeToggle, setActiveToggle] = useState('notification')
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setPageTitle('notification'))
  }, [])
  const { pageAccess } = useSelector((state) => state.user)

  return checkPageAccess(
    pageAccess,
    allPages?.notification,
    allCrudNames?.read
  ) ? (
    <>
      <div className='card overflow-hidden'>
        {checkPageAccess(
          pageAccess,
          allPages?.notification,
          allCrudNames?.read
        ) && (
          <div className='card-body p-0'>
            <div className='nav-tabs-horizontal nav nav-tabs'>
              <Link
                onClick={() => setActiveToggle('logs')}
                data-toggle='tab'
                className={`nav-link fw-semibold ${
                  activeToggle === 'notification' ? 'active show' : ''
                }`}
              >
                <span className='nav-span'>
                  Notification
                  <hr />
                </span>
              </Link>
            </div>
            <div className='tab-content p-3'>
              <div
                id='notification'
                className={`tab-pane fade ${
                  activeToggle === 'notification' ? 'active show' : ''
                }`}
              >
                {activeToggle === 'notification' && <MainNotification />}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  ) : (
    <NotFound />
  )
}

export default Notification
