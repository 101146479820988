import React, { useEffect, useRef, useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Badge from 'react-bootstrap/Badge'
import Slider from 'react-slick'
import widgetimg from '../../images/homepageadmin/watch.png'
import { Button } from 'react-bootstrap'
import widgetimgheartimg from '../../images/homepageadmin/product-heart.png'
import AddtoWishlist from '../../images/homepageadmin/Add-to-Wishlist.png'
import compare from '../../images/homepageadmin/compare.png'
import Quickview from '../../images/homepageadmin/Quick-view.png'
import axiosProvider from '../../lib/AxiosProvider'
import { _productImg_ } from '../../lib/ImagePath'
import Swal from 'sweetalert2'
import { _SwalDelete } from '../../lib/exceptionMessage'
import Loader from '../Loader'
import ComponentEdit from './ComponentEdit'
import ImgEditComponet from './ImgEditComponet'
import DynamicPositionComponent from './HeadingComponent'

function HomeProductWidget({
  layoutsInfo,
  section,
  setLayoutDetails,
  layoutDetails,
  handleDelete,
  fromLendingPage,
  handleImgDelete,
  modalShow,
  setModalShow
}) {
  const [data, setData] = useState()
  const sliderRefs = useRef(null)

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: data?.data?.data?.length > 7 ? 6 : data?.data?.data?.length,
    slidesToScroll: 1,
    centerMode: true
  }
  const prepareIdsData = (data) => {
    return data?.map((option) => option?.productId).join(',') ?? ''
  }

  const categoryId = section?.category_id ? section?.category_id : 0
  const topProduct = section?.top_products ? section?.top_products : 0
  const productId =
    section?.columns?.left?.single?.length > 0
      ? prepareIdsData(section?.columns?.left?.single)
      : ''

  const fetchData = async (sectionId) => {
    const response = await axiosProvider({
      method: 'GET',
      endpoint: 'ManageHomePageSections/GetProductHomePageSection',
      queryString: `?categoryId=${!productId ? categoryId : 0}&topProduct=${
        !productId ? topProduct : 0
      }&productId=${productId}`
    })
      .then((res) => {
        if (res.status === 200) {
          setData({ ...res, sectionId })
        }
      })
      .catch((err) => {})
  }

  useEffect(() => {
    fetchData(section?.section_id)
  }, [section])

  const sectionDelete = (id, specificImgDelete = false) => {
    Swal.fire({
      title: _SwalDelete.title,
      text: _SwalDelete.text,
      icon: _SwalDelete.icon,
      showCancelButton: _SwalDelete.showCancelButton,
      confirmButtonColor: _SwalDelete.confirmButtonColor,
      cancelButtonColor: _SwalDelete.cancelButtonColor,
      confirmButtonText: _SwalDelete.confirmButtonText,
      cancelButtonText: _SwalDelete.cancelButtonText
    }).then((result) => {
      if (result.isConfirmed) {
        if (specificImgDelete) {
          let endpoint = fromLendingPage
            ? `LendingPageSectionDetails?id=${id}`
            : `ManageHomePageDetails?id=${id}`
          handleImgDelete(endpoint)
        } else {
          handleDelete(section?.section_id, true)
        }
      } else if (result.isDenied) {
      }
    })
  }

  const sectionEdit = () => {
    setModalShow({
      ...modalShow,
      show: !modalShow?.show,
      layoutId: layoutsInfo?.layout_id,
      layoutName: layoutsInfo?.layout_name?.toLowerCase()?.includes('product')
        ? 'Product List'
        : layoutsInfo?.layout_name,
      layoutTypeId: layoutsInfo?.layout_type_id,
      layoutTypeName: layoutsInfo?.layout_type_name,
      sectionId: section?.section_id,
      type: 'normalLayoutSelection'
    })
  }

  return (
    <ComponentEdit sectionEdit={sectionEdit} sectionDelete={sectionDelete} sectionStatus={section?.status}>
      <DynamicPositionComponent
        heading={section?.title}
        paragraph={section?.sub_title}
        headingPosition={
          section?.title_position?.toLowerCase() === 'left'
            ? 'start'
            : section?.title_position?.toLowerCase() === 'center'
            ? 'center'
            : 'end'
        }
        buttonPosition={
          section?.link_in?.toLowerCase() === 'section'
            ? section?.link_position?.toLowerCase() === 'left'
              ? 'start'
              : section?.link_position?.toLowerCase() === 'center'
              ? 'center'
              : 'end'
            : section?.title_position?.toLowerCase() === 'left'
            ? 'end'
            : section?.title_position?.toLowerCase() === 'right'
            ? 'start'
            : 'center'
        }
        buttonPositionDirection={section?.link_in?.toLowerCase()}
        link_text={section?.link_text}
        link={section?.link}
        backgroundColor={section?.background_color}
      >
        <div className='mt-5 position-relative pv-product-widgetmain'>
          <Slider {...settings} ref={sliderRefs} className='pv-product-widget'>
            {data?.data?.data?.map((obj) => (
              <Card
                className='pv-widgetinner-col position-relative pv-globle-imgedit-btn'
                key={Math.floor(Math.random() * 1000000)}
              >
                <div className='pv-product-img'>
                  <Card.Img
                    variant='top'
                    className='p-3'
                    src={`${process.env.REACT_APP_IMG_URL}${_productImg_}${obj?.image1}`}
                  />
                </div>

                {/* <div className='position-relative'>
                <div className='pv-widhet-hoverbtn m-0 d-flex justify-content-around'>
                  <div>
                    <Button className='pv-product-btn pv-btn-whishlist'>
                      Wishlist
                    </Button>
                  </div>
                  {layoutsInfo?.layout_class?.toLowerCase() ===
                    'with-price' && (
                    <div>
                      <Button className='pv-product-btn pv-btn-add-cart'>
                        Add to cart
                      </Button>
                    </div>
                  )}
                </div>
              </div> */}
                <Card.Body>
                  <div className='card_details'>
                    <Card.Title className='bold'>{obj.brandName}</Card.Title>
                    <Card.Text className='mb-0'>{obj.productName}</Card.Text>
                    {/* <Card.Text className='mb-0'>Sizes: XS</Card.Text> */}
                    {/* {layoutsInfo?.layout_class?.toLowerCase() !==
                    'without-price' && (
                    <Card.Text className='d-flex align-items-center gap-2 mb-0'>
                      {' '}
                      <strong>Rs. {obj.sellingPrice}</strong>{' '}
                      <span>
                        <del>Rs.{obj.mrp}</del>
                      </span>
                      <small style={{ color: '#BC405D' }}>
                        ({obj?.discount}% OFF)
                      </small>{' '}
                    </Card.Text>
                  )} */}
                  </div>
                </Card.Body>
                {/* <ImgEditComponet
                showDeleteIcon={false}
                showEditIcon={false}
                sectionDelete={() => {
                  sectionDelete(obj?.section_details_id, true)
                }}
              /> */}
              </Card>
            ))}
          </Slider>
          <div className='pv-thumbline-btn-main'>
            <button
              className='slide_btn pv-thumbline-slide_left z-2 border-0 position-absolute'
              style={{ top: '50%', left: '0px' }}
              onClick={() => {
                sliderRefs?.current?.slickPrev()
              }}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='12.025'
                height='21.549'
                viewBox='0 0 12.025 21.549'
              >
                <g id='Icon' transform='translate(1.25 1.25)'>
                  <g id='Icon-2' data-name='Icon' transform='translate(0)'>
                    <path
                      id='Path'
                      d='M17.025,25.3a1.246,1.246,0,0,1-.884-.366L6.616,15.409a1.25,1.25,0,0,1,0-1.768l9.525-9.525a1.25,1.25,0,0,1,1.768,1.768L9.268,14.525l8.641,8.641a1.25,1.25,0,0,1-.884,2.134Z'
                      transform='translate(-7.5 -5)'
                      fill='#3d3d3d'
                    />
                  </g>
                </g>
              </svg>
            </button>
            <button
              className='slide_btn pv-thumbline-slide_right z-2 border-0 position-absolute'
              style={{ top: '50%', right: '0px' }}
              onClick={() => {
                sliderRefs?.current?.slickNext()
              }}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='12.025'
                height='21.549'
                viewBox='0 0 12.025 21.549'
              >
                <g id='Icon' transform='translate(-6.25 -3.75)'>
                  <g id='Icon-2' data-name='Icon' transform='translate(7.5 5)'>
                    <path
                      id='Path'
                      d='M7.5,25.3a1.25,1.25,0,0,1-.884-2.134l8.641-8.641L6.616,5.884A1.25,1.25,0,0,1,8.384,4.116l9.525,9.525a1.25,1.25,0,0,1,0,1.768L8.384,24.933A1.246,1.246,0,0,1,7.5,25.3Z'
                      transform='translate(-7.5 -5)'
                      fill='#3d3d3d'
                    />
                  </g>
                </g>
              </svg>
            </button>
          </div>
        </div>
      </DynamicPositionComponent>
    </ComponentEdit>
  )
}

export default HomeProductWidget
