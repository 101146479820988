import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  allCrudNames,
  allPages,
  checkPageAccess
} from '../../../lib/AllPageNames'
import NotFound from '../../NotFound/NotFound'
import { setPageTitle } from '../../redux/slice/pageTitleSlice'
import MainRequestInformation from './MainRequestInformation'

const ManageRequestInformation = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setPageTitle('Request Information'))
  }, [])
  const { pageAccess } = useSelector((state) => state.user)

  const [activeToggle, setActiveToggle] = useState('request-info')

  return checkPageAccess(
    pageAccess,
    allPages?.requestInformation,
    allCrudNames?.read
  ) ? (
    <>
      <div className='card overflow-hidden'>
        <div className='card-body p-0'>
          <div className='nav-tabs-horizontal nav nav-tabs'>
            {checkPageAccess(
              pageAccess,
              allPages?.requestInformation,
              allCrudNames?.read
            ) && (
              <Link
                onClick={() => setActiveToggle('request-info')}
                data-toggle='tab'
                className={`nav-link fw-semibold ${
                  activeToggle === 'request-info' ? 'active show' : ''
                }`}
              >
                <span className='nav-span'>
                  Request Information
                  <hr />
                </span>
              </Link>
            )}
          </div>

          <div className='tab-content p-3'>
            {checkPageAccess(
              pageAccess,
              allPages?.requestInformation,
              allCrudNames?.read
            ) && (
              <div
                id='brand'
                className={`tab-pane fade ${
                  activeToggle === 'request-info' ? 'active show' : ''
                }`}
              >
                {activeToggle === 'request-info' && <MainRequestInformation />}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  ) : (
    <NotFound />
  )
}

export default ManageRequestInformation
