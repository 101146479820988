import React, { useState, useRef, useEffect } from 'react'
import FormikControl from '../../components/FormikControl'
import { InputGroup } from 'react-bootstrap'
import { preparePerWarehouseStock } from '../../lib/GetBaseUrl'
import { maxWarehouseQty } from '../../lib/AllStaticVariables'
import { _integerRegex_ } from '../../lib/Regex'

const ManageSizeWarehouseQuantities = ({
  productPrice,
  values,
  setFieldValue,
  allState
}) => {
  return (
    <div className='mb-3'>
      <h5 className='mb-2 head_h3'>
        {productPrice?.sizeName
          ? `${productPrice?.sizeName} Size Warehouse Quantities`
          : 'Warehouse Quantity'}
      </h5>
      <div className='row'>
        <div className='col-md-3'>
          <div className='input-file-wrapper mb-3'>
            <FormikControl
              disabled
              control='input'
              label='Total Stock'
              id={`${productPrice?.sizeID}${productPrice?.sizeName}`}
              type='text'
              name={`${productPrice?.sizeID}${productPrice?.sizeName}`}
              placeholder='Stock'
              value={productPrice?.quantity}
            />
          </div>
        </div>
        {/* {!values?.productId || values?.isExistingProduct ? ( */}
        <div className='col-md-3'>
          <div className='input-file-wrapper mb-3'>
            <FormikControl
              control='input'
              disabled={productPrice?.manageWarehouseStock ? true : false}
              label='Warehouse Stock'
              id='sw'
              type='text'
              value={productPrice?.perWarehouseStock}
              name={`${productPrice?.perWarehouseStock}${productPrice?.sizeID}${productPrice?.sizeName}`}
              placeholder='Quantity'
              onChange={(e) => {
                let inputValue = Number(e?.target?.value)
                const isValid = _integerRegex_.test(inputValue)
                if (
                  (isValid ||
                    (inputValue >= 0 && inputValue <= maxWarehouseQty)) &&
                  !isNaN(inputValue)
                ) {
                  let productPriceArray = values?.productPrices ?? []

                  productPriceArray = productPriceArray?.filter((product) => {
                    if (product.sizeID) {
                      return product.sizeID === productPrice?.sizeID
                    } else {
                      return product
                    }
                  })[0]

                  productPriceArray = {
                    ...productPriceArray,
                    perWarehouseStock: inputValue
                  }

                  setFieldValue(
                    'productPrices',
                    preparePerWarehouseStock(
                      allState,
                      productPriceArray,
                      values
                    )
                  )
                  setFieldValue('perWarehouseStock', inputValue)
                }
              }}
            />
          </div>
        </div>
        {/* ) : (
          <></>
        )} */}

        <div className='col-md-3 mt-auto mb-3'>
          <InputGroup className='custom_checkbox'>
            <InputGroup.Checkbox
              // disabled={
              //   !values?.productId || values?.isExistingProduct ? false : true
              // }
              checked={productPrice?.manageWarehouseStock}
              id={productPrice?.sizeName}
              onChange={(e) => {
                let productPriceArray = values?.productPrices ?? []

                productPriceArray = productPriceArray?.filter((product) => {
                  if (product.sizeID) {
                    return product.sizeID === productPrice?.sizeID
                  } else {
                    return product
                  }
                })[0]

                productPriceArray = {
                  ...productPriceArray,
                  manageWarehouseStock: e?.target?.checked
                }

                setFieldValue(
                  'productPrices',
                  preparePerWarehouseStock(allState, productPriceArray, values)
                )
              }}
            />
            <label className='custom_label' htmlFor={productPrice?.sizeName}>
              Inventory Tracker
            </label>
          </InputGroup>
        </div>
      </div>
      {productPrice?.manageWarehouseStock && (
        <div className='row'>
          {productPrice?.productWarehouses &&
            productPrice?.productWarehouses?.map((warehouseData) => (
              <div className='col-md-3' key={warehouseData?.id}>
                <div className='position-relative setInput_main d-flex align-items-center mb-3 mt-3'>
                  <FormikControl
                    disabled
                    control='input'
                    id={`${productPrice?.sizeID}${warehouseData.warehouseName}`}
                    type='text'
                    name={`${productPrice?.sizeID}${warehouseData.warehouseName}`}
                    value={warehouseData.warehouseName}
                    placeholder={warehouseData.warehouseName}
                  />
                  <div className='setInput_field'>
                    <FormikControl
                      control='input'
                      id={warehouseData?.warehouseName}
                      className='mb-0'
                      type='text'
                      name={`${productPrice?.sizeID}${warehouseData.warehouseName}${warehouseData?.quantity}`}
                      value={warehouseData?.quantity}
                      placeholder='0'
                      onChange={(e) => {
                        let inputValue = Number(e?.target?.value)
                        const isValid = _integerRegex_.test(inputValue)
                        if (
                          (isValid ||
                            (inputValue >= 0 &&
                              inputValue <= maxWarehouseQty)) &&
                          !isNaN(inputValue)
                        ) {
                          let productPriceArray = values?.productPrices ?? []

                          let productWarehouseArray =
                            productPriceArray?.filter(
                              (product) =>
                                product?.sizeID === productPrice?.sizeID
                            )[0]?.productWarehouses ?? []

                          if (productWarehouseArray?.length > 0) {
                            productWarehouseArray = productWarehouseArray?.map(
                              (data) => {
                                if (
                                  data?.warehouseId ===
                                  warehouseData?.warehouseId
                                ) {
                                  return {
                                    warehouseId: warehouseData?.warehouseId,
                                    warehouseName: warehouseData?.warehouseName,
                                    quantity: e?.target?.value
                                      ? e?.target?.value
                                      : 0,
                                    sizeID: productPrice?.sizeID
                                  }
                                } else {
                                  return data
                                }
                              }
                            )
                          }
                          let total = productWarehouseArray.reduce(
                            (acc, current) => {
                              if (current.quantity) {
                                return acc + Number(current.quantity)
                              } else {
                                return acc + 0
                              }
                            },
                            0
                          )

                          if (productPriceArray?.length > 0) {
                            productPriceArray = productPriceArray?.map(
                              (product) => {
                                if (product?.sizeID === productPrice?.sizeID) {
                                  return {
                                    ...product,
                                    quantity: total,
                                    perWarehouseStock: 0,
                                    productWarehouses: productWarehouseArray
                                  }
                                } else {
                                  return product
                                }
                              }
                            )
                          }

                          setFieldValue('productPrices', productPriceArray)
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  )
}

export default ManageSizeWarehouseQuantities
