import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
// import CategoryWidget from '../components/ManageHomePage/CategoryWidget';
// import ManageSection from '../components/ManageHomePage/ManageSection';
// import ManageSectiontwo from '../components/ManageHomePage/ManageSectiontwo';
import LandAddSectionDetails from '../components/LandingHomePage/LandAddSectionDetails'
import LandCreateSection from '../components/LandingHomePage/LandCreateSection'
import LandManageSection from '../components/LandingHomePage/LandManageSection'

function LandingHomePage(props) {
  const [CreateSection, setCreateSection] = useState(false)
  const [AddSectionDetails, setAddSectionDetails] = useState(false)
  const [ManageSections, setManageSection] = useState(false)

  return (
    <div>
      <div
        style={{
          backgroundColor: 'var(--bg-default)',
          height: '200px',
          cursor: 'pointer'
        }}
        className='rounded d-flex align-items-center justify-content-center'
        onClick={() => setCreateSection(true)}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='25'
          height='25'
          viewBox='0 0 34.607 34.607'
        >
          <g id='Icon' transform='translate(1.5 1.5)'>
            <g id='Icon-2' data-name='Icon' transform='translate(0 0)'>
              <line
                id='Line'
                y2='31.607'
                transform='translate(15.804)'
                fill='none'
                stroke='#3d3d3d'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='3'
              />
              <line
                id='Line-2'
                data-name='Line'
                x1='31.607'
                transform='translate(0 15.804)'
                fill='none'
                stroke='#3d3d3d'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='3'
              />
            </g>
          </g>
        </svg>
      </div>
      {/* <Button variant="primary" onClick={() => setCreateSection(true)}>
                Land Create Section
            </Button> */}

      <Button variant='primary' onClick={() => setAddSectionDetails(true)}>
        land Add Section Details
      </Button>

      <Button variant='primary' onClick={() => setManageSection(true)}>
        Manage Section
      </Button>

      <LandCreateSection
        show={CreateSection}
        onHide={() => setCreateSection(false)}
      />
      <LandAddSectionDetails
        show={AddSectionDetails}
        onHide={() => setAddSectionDetails(false)}
      />
      <LandManageSection
        show={ManageSections}
        onHide={() => setManageSection(false)}
      />
    </div>
  )
}

export default LandingHomePage
