import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { setPageTitle } from '../../redux/slice/pageTitleSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import MainAdmin from './MainAdmin'
import { allCrudNames, allPages, checkPageAccess } from '../../../lib/AllPageNames'
import NotFound from '../../NotFound/NotFound'

const ManageAdmin = () => {
  const [activeToggle, setActiveToggle] = useState('admin')
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setPageTitle('Manage Admin'))
  }, [])

  const { pageAccess } = useSelector((state) => state.user)
  const handleTabClick = (e, tabName) => {
    e.preventDefault();
    setActiveToggle(tabName);
  };

  return checkPageAccess(
    pageAccess,
    allPages?.manageAdmin,
    allCrudNames?.read
  ) ? (
    <>
      {checkPageAccess(pageAccess, allPages?.manageAdmin, allCrudNames?.read) && (
        <div className='card overflow-hidden'>
          <div className='card-body p-0'>
            {/* Tabs Links */}
            <div className='nav-tabs-horizontal nav nav-tabs'>
              <Link
                // onClick={() => setActiveToggle('admin')}
                onClick={(e) => handleTabClick(e, "admin")}

                data-toggle='tab'
                className={`nav-link fw-semibold ${activeToggle === 'admin' ? 'active show' : ''
                  }`}
              >
                <span className='nav-span'>
                  Admin
                  <hr />
                </span>
              </Link>
            </div>

            <div className='tab-content p-3'>
              <div
                id='admin'
                className={`tab-pane fade ${activeToggle === 'admin' ? 'active show' : ''
                  }`}
              >
                {activeToggle === 'admin' && <MainAdmin />}

                {/* Pagination End */}
              </div>
              {/* First Tab content End */}
            </div>
          </div>
        </div>)}
    </>
  ) : (
    <NotFound />
  )
}

export default ManageAdmin
