import React, { useState } from 'react'
import TooltipComponent from '../Tooltip'

function DeleteIcon({ bg }) {
  const [isHovered, setIsHovered] = useState(false)

  const WithBackground = () => {
    return (
      <TooltipComponent toolplace='top' tooltipText='Delete'>
        <svg
          role='button'
          className='rounded pv-deleticon-bg'
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 50 50'
        >
          <defs>
            <clipPath id='clip-Delete'>
              <rect width='50' height='50' />
            </clipPath>
          </defs>
          <g id='Delete' clipPath='url(#clip-Delete)'>
            {/* <rect width="50" height="50" fill="#fff" /> */}
            <g id='noun-delete-1092943' transform='translate(-151.948 -30.63)'>
              <path
                id='Subtraction_23'
                data-name='Subtraction 23'
                d='M-165.894-3712.12h-6.464a5.579,5.579,0,0,1-5.573-5.573v-13.01h-.544a3.149,3.149,0,0,1-1.494-2.658,3.171,3.171,0,0,1,3.185-3.149h2.963v-.75a3.364,3.364,0,0,1,3.36-3.359h2.7a3.4,3.4,0,0,1,3.36,3.359v.75h2.963a3.153,3.153,0,0,1,3.15,3.149,3.174,3.174,0,0,1-1.654,2.753l-.384.215v12.7A5.579,5.579,0,0,1-165.894-3712.12Zm-10.727-18.091v12.518a4.268,4.268,0,0,0,4.263,4.263h6.464a4.268,4.268,0,0,0,4.263-4.263v-12.518h-14.99Zm-.2-4.989a1.841,1.841,0,0,0-1.839,1.84,1.841,1.841,0,0,0,1.839,1.839h15.386a1.841,1.841,0,0,0,1.839-1.839,1.841,1.841,0,0,0-1.839-1.839Zm6.323-4.11a2.083,2.083,0,0,0-2.049,2.013l-.038.787h6.843v-.75a2.052,2.052,0,0,0-2.05-2.049Z'
                transform='translate(345.917 3782.25)'
              />
              <path
                id='Subtraction_26'
                data-name='Subtraction 26'
                d='M-178.658-3731.862h-1.31v-8.758h1.31v8.757Z'
                transform='translate(359.722 3796.477)'
              />
              <path
                id='Subtraction_25'
                data-name='Subtraction 25'
                d='M-178.658-3731.862h-1.31v-8.758h1.31v8.757Z'
                transform='translate(356.104 3796.477)'
              />
              <path
                id='Subtraction_24'
                data-name='Subtraction 24'
                d='M-178.658-3731.862h-1.311v-8.758h1.311v8.757Z'
                transform='translate(352.486 3796.477)'
              />
            </g>
          </g>
        </svg>
      </TooltipComponent>
    )
  }

  const WithoutBackground = () => {
    return (
      <TooltipComponent toolplace='top' tooltipText='Delete'>
        <svg
          role='button'
          className='pv-deleticon'
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 50 50'
        >
          <defs>
            <clipPath id='clip-Delete'>
              <rect width='50' height='50' />
            </clipPath>
          </defs>
          <g id='Delete' clipPath='url(#clip-Delete)'>
            <g id='noun-delete-1092943' transform='translate(-159.948 -41.131)'>
              <path
                id='Subtraction_23'
                data-name='Subtraction 23'
                d='M24.447,49.5H13.22a9.691,9.691,0,0,1-9.68-9.68v-22.6H2.6A5.446,5.446,0,0,1,5.532,7.138h5.147v-1.3A5.842,5.842,0,0,1,16.515,0h4.7a5.914,5.914,0,0,1,5.837,5.835v1.3H32.2a5.449,5.449,0,0,1,2.6,10.251l-.667.374V39.821A9.691,9.691,0,0,1,24.447,49.5ZM5.815,18.078V39.821a7.413,7.413,0,0,0,7.4,7.4H24.447a7.413,7.413,0,0,0,7.4-7.4V18.078H5.815ZM5.471,9.414a3.195,3.195,0,0,0,0,6.39l26.724,0a3.194,3.194,0,1,0,0-6.388ZM16.454,2.275a3.617,3.617,0,0,0-3.559,3.5l-.067,1.367H24.713v-1.3a3.564,3.564,0,0,0-3.561-3.56Z'
                transform='translate(165.948 41.63)'
              />
              <path
                id='Subtraction_26'
                data-name='Subtraction 26'
                d='M2.276,15.211H0V0H2.276V15.211Z'
                transform='translate(189.929 66.342)'
              />
              <path
                id='Subtraction_25'
                data-name='Subtraction 25'
                d='M2.276,15.211H0V0H2.276V15.211Z'
                transform='translate(183.644 66.342)'
              />
              <path
                id='Subtraction_24'
                data-name='Subtraction 24'
                d='M2.277,15.211H0V0H2.277V15.211Z'
                transform='translate(177.358 66.342)'
              />
            </g>
          </g>
        </svg>
      </TooltipComponent>
    )
  }

  return (
    <div className='pv-deleticon-main'>
      {bg ? <WithBackground /> : <WithoutBackground />}
    </div>
  )
}

export default DeleteIcon
