import { DatePicker } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Image,
  ListGroup,
  Row,
  Table,
} from "react-bootstrap";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import { useImmer } from "use-immer";
import Previewicon from "../../../components/AllSvgIcon/Previewicon";
import Loader from "../../../components/Loader";
import ModelComponent from "../../../components/Modal";
import CustomToast from "../../../components/Toast/CustomToast";
import { customStyles } from "../../../components/customStyles";
import axiosProvider from "../../../lib/AxiosProvider";
import { callApi, pageRangeDisplayed } from "../../../lib/GetBaseUrl";
import {
  _productBimcad_,
  _productImg_,
  _specificationDocFiles_
} from "../../../lib/ImagePath";
import useDebounce from "../../../lib/useDebounce";

const DownloadInfoReport = () => {
  const [searchText, setSearchText] = useState();
  const [data, setData] = useState();
  const [allState, setAllState] = useState();
  const [loading, setLoading] = useState(true);
  const [toast, setToast] = useState({
    show: false,
    text: null,
    variation: null,
  });
  const [filterDetails, setFilterDetails] = useImmer({
    pageSize: 10,
    pageIndex: 1,
    searchText: "",
    sellerId: "",
    brandId: "",
    userId: "",
    fromDate: "",
    toDate: "",
  });
  const [modalShow, setModalShow] = useState({ show: false, data: null });

  const debounceSearchText = useDebounce(searchText, 500);
  const fetchAllGenericData = async (apiUrls) => {
    try {
      const responseArray = await Promise.all(
        apiUrls.map((url) => callApi(url.endpoint, url.queryString, url.state))
      );

      return responseArray.filter((response) => response !== undefined);
    } catch (error) {}
  };

  useEffect(() => {
    if (debounceSearchText) {
      setFilterDetails((draft) => {
        draft.searchText = debounceSearchText;
        draft.pageIndex = 1;
      });
    } else {
      setFilterDetails((draft) => {
        draft.searchText = "";
        draft.pageIndex = 1;
      });
    }
  }, [debounceSearchText]);

  useEffect(() => {
    let apiUrls = [
      {
        endpoint: "SellerData/bindAllSeller",
        queryString: "",
        state: "sellerDetails",
      },
      {
        endpoint: "Brand/List",
        queryString: "?pageSize=0&pageIndex=0",
        state: "brand",
      },
      {
        endpoint: "CustomerData",
        queryString: "?pageSize=0&pageIndex=0",
        state: "userDetails",
      },
    ];
    const fetchData = async () => {
      let sellerDetails, brand, userDetails;
      const responses = await fetchAllGenericData(apiUrls);
      responses.forEach((response) => {
        switch (response.state) {
          case "sellerDetails":
            sellerDetails = response?.data;
            break;

          case "brand":
            brand = response?.data;
            break;

          case "userDetails":
            userDetails = response?.data;
            break;

          default:
            break;
        }
      });

      setAllState({
        ...allState,
        sellerDetails,
        brand,
        userDetails,
      });
    };
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    const response = await axiosProvider({
      method: "GET",
      endpoint: "DownloadInformation",
      queryString: `?searchText=${filterDetails?.searchText}&pageIndex=${
        filterDetails?.pageIndex
      }&pageSize=${filterDetails?.pageSize}&brandId=${
        filterDetails?.brandId
      }&sellerId=${filterDetails?.sellerId}&userId=${
        filterDetails?.userId
      }&isBimcad=true&fromDate=${
        filterDetails?.fromDate
          ? filterDetails?.fromDate?.format("DD-MM-YYYY")
          : ""
      }&toDate=${
        filterDetails?.toDate ? filterDetails?.toDate?.format("DD-MM-YYYY") : ""
      }`,
    })
      .then((res) => {
        if (res?.status === 200) {
          setLoading(false);
          setData(res);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, [filterDetails]);

  const handlePageClick = (event) => {
    setFilterDetails((draft) => {
      draft.pageIndex = event.selected + 1;
    });
  };

  function filterTrueFields(obj) {
    const fieldMappings = {
      catalogueReq: "Catalogue",
      bimcadReq: "BIM CAD",
      contactRepresentativeReq: "Contact Representative",
      pricelistReq: "Price List",
      retailerlistReq: "Retailer List",
    };

    const trueFields = Object.keys(fieldMappings)
      .filter((key) => obj[key])
      .map((key) => fieldMappings[key]);

    return trueFields.join(", ");
  }

  const downloadURI = async (uri, name, folderName) => {
    let staticLink = `${process.env.REACT_APP_IMG_URL}${folderName}${uri}`;

    try {
      const response = await fetch(staticLink);
      const data = await response.blob();

      const url = window.URL.createObjectURL(data);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", name);
      document.body.appendChild(link);
      link.click();
    } catch (error) {}
  };

  const disabledDate = (current) => {
    return current && current < moment("2023-01-01").startOf("day");
  };

  const disabledEndDate = (current, startValue) => {
    const today = moment().startOf("day");

    if (current.isBefore(today)) {
      return true;
    }

    if (startValue && current.isBefore(startValue.startOf("day"))) {
      return true;
    }

    return false;
  };

  return (
    <>
      <ModelComponent
        show={modalShow?.show}
        modalsize={"xl"}
        modalheaderclass={""}
        modeltitle={"BimCAD Details"}
        onHide={() => setModalShow({ show: !modalShow?.show, data: null })}
        btnclosetext={""}
        closebtnvariant={""}
        backdrop={"static"}
      >
        <Row>
          <Col md={3}>
            <div className="border rounded p-2">
              <Card.Img
                variant="top"
                src={`${process.env.REACT_APP_IMG_URL}${_productImg_}${modalShow?.data?.productImage}`}
              />
              <ListGroup className="mt-2 list-group-flush">
                <ListGroup.Item className="px-0">
                  <p className="bold mb-0">Product Name</p>
                  <Card.Text>{modalShow?.data?.productName}</Card.Text>
                </ListGroup.Item>
                <ListGroup.Item className="px-0">
                  <p className="bold mb-0">Brand Name</p>
                  <Card.Text>{modalShow?.data?.brandName}</Card.Text>
                </ListGroup.Item>
              </ListGroup>
            </div>
          </Col>
          <Col md={9}>
            <Table
              hover
              className="align-middle table-list hr_table_seller table-bordered"
            >
              <tbody>
                <tr>
                  <th>First Name</th>
                  <td>{modalShow?.data?.firstName}</td>
                </tr>
                <tr>
                  <th>Last Name</th>
                  <td>{modalShow?.data?.lastName}</td>
                </tr>
                <tr>
                  <th>Email Id</th>
                  <td>{modalShow?.data?.emailId}</td>
                </tr>
                <tr>
                  <th>Profession</th>
                  <td>{modalShow?.data?.profession}</td>
                </tr>
                <tr>
                  <th>Company</th>
                  <td>{modalShow?.data?.company}</td>
                </tr>
                <tr>
                  <th>City</th>
                  <td>{modalShow?.data?.city}</td>
                </tr>
                <tr>
                  <th>Pincode</th>
                  <td>{modalShow?.data?.pincode}</td>
                </tr>
                {/* <tr>
                  <th>Address</th>
                  <td>{modalShow?.data?.firstName}</td>
                </tr> */}
                <tr>
                  <th>Mobile Number</th>
                  <td>{modalShow?.data?.mobile}</td>
                </tr>
                <tr>
                  <th>{modalShow?.data?.isBimcad ? "Bimcad" : "Catalogue"}</th>
                  <td>
                    <Button
                      className="btn btn-primary btn-sm"
                      onClick={() => {
                        downloadURI(
                          modalShow?.data?.fileName,
                          modalShow?.data?.downloadFor,
                          modalShow?.data?.isBimcad
                            ? _productBimcad_
                            : _specificationDocFiles_
                        );
                      }}
                    >
                      Download
                    </Button>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </ModelComponent>
      <Row className="my-3">
        <Col md={10}>
          <Row className="gy-3">
            <Col md={4}>
              <Select
                styles={customStyles}
                id="sellerDetails"
                isClearable
                menuPortalTarget={document.body}
                value={
                  filterDetails?.sellerId && {
                    label: filterDetails?.sellerName,
                    value: filterDetails?.sellerId,
                  }
                }
                placeholder="Select Seller"
                options={
                  allState?.sellerDetails &&
                  allState?.sellerDetails?.map(({ userID, fullName }) => ({
                    label: fullName,
                    value: userID,
                  }))
                }
                onChange={(e) => {
                  const fetchAllData = () => {
                    // setLoading(true)
                    Promise.all([
                      callApi(
                        "AssignBrandToSeller/bySeller&BrandId",
                        `?sellerId=${e?.value}`
                      ),
                    ])
                      .then(([brandResp]) => {
                        // setLoading(false)
                        setAllState({
                          ...allState,
                          sellerBrand: brandResp ?? [],
                        });
                      })
                      .catch(() => {
                        // setLoading(false)
                      });
                  };
                  if (e?.value) {
                    fetchAllData();
                  } else {
                    setAllState({
                      ...allState,
                      sellerBrand: null,
                    });
                  }
                  setFilterDetails((draft) => {
                    draft.sellerId = e?.value ? e?.value : "";
                    draft.sellerName = e?.label ? e?.label : "";
                    draft.brandId = "";
                    draft.brandName = "";
                    draft.pageIndex = 1;
                  });
                }}
              />
            </Col>

            <Col md={4}>
              <Select
                styles={customStyles}
                id="brandId"
                isClearable
                menuPortalTarget={document.body}
                value={
                  filterDetails?.brandId && {
                    label: filterDetails?.brandName,
                    value: filterDetails?.brandId,
                  }
                }
                placeholder="Select Brand"
                options={
                  allState?.sellerBrand
                    ? allState?.sellerBrand?.map(({ brandId, brandName }) => ({
                        label: brandName,
                        value: brandId,
                      }))
                    : allState?.brand?.map(({ id, name }) => ({
                        value: id,
                        label: name,
                      }))
                }
                onChange={(e) => {
                  setFilterDetails((draft) => {
                    draft.brandId = e?.value ? e?.value : "";
                    draft.brandName = e?.label ? e?.label : "";
                    draft.pageIndex = 1;
                  });
                }}
              />
            </Col>
            <Col md={4}>
              <Select
                styles={customStyles}
                id="userId"
                isClearable
                menuPortalTarget={document.body}
                value={
                  filterDetails?.userId && {
                    label: filterDetails?.userName,
                    value: filterDetails?.userId,
                  }
                }
                placeholder="Select User"
                options={
                  allState?.userDetails?.length
                    ? allState?.userDetails?.map(
                        ({ id, firstName, lastName }) => ({
                          label: `${firstName} ${lastName}`,
                          value: id,
                        })
                      )
                    : []
                }
                onChange={(e) => {
                  setFilterDetails((draft) => {
                    draft.userId = e?.value ? e?.value : "";
                    draft.userName = e?.label ? e?.label : "";
                    draft.pageIndex = 1;
                  });
                }}
              />
            </Col>
            <Col md={4}>
              <DatePicker
                className="w-100"
                inputReadOnly
                format="DD-MM-YYYY"
                placeholder="From date"
                // showTime={{ format: 'HH:mm:ss' }}
                onChange={(date) => {
                  setFilterDetails((draft) => {
                    draft.fromDate = date;
                    draft.toDate = "";
                    draft.pageIndex = 1;
                  });
                }}
                disabledDate={disabledDate}
                // disabledTime={disabledTime}
                value={filterDetails?.fromDate}
              />
            </Col>

            {filterDetails?.fromDate && (
              <Col md={4}>
                <DatePicker
                  className="w-100"
                  inputReadOnly
                  format="DD-MM-YYYY"
                  placeholder="To date"
                  // showTime={{
                  //   format: 'HH:mm:ss',
                  //   disabledTime: () => values?.startDate
                  // }}
                  onChange={(date) => {
                    setFilterDetails((draft) => {
                      draft.toDate = date;
                      draft.pageIndex = 1;
                    });
                  }}
                  disabledDate={(current) =>
                    disabledEndDate(current, filterDetails?.fromDate)
                  }
                  value={filterDetails?.toDate}
                />
              </Col>
            )}
            <div className="col-md-3 gap-2 d-flex align-items-center">
              <Button
                variant="light"
                size="md"
                onClick={() => {
                  setFilterDetails((draft) => {
                    draft.pageSize = 10;
                    draft.pageIndex = 1;
                    draft.searchText = "";
                    draft.sellerId = "";
                    draft.brandId = "";
                    draft.userId = "";
                    draft.fromDate = "";
                    draft.toDate = "";
                  });
                }}
              >
                Reset
              </Button>
            </div>
          </Row>
        </Col>

        <Col md={2} className="mt-auto">
          <div className="d-flex align-items-center">
            <label className="me-1">Show</label>
            <select
              styles={customStyles}
              name="dataget"
              id="parpageentries"
              className="form-select me-1"
              onChange={(e) => {
                setFilterDetails((draft) => {
                  draft.pageSize = e?.target?.value;
                });
              }}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="200">200</option>
              <option value="500">500</option>
            </select>
          </div>
        </Col>
      </Row>

      {toast?.show && (
        <CustomToast text={toast?.text} variation={toast?.variation} />
      )}

      {loading && <Loader />}

      <Table responsive hover className="align-middle table-list">
        <thead>
          <tr>
            <th>Product</th>
            <th>Name</th>
            <th>Email</th>
            <th>Download For</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {data?.data?.data?.length > 0 ? (
            data?.data?.data?.map((data, index) => (
              <tr key={data?.id}>
                <td>
                  <div className="d-flex gap-2 align-items-center">
                    <Image
                      src={
                        data?.productImage
                          ? `${process.env.REACT_APP_IMG_URL}${_productImg_}${data?.productImage}`
                          : "https://placehold.jp/50x50.png"
                      }
                      className="rounded-1 kl-object-fit"
                      height="50px"
                      width="50px"
                    />
                    <span>{data?.productName}</span>
                  </div>
                </td>
                <td>{data?.firstName + " " + data?.lastName}</td>
                <td>{data?.emailId}</td>
                <td>{data?.fileName}</td>
                <td className="text-center">
                  <span
                    onClick={() =>
                      setModalShow({ show: !modalShow?.show, data })
                    }
                  >
                    <Previewicon bg={"bg"} />
                  </span>
                </td>
                {/* <td>{filterTrueFields(data)}</td> */}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={5} className="text-center">
                {data?.data?.message}
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      <ReactPaginate
        className="list-inline m-cst--pagination d-flex justify-content-end gap-1"
        breakLabel="..."
        nextLabel=""
        onPageChange={handlePageClick}
        pageRangeDisplayed={pageRangeDisplayed}
        pageCount={data?.data?.pagination?.pageCount ?? 0}
        previousLabel=""
        renderOnZeroPageCount={null}
        forcePage={filterDetails?.pageIndex - 1}
      />
    </>
  );
};

export default DownloadInfoReport;
