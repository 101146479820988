import React, { useRef, useState, useEffect } from 'react'
import Slider from 'react-slick'
import { _homePageImg_, _lendingPageImg_ } from '../../lib/ImagePath'
import Swal from 'sweetalert2'
import { _SwalDelete } from '../../lib/exceptionMessage'
import ImgEditComponet from './ImgEditComponet'
import ComponentEdit from './ComponentEdit'
import { allCrudNames, allPages, checkPageAccess } from '../../lib/AllPageNames'
import { useSelector } from 'react-redux'
import DynamicPositionComponent from './HeadingComponent'

function ManageBannerSlider({
  layoutsInfo,
  section,
  setLayoutDetails,
  layoutDetails,
  handleDelete,
  fromLendingPage,
  handleImgDelete,
  modalShow,
  setModalShow
}) {
  const sliderRef = useRef(null)
  const arrows = layoutsInfo?.layout_class?.toLowerCase()?.includes('arrows')

  const productbannerslick = {
    dots: layoutsInfo?.layout_class?.toLowerCase()?.includes('dots') && true,
    arrows: false,
    slidesToShow: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    infinite: true,
    centerMode: false
  }

  const minImagesLength = layoutsInfo?.layout_min_images
  const maxImagesLength = layoutsInfo?.layout_max_images

  const deleteBanner = (id) => {}
  const [hoveredBox, setHoveredBox] = useState(null)

  const handleMouseEnter = (boxNumber) => {
    setHoveredBox(boxNumber)
  }

  const handleMouseLeave = () => {
    setHoveredBox(null)
  }

  const sectionDelete = (id, specificImgDelete = false) => {
    Swal.fire({
      title: _SwalDelete.title,
      text: _SwalDelete.text,
      icon: _SwalDelete.icon,
      showCancelButton: _SwalDelete.showCancelButton,
      confirmButtonColor: _SwalDelete.confirmButtonColor,
      cancelButtonColor: _SwalDelete.cancelButtonColor,
      confirmButtonText: _SwalDelete.confirmButtonText,
      cancelButtonText: _SwalDelete.cancelButtonText
    }).then((result) => {
      if (result.isConfirmed) {
        if (specificImgDelete) {
          let endpoint = fromLendingPage
            ? `LendingPageSectionDetails?id=${id}`
            : `ManageHomePageDetails?id=${id}`
          handleImgDelete(endpoint)
        } else {
          handleDelete(section?.section_id)
        }
      } else if (result.isDenied) {
      }
    })
  }
  const sectionEdit = () => {
    setModalShow({
      ...modalShow,
      show: !modalShow?.show,
      layoutId: layoutsInfo?.layout_id,
      layoutName: layoutsInfo?.layout_name?.toLowerCase()?.includes('product')
        ? 'Product List'
        : layoutsInfo?.layout_name,
      layoutTypeId: layoutsInfo?.layout_type_id,
      layoutTypeName: layoutsInfo?.layout_type_name,
      sectionId: section?.section_id,
      type: 'normalLayoutSelection'
    })
  }

  return (
    <ComponentEdit
      sectionDelete={sectionDelete}
      sectionEdit={sectionEdit}
      sectionStatus={section?.status}
    >
      <DynamicPositionComponent
        heading={section?.title}
        paragraph={section?.sub_title}
        headingPosition={
          section?.title_position?.toLowerCase() === 'left'
            ? 'start'
            : section?.title_position?.toLowerCase() === 'center'
            ? 'center'
            : 'end'
        }
        buttonPosition={
          section?.link_in?.toLowerCase() === 'section'
            ? section?.link_position?.toLowerCase() === 'left'
              ? 'start'
              : section?.link_position?.toLowerCase() === 'center'
              ? 'center'
              : 'end'
            : section?.title_position?.toLowerCase() === 'left'
            ? 'end'
            : section?.title_position?.toLowerCase() === 'right'
            ? 'start'
            : 'center'
        }
        buttonPositionDirection={section?.link_in?.toLowerCase()}
        link_text={section?.link_text}
        link={section?.link}
        textColor={section?.text_color}
        titleColor={section?.title_color}
        backgroundColor={section?.background_color}
        sectionStatus={section?.status}
      >
        <div className='pv-managehome-bannerslider-main'>
          <div>
            <Slider
              {...productbannerslick}
              ref={sliderRef}
              className='pv-product-widget'
            >
              {section?.columns?.left?.single?.length > 0 ? (
                section?.columns?.left?.single?.map((data) => (
                  <div
                    className='position-relative pv-globle-imgedit-btn'
                    key={data}
                  >
                    <img
                      onContextMenu={() => {
                        deleteBanner(section?.section_id)
                      }}
                      onDoubleClick={() =>
                        setLayoutDetails({
                          show: !layoutDetails?.show,
                          sectionId: section?.section_id,
                          minImagesLength,
                          maxImagesLength
                        })
                      }
                      className='rounded w-100 h-100'
                      style={{ objectFit: 'cover' }}
                      src={`${process.env.REACT_APP_IMG_URL}${
                        fromLendingPage ? _lendingPageImg_ : _homePageImg_
                      }${data?.image}`}
                      alt='banner image'
                    />
                    <ImgEditComponet
                      sectionDelete={() => {
                        sectionDelete(data?.section_details_id, true)
                      }}
                      sectionEdit={() => {
                        setLayoutDetails({
                          show: !layoutDetails?.show,
                          sectionId: section?.section_id,
                          minImagesLength,
                          maxImagesLength,
                          sectionDetailsId: data?.section_details_id
                        })
                      }}
                    />
                  </div>
                ))
              ) : (
                <>
                  <div
                    key={Math.floor(Math.random() * 100000)}
                    className='position-relative pv-globle-imgedit-btn'
                  >
                    <img
                      style={{ objectFit: 'cover' }}
                      className='rounded w-100 h-100'
                      src='https://place-hold.it/900x250?text=900x250&fontsize=26'
                      alt='banner image'
                    />
                    <ImgEditComponet
                      showDeleteIcon={false}
                      sectionEdit={() => {
                        setLayoutDetails({
                          show: !layoutDetails?.show,
                          sectionId: section?.section_id,
                          minImagesLength,
                          maxImagesLength
                        })
                      }}
                    />
                  </div>
                </>
              )}
            </Slider>
            {section?.columns?.left?.single?.length > 1 && arrows && (
              <div className='pv-bannerslider-btn-main'>
                <button
                  className='slide_btn pv-banner-slide_left border-0'
                  onClick={() => {
                    sliderRef?.current?.slickPrev()
                  }}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='500'
                    height='500'
                    viewBox='0 0 500 500'
                    onMouseEnter={() => handleMouseEnter(1)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <defs>
                      <clipPath id='clip-Artboard_2'>
                        <rect width='500' height='500' />
                      </clipPath>
                    </defs>
                    <g
                      id='Artboard_2'
                      data-name='Artboard – 2'
                      clipPath='url(#clip-Artboard_2)'
                    >
                      <g
                        id='Arrow'
                        transform='translate(479.926 144.61) rotate(90)'
                      >
                        <path
                          id='Vector'
                          d='M105.568,0A26.8,26.8,0,0,0,86.925,7.481,25.143,25.143,0,0,0,79.2,25.541V372.647l-34.011-33.2a27.081,27.081,0,0,0-37.439,0,25.083,25.083,0,0,0,0,36.269l79.1,76.624a26.652,26.652,0,0,0,13.53,6.9,27.153,27.153,0,0,0,15.208-1.536,26.211,26.211,0,0,0,11.845-9.352,24.983,24.983,0,0,0,4.5-14.146V25.541a25.143,25.143,0,0,0-7.722-18.06A26.8,26.8,0,0,0,105.568,0Z'
                          transform='translate(0 0)'
                          fill={hoveredBox === 1 ? '#808080' : '#05215b'}
                        />
                        <path
                          id='Vector-2'
                          data-name='Vector'
                          d='M105.568,0A27.11,27.11,0,0,0,95.447,1.9a26.447,26.447,0,0,0-8.6,5.511L7.754,84.031A25.246,25.246,0,0,0,0,102.165a24.952,24.952,0,0,0,2.015,9.814,25.608,25.608,0,0,0,5.739,8.32,26.546,26.546,0,0,0,8.588,5.559,27.216,27.216,0,0,0,10.131,1.952A26.913,26.913,0,0,0,45.192,120.3l79.1-76.624a25.51,25.51,0,0,0,5.771-8.312,24.806,24.806,0,0,0,0-19.645,25.511,25.511,0,0,0-5.771-8.312,26.447,26.447,0,0,0-8.6-5.511A27.11,27.11,0,0,0,105.568,0Z'
                          transform='translate(79.095 332.035)'
                          fill={hoveredBox === 1 ? '#808080' : '#05215b'}
                        />
                      </g>
                    </g>
                  </svg>
                </button>
                <button
                  className='slide_btn pv-banner-slide_right border-0'
                  onClick={() => {
                    sliderRef?.current?.slickNext()
                  }}
                >
                  {/* <svg
                xmlns='http://www.w3.org/2000/svg'
                width='12.025'
                height='21.549'
                viewBox='0 0 12.025 21.549'
              >
                <g id='Icon' transform='translate(-6.25 -3.75)'>
                  <g id='Icon-2' data-name='Icon' transform='translate(7.5 5)'>
                    <path
                      id='Path'
                      d='M7.5,25.3a1.25,1.25,0,0,1-.884-2.134l8.641-8.641L6.616,5.884A1.25,1.25,0,0,1,8.384,4.116l9.525,9.525a1.25,1.25,0,0,1,0,1.768L8.384,24.933A1.246,1.246,0,0,1,7.5,25.3Z'
                      transform='translate(-7.5 -5)'
                      fill='#3d3d3d'
                    />
                  </g>
                </g>
              </svg> */}
                  {/* <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="55" height="25" viewBox="0 0 55 25" fill="none">
                <rect width="55" height="20" fill="url(#pattern0)" />
                <defs>
                  <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
                    <use href="#image0_1101_522" transform="scale(0.0181818 0.04)" />
                  </pattern>
                  <image id="image0_1101_522" width="55" height="20" href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADcAAAAZCAYAAACVfbYAAAAABHNCSVQICAgIfAhkiAAAAc9JREFUWEfVmD1WwkAQx2eA1FLYu5xAbqAX8Empie8JN6DUCui00iOkIWglhb3cAG9gwNqnNWR3nQ3yHh8JWaFgstXm7SQ7v53Z+e8GgXkrCbeFAHXjpgbwozDo2LpM7/FtBNZGxNaSh1r70zBo2HjNHM77RoTyGoglIG+4ivuDgAeJUbIAZA3nCM8HhOvUFMwAZA0Hol52YDogwONtAHnDGaIdAPnDzQFx8g6AR/+JYD7gYkC3WkIYpBYYMlFaN2QY+PMFiOFK4uqUJPKculUb/diXDYIWgChW59ek7rHEG0CAexUGN6aPRXFZK2DhZV8O7zqvJjIS+qXPSKnu1Lh3i45whzTKOmJpC5AEFsdQ6SgaBQ46FW8Wz5y1NLA5xvSji0jntz6F1ey33LQsMKX0lxwFh2hThThRZ4GZ8UiqC/h8ep7tRBLKIkxq9LBWiViBUTUvZGSZknAmx93XuFpycn6jL6RzDuJwk02izrEHNAIO+JZ4/flzfhUsH5HbEow/3A5g7OFIpgYkUydp2yYpFRdtWReUTQeMLDD2kXMqbph0zbEB4w8nvCaJ1cNiqpFId+j3XtumwrNOSwNgbi10h2uavgb9KMNe3wbM2PwCr8vSGt7FbuoAAAAASUVORK5CYII=" />
                </defs>
              </svg> */}
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='500'
                    height='500'
                    viewBox='0 0 500 500'
                    onMouseEnter={() => handleMouseEnter(2)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <defs>
                      <clipPath id='clip-Artboard_1'>
                        <rect width='500' height='500' />
                      </clipPath>
                    </defs>
                    <g
                      id='Artboard_1'
                      data-name='Artboard – 1'
                      clipPath='url(#clip-Artboard_1)'
                    >
                      <g
                        id='Arrow'
                        transform='translate(490.42 126.61) rotate(90)'
                      >
                        <path
                          id='Vector'
                          d='M105.568,459.7a26.8,26.8,0,0,1-18.643-7.481,25.143,25.143,0,0,1-7.722-18.06V87.049l-34.011,33.2a27.081,27.081,0,0,1-37.439,0,25.083,25.083,0,0,1,0-36.269l79.1-76.624a26.652,26.652,0,0,1,13.53-6.9A27.153,27.153,0,0,1,115.587,2a26.211,26.211,0,0,1,11.845,9.352,24.983,24.983,0,0,1,4.5,14.146v408.66a25.143,25.143,0,0,1-7.722,18.06A26.8,26.8,0,0,1,105.568,459.7Z'
                          transform='translate(18 10.644)'
                          fill={hoveredBox === 2 ? '#808080' : '#05215b'}
                        />
                        <path
                          id='Vector-2'
                          data-name='Vector'
                          d='M105.568,127.811a27.11,27.11,0,0,1-10.122-1.9,26.447,26.447,0,0,1-8.6-5.511L7.754,43.78A25.246,25.246,0,0,1,0,25.646a24.952,24.952,0,0,1,2.015-9.814,25.608,25.608,0,0,1,5.739-8.32,26.546,26.546,0,0,1,8.588-5.559A27.216,27.216,0,0,1,26.473,0,26.913,26.913,0,0,1,45.192,7.511l79.1,76.624a25.51,25.51,0,0,1,5.771,8.312,24.806,24.806,0,0,1,0,19.645,25.511,25.511,0,0,1-5.771,8.312,26.447,26.447,0,0,1-8.6,5.511A27.11,27.11,0,0,1,105.568,127.811Z'
                          transform='translate(97.095 10.494)'
                          fill={hoveredBox === 2 ? '#808080' : '#05215b'}
                        />
                      </g>
                    </g>
                  </svg>
                </button>
              </div>
            )}
          </div>
        </div>
      </DynamicPositionComponent>
    </ComponentEdit>
  )
}

export default ManageBannerSlider
